import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import SavedReplyList from "./SavedReplyList";
import SavedReplyEdit from "./SavedReplyEdit";
import SavedReplyCreate from "./SavedReplyCreate";

import s from "./SavedReplies.module.less";

import { postSavedRepliesAction, clearSavedReplyAllTagsAction } from "@store/replies/actions";

import { Mode, ViewChangeFunc } from "../common/modal/types";

interface ISavedRepliesProps {
  mode?: Mode;
  onCancel?: () => void;
  setMessage?: (message: string) => void;
}

const SavedReplies: React.FC<ISavedRepliesProps> = ({ onCancel, setMessage, mode = "modal" }) => {
  const dispatch = useDispatch();

  const [view, setView] = useState("list");
  const [editItemId, setEditItemId] = useState("");

  useEffect(() => {
    dispatch(postSavedRepliesAction());
  }, [dispatch]);

  const onViewChange: ViewChangeFunc = (_view, editId) => {
    setView(_view);
    dispatch(clearSavedReplyAllTagsAction());

    if (editId) setEditItemId(editId);
  };

  const onReplyClick = (message: string) => {
    setMessage?.(message);
    onCancel?.();
  };

  const list = (
    <SavedReplyList
      onViewChange={onViewChange}
      onReplyClick={mode === "modal" ? onReplyClick : undefined}
    />
  );

  const savedRepliesModalView = (
    <Modal
      centered
      hideFooter
      width={600}
      theme="light"
      visible={true}
      closable={false}
      hideHeader={true}
      maskClosable={true}
      onCancel={onCancel}
      destroyOnClose={true}
      wrapClassName={s.bbSavedReplyModal}
    >
      {view === "list" && list}
      {view === "create" && <SavedReplyCreate onViewChange={onViewChange} />}
      {view === "edit" && <SavedReplyEdit editItemId={editItemId} onViewChange={onViewChange} />}
    </Modal>
  );

  const savedRepliesListView = (
    <>
      {list}
      <Modal
        centered
        hideFooter
        width={600}
        theme="light"
        closable={false}
        hideHeader={true}
        maskClosable={true}
        onCancel={onCancel}
        destroyOnClose={true}
        visible={view !== "list"}
        wrapClassName={s.bbSavedReplyModal}
      >
        {view === "create" && <SavedReplyCreate onViewChange={onViewChange} />}
        {view === "edit" && <SavedReplyEdit editItemId={editItemId} onViewChange={onViewChange} />}
      </Modal>
    </>
  );

  return (
    <>
      {mode === "list" && savedRepliesListView}
      {mode === "modal" && savedRepliesModalView}
    </>
  );
};

export default SavedReplies;
