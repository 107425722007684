import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useState, useEffect, useCallback } from "react";

import {
  Loading,
  AreaChart,
  IBlockRef,
  CardCentered,
  IAxisTick,
  numberToQuantity,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Row, Col, Empty, Grid } from "antd";
import Breakdown from "./breakdown/Breakdown";
import { LoadingOutlined } from "@ant-design/icons";
import Aggregations from "./aggregations/Aggregations";
import AppFilters from "../../_common/AppFilter/AppFilters";
import { CommentsGridContainer } from "@containers/Comments/Comments";

import {
  postStatsSelectorPending,
  postStatsSelectorSuccessful,
  getStatsSelector,
} from "@store/stats/selectors";
import { clientDataSelector, meSelector } from "@store/me/selectors";
import { postStatsAction, resetStatsData } from "@store/stats/actions";

import s from "./Dashboard.module.less";

import { IAreaChartData } from "./Dashboard.type";
import { extractDate } from "@utils/dates";
import { IFilters } from "@store/filters/types";
import { useAppFilters } from "@utils/appFilters";
import { IDataStatsProps } from "@store/stats/types";
import { getAreas, colorData, tooltipDelay } from "./helpers";
import { getDefaultFilters } from "../../_common/AppFilter/config";
import { ISavedFilter } from "../../_common/AppFilter/AppFilters.type";

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const { filters, updateSearchDataAndUrl } = useAppFilters();

  const [areaChartData, setAreaChartData] = useState<IAreaChartData[]>([]);

  const resetStats = useCallback(() => dispatch(resetStatsData()), [dispatch]);
  const fetchStats = useCallback(
    (filterProps: IFilters) => dispatch(postStatsAction(filterProps)),
    [dispatch],
  );

  const clientData = useSelector(clientDataSelector);
  const statsFetched = useSelector(postStatsSelectorSuccessful);
  const stats = useSelector(getStatsSelector);
  const statsPending = useSelector(postStatsSelectorPending);
  const me = useSelector(meSelector);

  useEffect(() => {
    // Clear stats on unmount
    return () => {
      resetStats();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filters && !filters.saved_filter) {
      fetchStats(filters);
    }
  }, [fetchStats, filters]);

  const initStats = (statsProps: IDataStatsProps) => {
    if (statsProps.date_histogram && statsProps.date_histogram.datasets) {
      setAreaChartData(
        statsProps.date_histogram.datasets.map(
          (d, key): IAreaChartData => ({
            ...d,
            // key avoid duplicate reference for zoom
            date: `${extractDate(d.date)}-${key}`,
            tooltipLabel: `${extractDate(d.date)} / ${extractDate(d.end_date)}`,
          }),
        ),
      );
    }
  };

  useEffect(() => {
    if (statsFetched) {
      initStats(stats);
    }
  }, [stats, statsFetched]);

  const onFilter = (data: IFilters, paramUrl: string | ISavedFilter | undefined) =>
    updateSearchDataAndUrl(data, paramUrl);

  const onFilterAuthorId = (authorIds: string[]) =>
    updateSearchDataAndUrl({ author_ids: authorIds }, "id");

  const filterToSpecificItem = (item: IFilters) => {
    updateSearchDataAndUrl(
      {
        ...filters,
        ...item,
      },
      "id",
    );
  };

  // eslint-disable-next-line
  const calculateDateFromAreaChart = (data: any) => {
    const { activePayload } = data;
    const hour = activePayload[0]?.payload?.hour;
    return `${data.activeLabel.split("-")[0]} ${hour ? hour : ""}`;
  };

  const areaChartMouseEvent = (data: IBlockRef) => {
    const iniBlockCalculated = calculateDateFromAreaChart(data.initBlockRef);
    const endBlockCalculated = calculateDateFromAreaChart(data.endBlockRef);

    const startDate = moment(iniBlockCalculated).isBefore(endBlockCalculated)
      ? iniBlockCalculated
      : endBlockCalculated;

    const endDate = moment(iniBlockCalculated).isBefore(endBlockCalculated)
      ? endBlockCalculated
      : iniBlockCalculated;

    filterToSpecificItem({
      data_range_option: "custom",
      start_time: moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      end_time: moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
    });
  };

  const customDateTick = (tick: IAxisTick) => {
    const { x, y, payload } = tick;
    const { value, offset } = payload;

    return (
      <g>
        {value && (
          <text
            className={s.bbModerationTooltipDate}
            x={x + offset}
            y={y + 12}
          >{`${value?.split("-")[0]}`}</text>
        )}
      </g>
    );
  };

  const appFilters = (
    <>
      {filters && (
        <AppFilters
          showModerationGroups
          clientData={clientData}
          initialFilterData={filters}
          onFilter={onFilter}
          customFilters={getDefaultFilters(t, me)}
        />
      )}
    </>
  );

  return filters ? (
    <>
      <div className={s.bbModeration}>
        {screens.md && appFilters}
        <Row>
          {!statsPending ? (
            <>
              <Col xs={24} id="col-timeline">
                <Row>
                  <Col xs={24}>
                    <CardCentered
                      className={s.bbTimelineCard}
                      headerTitle={t("pages:dashboard:timeline:title")}
                    >
                      <div
                        className={s.bbModerationRootCard}
                        data-stonly-target="safety-moderation__timeline-chart"
                      >
                        <AreaChart
                          legend
                          tooltip
                          responsive
                          hideGradient
                          areas={getAreas()}
                          data={areaChartData}
                          legendSize={"normal"}
                          percentagePrecision={1}
                          onMouseEvent={areaChartMouseEvent}
                          legendTooltipDelayTime={tooltipDelay}
                          responsiveProps={{
                            height: 415,
                          }}
                          XAxisProps={{
                            dataKey: "date",
                            tick: customDateTick,
                            padding: {
                              left: 0,
                              right: 31,
                            },
                            allowDataOverflow: true,
                          }}
                          YAxisProps={{
                            type: "number",
                            allowDataOverflow: true,
                            tickFormatter: numberToQuantity,
                          }}
                          referenceAreaProps={{
                            yAxisId: 1,
                            isFront: true,
                          }}
                          margin={{
                            top: 30,
                            right: 30,
                            left: 30,
                            bottom: 0,
                          }}
                        />
                      </div>
                    </CardCentered>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} className={s.bbModerationContent}>
                {stats.stats?.total !== 0 && areaChartData.length ? (
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={9} xl={8} xxl={6} id="col-aggregations">
                      <Row>
                        <div className={s.bbModerationRowLeft}>
                          <Col xs={24}>
                            <Aggregations widthInfo={158} colors={colorData} filters={filters} />
                          </Col>
                        </div>
                      </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={15} xl={16} xxl={18} id="col-breakdown">
                      <Breakdown
                        appFilters={filters}
                        filterToSpecificItem={(requestData: IFilters) =>
                          filterToSpecificItem(requestData)
                        }
                      />
                    </Col>

                    <Col xs={24}>
                      <div
                        className={s.bbModerationComments}
                        data-stonly-target="safety-moderation__comments"
                      >
                        <div className={s.bbModerationCommentsHeader}>
                          <h5 data-cy="comments-title" className={s.bbModerationCommentsTitle}>
                            {t("components:comments:title")}
                          </h5>
                        </div>
                        <CommentsGridContainer
                          filters={filters}
                          showDeleteAction
                          showHideAction
                          showLikeAction
                          showDetailsAction
                          showLinkedInReactionAction
                          providedFilterAuthorIdAction={(authorIds: string[]) =>
                            onFilterAuthorId(authorIds)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col xs={24}>
                      <CardCentered>
                        <Empty
                          className={s.bbModerationEmpty}
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={t("pages:dashboard:empty")}
                          imageStyle={{
                            height: 40,
                          }}
                        />
                      </CardCentered>
                    </Col>
                  </Row>
                )}
              </Col>
            </>
          ) : (
            <Col xs={24}>
              <div className={s.bbModerationLoading}>
                <Loading
                  isLoading
                  size="large"
                  tip={t("pages:loading:spinText")}
                  indicator={<LoadingOutlined color="primary" style={{ fontSize: 24 }} />}
                />
              </div>
            </Col>
          )}
        </Row>
        {!screens.md && appFilters}
      </div>
    </>
  ) : null;
};

export default Dashboard;
