import React, { FC, useState } from "react";

import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import AIPromptsModal from "./aiPromptsModal/AIPromptsModal";
import AIPromptsContextWrapper from "./context/ContextWrapper";
import { GeneratePromptButton } from "./generatePromptButton/GeneratePromptButton";

import s from "./AIPrompts.module.less";

import { IAIPromptsProps } from "./types";

import { ChevronUpIcon, ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AIPrompts: FC<IAIPromptsProps> = ({
  aIPrompts,
  aIPromptsForm,
  mainBtnTooltipText,
  listBtnTooltipText,
  generateDefaultPromptLoading,
  generateDefaultPromptDisabled,
  fetchAIPrompts,
  deleteAIPrompt,
  setDefaultAIPrompt,
  getAIPromptSuggestion,
  onGenerateDefaultPromptClick,
}) => {
  const [aiPromptModalOpen, setAIPromptModalOpen] = useState(false);

  const onAIPromptClick = () => {
    setAIPromptModalOpen(!aiPromptModalOpen);
  };

  return (
    <AIPromptsContextWrapper
      fetchAIPrompts={fetchAIPrompts}
      deleteAIPrompt={deleteAIPrompt}
      setDefaultAIPrompt={setDefaultAIPrompt}
      getAIPromptSuggestion={getAIPromptSuggestion}
    >
      <GeneratePromptButton
        tooltipText={mainBtnTooltipText}
        loading={generateDefaultPromptLoading}
        disabled={generateDefaultPromptDisabled}
        onClick={onGenerateDefaultPromptClick}
      />

      <Tooltip title={listBtnTooltipText}>
        <span>
          <Button
            _size="sm"
            onClick={onAIPromptClick}
            className={s.bbAIPromptButton}
            disabled={generateDefaultPromptDisabled}
          >
            {aiPromptModalOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Button>
        </span>
      </Tooltip>

      {aiPromptModalOpen && (
        <AIPromptsModal
          aIPrompts={aIPrompts}
          onCancel={() => setAIPromptModalOpen(false)}
          aIPromptsForm={aIPromptsForm}
        />
      )}
    </AIPromptsContextWrapper>
  );
};

export default AIPrompts;
