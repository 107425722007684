export const GET_POSTS = "POSTS/GET_POSTS";
export const GET_POSTS_SUCCESS = "POSTS/GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "POSTS/GET_POSTS_FAILURE";

export const GET_POST = "POST/GET_POST";
export const GET_POST_SUCCESS = "POST/GET_POST_SUCCESS";
export const GET_POST_FAILURE = "POST/GET_POST_FAILURE";
export const CLEAR_POST = "POST/CLEAR_POST";

export const GET_POST_ADS = "POST/GET_POST_ADS";
export const GET_POST_ADS_SUCCESS = "POST/GET_POST_ADS_SUCCESS";
export const GET_POST_ADS_FAILURE = "POST/GET_POST_ADS_FAILURE";
export const CLEAR_POST_ADS = "POST/CLEAR_POST_ADS";
