import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { LanguageActionsType, ILanguage } from "./types";
import { RootState } from "..";
import { postLanguagesFailureAction, postLanguagesSuccessAction } from "./actions";
import { POST_LANGUAGES } from "./actionTypes";

import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";
import { Languages } from "@utils/languages";

const normalizeResponse = (data: ILanguage[]) =>
  data.map(lang => ({
    ...lang,
    value: lang.code,
  }));

export const postLanguages = (
  action$: Observable<LanguageActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_LANGUAGES)),
    switchMap(a => {
      return ajax
        .post<{ items: ILanguage[] }>(
          `${Languages}`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => postLanguagesSuccessAction(normalizeResponse(data.items))),
          catchError(e => handleError(e, postLanguagesFailureAction)),
        );
    }),
  );
