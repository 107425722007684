/* eslint-disable react/no-unescaped-entities */

import React from "react";
import { Trans } from "react-i18next";

export interface IAPIError {
  error: string;
  statusCode: number;
  message: string;
  data?: unknown[];
}

export interface IErrorNotification {
  title: string;
  message: string | React.ReactNode;
}

export interface IErrorMessages {
  [key: string]: IErrorNotification;
}

export const ERROR_MESSAGES: IErrorMessages = {
  missing_permissions: {
    title: "",
    message: (
      <div>
        <Trans i18nKey="components:comments:reply:notifications:missing_permissions">
          We were unable to issue this response because we don't have the right permissions to
          manage your comments. Please go to <a href="/settings/asset-management"> Assets</a> and
          login again to provide the necessary permissions.
        </Trans>
      </div>
    ),
  },
};

export const getAPIErrorMessages = ({ error, message }: IAPIError) => {
  const defaultResponse: IErrorNotification = {
    title: error || "",
    message: message || "",
  };

  return ERROR_MESSAGES[message] || defaultResponse;
};
