import { action } from "typesafe-actions";
import {
  GET_AGGREGATIONS,
  GET_AGGREGATIONS_SUCCESS,
  GET_AGGREGATIONS_FAILURE,
} from "./actionTypes";
import { IBreakdownUnit, IAggregationsBody } from "./types";

export const getAggregationsAction = (data: IAggregationsBody) => action(GET_AGGREGATIONS, data);
export const getAggregationsSuccessAction = (data: IBreakdownUnit[]) =>
  action(GET_AGGREGATIONS_SUCCESS, data);
export const getAggregationsFailureAction = () => action(GET_AGGREGATIONS_FAILURE);
