import moment from "moment";
import React, { FC } from "react";

import { Empty, Divider } from "antd";
import { Table, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import { LoadingOutlined } from "@ant-design/icons";

import s from "./Ads.module.less";

import { IAd, IAdsProps } from "./Ads.type";

const Ads: FC<IAdsProps> = ({ ads, isLoading, texts }) => {
  const extractDate = (date: string) => {
    return moment(date).format("DD MMM, YYYY");
  };

  const extractTime = (date: string) => {
    return moment(date).format("hh:mm:ss A");
  };

  const generateInfo = (value: string, rowData: IAd) => {
    return (
      <div className={s.bbCommentAdsInfo}>
        <div className={s.bbCommentAdsAccountName} data-cy="ads-account-name">
          {rowData.account_name}
        </div>
        <div className={s.bbCommentAdsAccountValue} data-cy="ads-account-value">
          {rowData.account_id}
        </div>
        <div className={s.bbCommentAdsValue} data-cy="comment-ads-value">
          {extractDate(rowData.created_time_pretty)}
        </div>
        <div className={s.bbCommentAdsSubValue} data-cy="ads-subvalue">
          {extractTime(rowData.created_time_pretty)}
        </div>
        <div className={s.bbCommentAdsSubValue} data-cy="ads-subvalue">
          {rowData.targeting && rowData.targeting?.countries
            ? rowData.targeting?.countries.join(", ")
            : ""}
        </div>
      </div>
    );
  };

  const generateAd = (value: string, rowData: IAd) => {
    return (
      <div className={s.bbCommentAdsDetails}>
        <div className={s.bbCommentAdsSection}>
          <Divider className={s.bbCommentAdsDivider} plain orientation="left">
            {texts?.adLabel || ""}
          </Divider>
          <div className={s.bbCommentAdsValue} data-cy="comment-ads-value">
            {rowData.ad_id}
          </div>
          <div className={s.bbCommentAdsSubValue} data-cy="ads-subvalue">
            {rowData.ad_name}
          </div>
        </div>

        <div className={s.bbCommentAdsSection}>
          <Divider className={s.bbCommentAdsDivider} plain orientation="left">
            {texts?.adSetLabel || ""}
          </Divider>
          <div className={s.bbCommentAdsValue} data-cy="comment-ads-value">
            {rowData.adset_id}
          </div>
          <div className={s.bbCommentAdsSubValue} data-cy="ads-subvalue">
            {rowData.adset_name}
          </div>
        </div>

        <div className={s.bbCommentAdsSection}>
          <Divider className={s.bbCommentAdsDivider} plain orientation="left">
            {texts?.campaignLabel || ""}
          </Divider>
          <div className={s.bbCommentAdsValue} data-cy="comment-ads-value">
            {rowData.campaign_id}
          </div>
          <div className={s.bbCommentAdsSubValue} data-cy="ads-subvalue">
            {rowData.campaign_name}
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      key: "info",
      title: texts?.adsTitle,
      render: generateInfo,
    },
    {
      key: "ad",
      render: generateAd,
    },
  ];

  return (
    <div className={s.bbCommentAds} data-cy="social-ads">
      {!isLoading ? (
        <div className={s.bbCommentAdsContent}>
          {ads.length ? (
            <Table
              size="small"
              showHeader={false}
              dataSource={ads}
              columns={columns}
              pagination={{ defaultPageSize: 1, showSizeChanger: false }}
            />
          ) : (
            <Empty className={s.bbCommentAdsEmpty} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      ) : (
        <div className={s.bbCommentAdsLoader}>
          <Loading
            isLoading
            type="spinning"
            size="small"
            tip={texts?.spinText || "Loading"}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
          />
        </div>
      )}
    </div>
  );
};

export default Ads;
