import classNames from "classnames";
import React, { FC, useMemo } from "react";

import { Avatar } from "antd";
import { Document, Page } from "react-pdf";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Post.module.less";

import { IPostImageProps } from "./Post.type";
import { defaultPDFDocOptions } from "./helpers";
import { getPostImageUrl, isPostMention } from "@utils/post";

export const PostImage: FC<IPostImageProps> = ({ post }) => {
  const showTextAsImage = useMemo(
    () => post?.platform_type.id === "twitter" && !getPostImageUrl(post),
    [post],
  );

  const postName = useMemo(() => {
    const twitterNotOwnedPost = !post.owned && post.platform_type.id.includes("twitter");
    const isIGMentionPost = isPostMention(post);

    if (twitterNotOwnedPost || isIGMentionPost) {
      return post.author_name;
    }
    return post.page_name;
  }, [post]);

  return (
    <div className={s.bbPostLeft}>
      <div className={s.bbPostInfo}>
        <div
          className={classNames(s.bbPostAttachmentContainer, {
            [s.bbPostAttachmentContainerPortrait]: post.post_placement === "STORY",
          })}
        >
          {showTextAsImage ? (
            <div className={s.bbPostImageTextWrapper}>
              <Card className={s.bbPostImageText}>{post.message}</Card>
            </div>
          ) : post.attachments?.length && post.attachments[0].type === "video" ? (
            <div className={s.bbPostVideoWrapper}>
              <video className={s.bbPostVideo} preload="metadata">
                <source src={post.attachments[0].url} />
              </video>
            </div>
          ) : post.attachments?.length && post.attachments[0].type === "pdf" ? (
            <div className={s.bbPostPDFWrapper}>
              <Document
                file={post.attachments[0].url}
                renderMode="canvas"
                options={defaultPDFDocOptions}
              >
                <Page
                  key={1}
                  pageNumber={1}
                  width={220}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            </div>
          ) : (
            <Avatar
              shape="square"
              data-cy="post-image"
              className={s.bbPostImage}
              src={
                post?.attachments && post.attachments[0]?.media
                  ? post.attachments[0].media?.image.src
                  : post?.picture || ""
              }
            />
          )}
        </div>
        <div className={s.bbPostDetails}>
          <div className={s.bbPostName} data-cy="post-name">
            {postName}
          </div>
          <div className={s.bbPostMessage} data-cy="post-message">
            {post?.message}
          </div>
        </div>
      </div>
    </div>
  );
};
