import { action } from "typesafe-actions";
import {
  GET_AUTOCOMPLETE_OPTIONS,
  GET_AUTOCOMPLETE_OPTIONS_SUCCESS,
  GET_AUTOCOMPLETE_OPTIONS_FAILURE,
  CLEAR_AUTOCOMPLETE_OPTIONS,
} from "./actionTypes";
import { IAutocompleteOption } from "./types";
import { AllowedAutocompleteFieldType, IGetAutocompleteOptionsPayload } from "./types";

export const getAutocompleteOptionsAction = (payload: IGetAutocompleteOptionsPayload) =>
  action(GET_AUTOCOMPLETE_OPTIONS, payload);
export const getAutocompleteOptionsSuccessAction = (
  data: IAutocompleteOption[],
  key: AllowedAutocompleteFieldType,
) => action(GET_AUTOCOMPLETE_OPTIONS_SUCCESS, { data, key });
export const getAutocompleteOptionsFailureAction = () => action(GET_AUTOCOMPLETE_OPTIONS_FAILURE);
export const clearAutocompleteOptionsAction = (field: AllowedAutocompleteFieldType) =>
  action(CLEAR_AUTOCOMPLETE_OPTIONS, field);
