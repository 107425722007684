import cn from "classnames/bind";

import React, { memo } from "react";
import { Col, Grid, Row } from "antd";
import { IOverviewMessageTypeDetailItem } from "@store/dashboard/types";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./MessageTypeDetailTable.module.less";
import colors from "@components/_common/BreakdownChart/colors.module.less";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

const MessageTypeDetailTableRow = memo(
  ({ data, index }: { data: IOverviewMessageTypeDetailItem; index: number }) => {
    const screens = Grid.useBreakpoint();
    const columnsSizes = {
      sm: [0, 12, 10, 0, 0],
      md: [6, 6, 5, 5, 0],
      lg: [5, 5, 4, 4, 4],
    };
    const columnsSize = columnsSizes[screens.lg ? "lg" : screens.md ? "md" : "sm"];

    return (
      <div>
        <Row
          className={cx(s.bbTableRow, {
            [s.bbTableRowColored]: index % 2 === 1,
          })}
        >
          {columnsSize[0] !== 0 && (
            <Col span={columnsSize[0]} className={s.bbColumn}>
              <div className={s.bbMessageTypeDetailNameColumn}>
                <div
                  className={s.bbMessageTypeDetailNameColumnCircle}
                  style={{ background: colors[data.category.id] }}
                />
                <Tooltip title={data.category.label} className={s.bbCellEllipsis}>
                  {data.category.label}
                </Tooltip>
              </div>
            </Col>
          )}
          {columnsSize[1] !== 0 && (
            <Col span={columnsSize[1]} className={s.bbColumn}>
              <Tooltip title={data.messageType.label} className={s.bbCellEllipsis}>
                {data.messageType.label}
              </Tooltip>
            </Col>
          )}
          {columnsSize[2] !== 0 && (
            <Col span={columnsSize[3]} className={s.bbColumn}>
              <div>
                <span>{data.volume}</span>
              </div>
            </Col>
          )}
          {columnsSize[3] !== 0 && (
            <Col span={columnsSize[3]} className={s.bbColumn}>
              <div>
                <span>{data.percentage}%</span>
              </div>
            </Col>
          )}
          {columnsSize[4] !== 0 && (
            <Col span={columnsSize[4]} className={s.bbColumn}>
              <div>
                <PerformanceCardPill percentageValue={data.percentageChange} />
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  },
);

MessageTypeDetailTableRow.displayName = "MessageTypeDetailTableRow";

export default MessageTypeDetailTableRow;
