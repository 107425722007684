import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "../";
import { KpiDataActions, IKpiData } from "./types";
import { getKpiDataFailureAction, getKpiDataSuccessAction } from "./actions";

import { GET_KPI_DATA } from "./actionTypes";

import { kpiData } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const getKpiData = (
  action$: Observable<KpiDataActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_KPI_DATA)),
    switchMap(a => {
      return ajax
        .post<IKpiData>(
          kpiData,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getKpiDataSuccessAction(data)),
          catchError(e => handleError(e, getKpiDataFailureAction)),
        );
    }),
  );
