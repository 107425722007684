import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { mergeMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "..";
import { AutoCompleteActionsType, IAutocompleteOption } from "./types";
import { GET_AUTOCOMPLETE_OPTIONS } from "./actionTypes";
import {
  getAutocompleteOptionsFailureAction,
  getAutocompleteOptionsSuccessAction,
} from "./actions";

import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import { autocomplete } from "@utils/paths";

export const getAutocomleteOptions = (
  action$: Observable<AutoCompleteActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_AUTOCOMPLETE_OPTIONS)),
    mergeMap(a => {
      return ajax
        .post<{ items: IAutocompleteOption[] }>(
          `${autocomplete}/searches`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getAutocompleteOptionsSuccessAction(data.items, a.payload.field)),
          catchError(e => handleError(e, getAutocompleteOptionsFailureAction)),
        );
    }),
  );
