import { produce } from "immer";
import { createReducer, ActionType } from "typesafe-actions";

import {
  postCommentsSuccessAction,
  translateCommentAction,
  loadCommentsPageSuccessAction,
  getInboxRepliesAction,
  performCommentAction,
  getCommentsCSVSuccessAction,
  updateCommentSentimentSuccessAction,
  performBulkCommentAction,
  performBulkCommentActionSuccessAction,
  postCommentsFailureAction,
  postCommentsAction,
  assignCommentUserAction,
  getCommentByIdSuccessAction,
} from "./actions";
import {
  postCustomTagAction,
  postCustomTagSuccessAction,
  postCustomTagFailureAction,
  deleteCustomTagFromCommentSuccessAction,
} from "../customTags/actions";
import {
  deleteCommentTagSuccessAction,
  postCommentTagSuccessAction,
} from "@store/commentTags/actions";
import { deleteBrandReplySuccessAction, postCommentReplySuccessAction } from "../replies/actions";

import {
  POST_COMMENTS,
  POST_COMMENTS_SUCCESS,
  POST_COMMENTS_FAILURE,
  GET_COMMENT_BY_ID,
  GET_COMMENT_BY_ID_SUCCESS,
  GET_COMMENT_BY_ID_FAILURE,
  TRANSLATE_COMMENT,
  LOAD_COMMENTS_PAGE_SUCCESS,
  GET_INBOX_REPLIES,
  PERFORM_COMMENT_ACTION,
  PERFORM_COMMENT_ACTION_SUCCESS,
  GET_COMMENTS_CSV,
  GET_COMMENTS_CSV_SUCCESS,
  ASSIGN_COMMENT_USER,
  UPDATE_COMMENT_SENTIMENT,
  PERFORM_COMMENT_ACTION_FAILURE,
  PERFORM_BULK_COMMENT_ACTION,
  PERFORM_BULK_COMMENT_ACTION_SUCCESS,
  PERFORM_BULK_COMMENT_ACTION_FAILURE,
  TRIGGER_NEXT_BATCH,
} from "./actionTypes";
import {
  POST_CUSTOM_TAG,
  POST_CUSTOM_TAG_SUCCESS,
  POST_CUSTOM_TAG_FAILURE,
  DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS,
} from "../customTags/actionTypes";
import {
  ADD_LINKEDIN_REACTION,
  ADD_LINKEDIN_REACTION_SUCCESS,
  DELETE_LINKEDIN_REACTION,
  DELETE_LINKEDIN_REACTION_SUCCESS,
  LINKEDIN_REACTION_FAILURE,
} from "../linkedInReactions/actionTypes";
import {
  DELETE_COMMENT_TAG_SUCCESS,
  POST_COMMENT_TAG_SUCCESS,
} from "@store/commentTags/actionTypes";
import { GET_LISTEN_PROJECT_COMMENTS } from "@store/listen/actionTypes";
import { GET_MODERATION_STREAMS_COMMENTS } from "@store/streams/actionTypes";
import { DELETE_BRAND_REPLY_SUCCESS, POST_COMMENT_REPLY_SUCCESS } from "../replies/actionTypes";

import {
  handleSuccessfulCommentActionState,
  handleFailedCommentActionState,
  ActionToReplyProp,
  canReplyToComment,
  isPrivateConversation,
} from "@utils/comments";
import { ICommentsState, IMessageTags } from "./types";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";
import { addToTagsListing, removeFromTagsListing } from "@utils/tagsListingOperations";

const initialState: ICommentsState = {
  cursor: undefined,
  info: undefined,
  comments: [],
  comment: undefined,
  commentsCSV: undefined,
  actionsProcessing: [],
  fetchingComment: false,
  fetchedComment: false,
  fetchedCommentFail: false,
  performingBatchActions: false,
  performedBatchActions: false,
  performedBatchActionsFailed: false,
  fetchingPostComments: false,
  fetchedPostComments: false,
  fetchedPostCommentsFail: false,
  fetchingPostUnhideComments: false,
  fetchedPostUnhideComments: false,
  fetchedPostUnhideCommentsFail: false,
  fetchingCommentsCSV: false,
  fetchedCommentsCSV: false,
  fetchedCommentsCSVFail: false,
  previouslyAssignedUsers: [],
  previousReactions: {},
  triggerNextBatch: undefined,
};

export const commentsReducer = createReducer<ICommentsState>(initialState, {
  [POST_COMMENTS]: (state: ICommentsState, action: ActionType<typeof postCommentsAction>) => {
    if (action.payload.isReplyModal) return state;

    return {
      ...state,
      fetchingPostComments: true,
      fetchedPostComments: false,
      fetchedPostCommentsFail: false,
    };
  },
  [GET_INBOX_REPLIES]: (
    state: ICommentsState,
    action: ActionType<typeof getInboxRepliesAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    return {
      ...state,
      fetchingPostComments: true,
      fetchedPostComments: false,
      fetchedPostCommentsFail: false,
      replyType: action.payload.list_type,
    };
  },
  [GET_MODERATION_STREAMS_COMMENTS]: (state: ICommentsState) => ({
    ...state,
    fetchingPostComments: true,
    fetchedPostComments: false,
    fetchedPostCommentsFail: false,
  }),
  [GET_LISTEN_PROJECT_COMMENTS]: (state: ICommentsState) => ({
    ...state,
    fetchingPostComments: true,
    fetchedPostComments: false,
    fetchedPostCommentsFail: false,
  }),
  [POST_COMMENTS_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentsSuccessAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    return {
      ...state,
      info: action.payload.info,
      navigation_id: action.payload.navigation_id,
      cursor: action.payload.cursor,
      comments: action.payload.comments,
      fetchingPostComments: false,
      fetchedPostComments: true,
      fetchedPostCommentsFail: false,
    };
  },
  [POST_COMMENTS_FAILURE]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentsFailureAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    return {
      ...state,
      fetchingPostComments: false,
      fetchedPostComments: false,
      fetchedPostCommentsFail: true,
    };
  },
  // GET_COMMENT_BY_ID
  [GET_COMMENT_BY_ID]: (state: ICommentsState) => {
    return {
      ...state,
      comment: undefined,
      fetchingComment: true,
      fetchedComment: false,
      fetchedCommentFail: false,
    };
  },
  [GET_COMMENT_BY_ID_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof getCommentByIdSuccessAction>,
  ) => {
    return {
      ...state,
      comment: action.payload,
      fetchingComment: false,
      fetchedComment: true,
      fetchedCommentFail: false,
    };
  },
  [GET_COMMENT_BY_ID_FAILURE]: (state: ICommentsState) => {
    return {
      ...state,
      comment: undefined,
      fetchingComment: false,
      fetchedComment: false,
      fetchedCommentFail: true,
    };
  },
  // Pagination Success
  [LOAD_COMMENTS_PAGE_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof loadCommentsPageSuccessAction>,
  ) => {
    const comments = action.payload.replacePrevPage
      ? action.payload.comments
      : [...state.comments, ...action.payload.comments];

    return {
      ...state,
      info: action.payload.info,
      navigation_id: action.payload.navigation_id,
      cursor: action.payload.cursor,
      comments: comments,
      fetchingPostComments: false,
      fetchedPostComments: true,
      fetchedPostCommentsFail: false,
    };
  },
  [TRANSLATE_COMMENT]: (
    state: ICommentsState,
    action: ActionType<typeof translateCommentAction>,
  ) => {
    const commentIdx = state.comments.findIndex(({ id }) => id === action.payload.comment_id);

    if (commentIdx > -1) {
      if (!action.payload.isParent) {
        return {
          ...state,
          comments: Object.assign([], {
            ...state.comments,
            [commentIdx]: {
              ...state.comments[commentIdx],
              is_translated: action.payload.isParent
                ? state.comments[commentIdx].is_translated
                : !state.comments[commentIdx].is_translated,
            },
          }),
        };
      } else if (state.comments[commentIdx].parent && action.payload.isParent) {
        return {
          ...state,
          comments: Object.assign([], {
            ...state.comments,
            [commentIdx]: {
              ...state.comments[commentIdx],
              parent: {
                ...state.comments[commentIdx].parent,
                is_translated: !state.comments[commentIdx].parent?.is_translated,
              },
            },
          }),
        };
      } else {
        return {
          ...state,
          fetchingSearch: false,
          fetchedSearch: false,
          fetchedSearchFail: false,
        };
      }
    }

    return state;
  },
  [PERFORM_COMMENT_ACTION]: (
    state: ICommentsState,
    action: ActionType<typeof performCommentAction>,
  ) => {
    const commentIdx = state.comments.findIndex(({ id }) => id === action.payload.id);

    if (commentIdx > -1) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            actionsProcessing: [...state.actionsProcessing, action.payload.action],
          },
        }),
      };
    }

    return state;
  },
  [PERFORM_COMMENT_ACTION_SUCCESS]: handleSuccessfulCommentActionState,
  [PERFORM_COMMENT_ACTION_FAILURE]: handleFailedCommentActionState,
  [POST_COMMENT_REPLY_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentReplySuccessAction>,
  ) => {
    const commentIdx = state.comments.findIndex(({ id }) => id === action.payload.commentId);

    if (commentIdx > -1) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            reply: {
              ...state.comments[commentIdx].reply,
              is_sent: true,
              raw_message: action.payload.data.raw_message,
              replied_as: action.payload.data.replied_as,
            },
          },
        }),
      };
    }

    return state;
  },
  // CUSTOM TAG ACTIONS
  [POST_CUSTOM_TAG]: (state: ICommentsState, action: ActionType<typeof postCustomTagAction>) => {
    const commentIdx = state.comments.findIndex(({ id }) => id === action.payload.comment_id);
    const newTag: ICustomTag = {
      label: action.payload.label,
      newlyAdded: true,
    };

    if (commentIdx > -1) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            custom_tags_by_user: [
              ...(state.comments[commentIdx].custom_tags_by_user || []),
              newTag,
            ],
          },
        }),
      };
    }

    return state;
  },
  [POST_CUSTOM_TAG_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof postCustomTagSuccessAction>,
  ) => {
    let commentIdx = state.comments.findIndex(({ id }) => id === action.payload.commentId);

    if (commentIdx > -1) {
      const newTagIndex = state.comments[commentIdx].custom_tags_by_user?.findIndex(
        tag => tag.label === action.payload.tag.label,
      );

      if (newTagIndex !== undefined && newTagIndex > -1) {
        const comment = state.comments[commentIdx];

        return {
          ...state,
          comments: Object.assign([], {
            ...state.comments,
            [commentIdx]: {
              ...comment,
              custom_tags_by_user: Object.assign([], {
                ...(comment.custom_tags_by_user || []),
                [newTagIndex]: {
                  ...action.payload.tag,
                  newlyAdded: true,
                },
              }),
            },
          }),
        };
      }

      return state;
    } else {
      // If it's a private conversation, the comment id is the parent id
      commentIdx = state.comments.findIndex(
        comment => comment.id === action.payload.parentCommentId,
      );

      if (commentIdx > -1) {
        const comment = state.comments[commentIdx];
        let message_tags = comment.message_tags;

        if (comment.post_placement && isPrivateConversation(comment.post_placement)) {
          message_tags = {
            ...comment.message_tags,
            custom_tags: addToTagsListing(
              comment.message_tags?.custom_tags || [],
              action.payload.tag,
            ),
            count: (comment.message_tags?.count || 0) + 1,
          } as IMessageTags;
        }

        return {
          ...state,
          comments: Object.assign([], {
            ...state.comments,
            [commentIdx]: {
              ...comment,
              message_tags: Object.assign({}, message_tags),
            },
          }),
        };
      }

      return state;
    }
  },
  [POST_CUSTOM_TAG_FAILURE]: (
    state: ICommentsState,
    action: ActionType<typeof postCustomTagFailureAction>,
  ) => {
    const commentIdx = state.comments.findIndex(({ id }) => id === action.payload);

    if (commentIdx > -1) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            custom_tags_by_user: Object.assign([], {
              ...(state.comments[commentIdx].custom_tags_by_user || []).filter(tag => tag.id),
            }),
          },
        }),
      };
    }

    return state;
  },
  [DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof deleteCustomTagFromCommentSuccessAction>,
  ) => {
    let commentIdx = state.comments.findIndex(({ id }) => id === action.payload.comment_id);
    const removedTagId = action.payload.id;

    if (commentIdx > -1) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            custom_tags_by_user: state.comments[commentIdx].custom_tags_by_user?.filter(
              tag => tag.id !== removedTagId,
            ),
            custom_tags_by_automation: state.comments[commentIdx].custom_tags_by_automation?.filter(
              tag => tag.id !== removedTagId,
            ),
          },
        }),
      };
    } else {
      // If it's a private conversation, the comment id is the parent id
      commentIdx = state.comments.findIndex(
        comment => comment.id === action.payload.parent_comment_id,
      );

      if (commentIdx > -1) {
        const comment = state.comments[commentIdx];
        let message_tags = comment.message_tags;

        if (comment.post_placement && isPrivateConversation(comment.post_placement)) {
          message_tags = {
            ...comment.message_tags,
            custom_tags: removeFromTagsListing(
              comment.message_tags?.custom_tags || [],
              action.payload.id,
            ),
            count: (comment.message_tags?.count || 0) - 1,
          } as IMessageTags;
        }

        return {
          ...state,
          comments: Object.assign([], {
            ...state.comments,
            [commentIdx]: {
              ...comment,
              message_tags: Object.assign({}, message_tags),
            },
          }),
        };
      }

      return state;
    }
  },
  [DELETE_BRAND_REPLY_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof deleteBrandReplySuccessAction>,
  ) => {
    const commentIdx = state.comments.findIndex(({ id }) => id === action.payload.id);

    if (commentIdx > -1) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            reply: {
              is_ignored: false,
              is_starred: false,
              is_sent: false,
              sent_at: "",
              sent_by: "",
              sent_id: "",
              message: "",
            },
          },
        }),
      };
    }

    return state;
  },
  [POST_COMMENT_TAG_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentTagSuccessAction>,
  ) => {
    let commentIdx = state.comments.findIndex(({ id }) => id === action.payload.commentId);

    if (commentIdx > -1) {
      const comment = state.comments[commentIdx];
      const tags = comment.tags;
      const tagExists = tags?.some(tag => tag.id === action.payload.tagId);

      if (action.payload.tag && !tagExists) tags?.push(action.payload.tag);

      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...comment,
            tags: Object.assign([], {
              ...tags,
            }),
          },
        }),
      };
    } else {
      // If it's a private conversation, the comment id is the parent id
      commentIdx = state.comments.findIndex(
        comment => comment.id === action.payload.parentCommentId,
      );

      if (commentIdx > -1) {
        const comment = state.comments[commentIdx];
        let message_tags = comment.message_tags;

        if (comment.post_placement && isPrivateConversation(comment.post_placement)) {
          message_tags = {
            ...comment.message_tags,
            managed_tags: addToTagsListing(
              comment.message_tags?.managed_tags || [],
              action.payload.tag,
            ),
            count: (comment.message_tags?.count || 0) + 1,
          } as IMessageTags;
        }

        return {
          ...state,
          comments: Object.assign([], {
            ...state.comments,
            [commentIdx]: {
              ...comment,
              message_tags: Object.assign({}, message_tags),
            },
          }),
        };
      }

      return state;
    }
  },
  [DELETE_COMMENT_TAG_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof deleteCommentTagSuccessAction>,
  ) => {
    let commentIdx = state.comments.findIndex(({ id }) => id === action.payload.commentId);

    if (commentIdx > -1) {
      const comment = state.comments[commentIdx];
      const tags = comment.tags?.filter(tag => tag.id !== action.payload.tagId);

      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: Object.assign([], {
            ...comment,
            tags,
          }),
        }),
      };
    } else {
      // If it's a private conversation, the comment id is the parent id
      commentIdx = state.comments.findIndex(
        comment => comment.id === action.payload.parentCommentId,
      );

      if (commentIdx > -1) {
        const comment = state.comments[commentIdx];
        let message_tags = comment.message_tags;

        if (comment.post_placement && isPrivateConversation(comment.post_placement)) {
          message_tags = {
            ...comment.message_tags,
            managed_tags: removeFromTagsListing(
              comment.message_tags?.managed_tags || [],
              action.payload.tagId,
            ),
            count: (comment.message_tags?.count || 0) - 1,
          } as IMessageTags;
        }

        return {
          ...state,
          comments: Object.assign([], {
            ...state.comments,
            [commentIdx]: {
              ...comment,
              message_tags: Object.assign({}, message_tags),
            },
          }),
        };
      }

      return state;
    }
  },
  // COMMENTS CSV
  [GET_COMMENTS_CSV]: (state: ICommentsState) => ({
    ...state,
    fetchingCommentsCSV: true,
    fetchedCommentsCSV: false,
    fetchedCommentsCSVFail: false,
  }),
  [GET_COMMENTS_CSV_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof getCommentsCSVSuccessAction>,
  ) => ({
    ...state,
    commentsCSV: action.payload,
    fetchingCommentsCSV: false,
    fetchedCommentsCSV: true,
    fetchedCommentsCSVFail: false,
  }),
  [ASSIGN_COMMENT_USER]: (
    state: ICommentsState,
    action: ActionType<typeof assignCommentUserAction>,
  ) => {
    const commentIdx = state.comments.findIndex(({ id }) => id === action.payload.id);

    if (commentIdx > -1) {
      const { assigned_users: prevUsers } = state.comments[commentIdx];
      const { assigned_users: nextUsers = [] } = action.payload.data;
      const { userId: currentUser } = action.payload;

      if (state.replyType === "assigned_to_me" && currentUser) {
        const unassigned = prevUsers.includes(currentUser) && !nextUsers.includes(currentUser);
        if (unassigned) {
          return {
            ...state,
            comments: Object.assign([], {
              ...state.comments.filter(({ id }) => id !== action.payload.id),
            }),
            previouslyAssignedUsers: prevUsers,
          };
        }
      }

      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            ...action.payload.data,
          },
        }),
        previouslyAssignedUsers: prevUsers,
      };
    }

    return state;
  },
  [UPDATE_COMMENT_SENTIMENT]: (
    state: ICommentsState,
    action: ActionType<typeof updateCommentSentimentSuccessAction>,
  ) => {
    const commentIdx = state.comments.findIndex(({ id }) => id === action.payload.id);

    if (commentIdx > -1) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            ...action.payload.data,
          },
        }),
      };
    }

    return state;
  },
  [PERFORM_BULK_COMMENT_ACTION]: (
    state: ICommentsState,
    action: ActionType<typeof performBulkCommentAction>,
  ) => {
    const comments = state.comments?.filter(comment =>
      action.payload?.comment_ids.includes(comment.id),
    );

    if (!!comments.length)
      return {
        ...state,
        performingBatchActions: true,
        performedBatchActions: false,
        performedBatchActionsFailed: false,
        comments: Object.assign([], {
          ...state.comments,
          ...comments,
        }),
      };

    return state;
  },
  [PERFORM_BULK_COMMENT_ACTION_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof performBulkCommentActionSuccessAction>,
  ) => {
    const comments = state.comments.filter(comment =>
      action.payload?.commentAction.comment_ids.includes(comment.id),
    );

    if (!!comments.length) {
      if (action.payload.commentAction.actions?.includes("hide")) {
        return {
          ...state,
          performingBatchActions: false,
          performedBatchActions: true,
          comments: Object.assign([], {
            ...state.comments,
            ...comments.map(comment => ({
              ...comment,
              actions: {
                ...comment.actions,
                can_reply: canReplyToComment(comment, true),
              },

              is_hidden: action.payload.commentAction.actions?.includes("hide"),
            })),
          }),
        };
      }

      if (action.payload.commentAction.actions?.includes("review")) {
        return {
          ...state,
          performingBatchActions: false,
          performedBatchActions: true,
          comments: Object.assign([], {
            ...state.comments,
            ...comments.map(comment => ({
              ...comment,
              moderation: {
                ...comment.moderation,
                [ActionToReplyProp["review"]]: true,
              },
            })),
          }),
        };
      }

      // Flag/ignore actions are handled in `reply` prop of comment
      if (action.payload.commentAction.actions?.includes("ignore")) {
        return {
          ...state,
          performingBatchActions: false,
          performedBatchActions: true,
          comments: Object.assign([], {
            ...state.comments,
            ...comments.map(comment => ({
              ...comment,

              reply: {
                ...comment.reply,
                is_ignored: true,
                is_starred: false,
              },
            })),
          }),
        };
      }

      return {
        ...state,
        performingBatchActions: false,
        performedBatchActions: true,
        comments: Object.assign([], {
          ...state.comments,
          ...comments.map(comment => ({
            ...comment,
          })),
        }),
      };
    }

    return state;
  },
  [PERFORM_BULK_COMMENT_ACTION_FAILURE]: (state: ICommentsState) => {
    return {
      ...state,
      performingBatchActions: false,
      performedBatchActions: false,
      performedBatchActionsFailed: true,
      comments: Object.assign([], {
        ...state.comments?.map(comment => {
          return comment;
        }),
      }),
    };
  },

  [ADD_LINKEDIN_REACTION]: produce((draft, action) => {
    const comment = draft.comments.find(c => c.id === action.payload.commentId);
    if (comment) {
      draft.previousReactions[action.payload.commentId] = comment.reaction;
      comment.reaction = {
        type: action.payload.reaction,
        is_sent: true,
      };
    }
  }),

  [ADD_LINKEDIN_REACTION_SUCCESS]: produce((draft, action) => {
    const comment = draft.comments.find(c => c.id === action.payload.commentId);
    if (comment) {
      comment.reaction = {
        type: action.payload.reaction,
        is_sent: true,
      };
    }
  }),

  [DELETE_LINKEDIN_REACTION]: produce((draft, action) => {
    const comment = draft.comments.find(c => c.id === action.payload);
    if (comment) {
      draft.previousReactions[action.payload] = comment.reaction;
      comment.reaction = undefined;
    }
  }),

  [DELETE_LINKEDIN_REACTION_SUCCESS]: produce((draft, action) => {
    draft.previousReactions[action.payload] = undefined;
  }),

  [LINKEDIN_REACTION_FAILURE]: produce((draft, action) => {
    const prevReaction = draft.previousReactions[action.payload];
    const comment = draft.comments.find(c => c.id === action.payload);
    if (comment) {
      comment.reaction = prevReaction;
    }
    draft.previousReactions[action.payload] = undefined;
  }),

  [TRIGGER_NEXT_BATCH]: produce((draft, action) => {
    draft.triggerNextBatch = action.payload;
  }),
});
