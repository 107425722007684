import { RootState } from "../";

export const fromReplies = (state: RootState) => state.replies;

export const repliesSelector = (state: RootState) => fromReplies(state).data;

export const fetchingRepliesSelector = (state: RootState) => fromReplies(state).fetchingReplies;
export const getRepliesSuccessfulSelector = (state: RootState) => fromReplies(state).fetchedReplies;
export const getRepliesFailedSelector = (state: RootState) => fromReplies(state).fetchedRepliesFail;
export const repliesClearedSelector = (state: RootState) => fromReplies(state).clearReplies;

export const fetchingThreadRepliesSelector = (state: RootState) =>
  fromReplies(state).fetchingThreadReplies;
export const getThreadRepliesSuccessfulSelector = (state: RootState) =>
  fromReplies(state).fetchedThreadReplies;
export const getThreadRepliesFailedSelector = (state: RootState) =>
  fromReplies(state).fetchedThreadRepliesFail;
export const replyingThreadCommentSelector = (state: RootState) =>
  fromReplies(state).replyingThreadComment;
export const repliedThreadCommentSelector = (state: RootState) =>
  fromReplies(state).repliedThreadComment;

export const getSavedRepliesSelector = (state: RootState) => fromReplies(state).savedReplies;

export const getAISuggestedReplySelector = (state: RootState) =>
  fromReplies(state).aiSuggestedReply;

export const fetchAiSuggestedReplySelector = (state: RootState) =>
  fromReplies(state).fetchAiSuggestedReply;

export const searchedSavedRepliesSelector = (state: RootState) =>
  fromReplies(state).searchedSavedReplies;

export const repliesCursorSelector = (state: RootState) => fromReplies(state).data?.cursor;
export const postingReplySelector = (state: RootState) => fromReplies(state).postingCommentReply;
export const postedReplySelector = (state: RootState) => fromReplies(state).postedCommentReply;

export const deletingBrandReplySelector = (state: RootState) =>
  fromReplies(state)?.deletingBrandReply;
export const deletedReplyIdSelector = (state: RootState) => fromReplies(state)?.deletedReplyId;

export const getCreateSavedReplyCustomTagsSelector = (state: RootState) =>
  fromReplies(state).savedReplyCustomTags;

export const getSavedReplyBBTagsSelector = (state: RootState) =>
  fromReplies(state).savedReplyBbTags;

export const getReplyCommentsSelector = (state: RootState) => fromReplies(state).comments;

export const fetchingReplyCommentsSelector = (state: RootState) =>
  fromReplies(state).fetchingPostComments;

export const replyCommentsNavigationIdSelector = (state: RootState) =>
  fromReplies(state).navigation_id;

export const replyCommentsCursorSelector = (state: RootState) => fromReplies(state).cursor;

export const getDraftReplySelector = (commentId: string) => (state: RootState) => {
  const draftReplies = fromReplies(state).draftReplies;
  return draftReplies[commentId] || "";
};
