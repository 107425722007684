export const GET_REPLIES = "REPLIES/GET_REPLIES";
export const GET_REPLIES_SUCCESS = "REPLIES/GET_REPLIES_SUCCESS";
export const GET_REPLIES_FAILURE = "REPLIES/GET_REPLIES_FAILURE";

export const GET_THREAD_REPLIES = "REPLIES/GET_THREAD_REPLIES";
export const GET_THREAD_REPLIES_SUCCESS = "REPLIES/GET_THREAD_REPLIES_SUCCESS";
export const GET_THREAD_REPLIES_FAILURE = "REPLIES/GET_THREAD_REPLIES_FAILURE";

export const PATCH_REPLIES_PARENT_SENTIMENT = "REPLIES/PATCH_REPLIES_PARENT_SENTIMENT";
export const PATCH_REPLIES_PARENT_SENTIMENT_SUCCESS =
  "REPLIES/PATCH_REPLIES_PARENT_SENTIMENT_SUCCESS";
export const PATCH_REPLIES_PARENT_SENTIMENT_FAILURE =
  "REPLIES/PATCH_REPLIES_PARENT_SENTIMENT_FAILURE";

export const PATCH_REPLIES_ITEM_SENTIMENT = "REPLIES/PATCH_REPLIES_ITEM_SENTIMENT";
export const PATCH_REPLIES_ITEM_SENTIMENT_SUCCESS = "REPLIES/PATCH_REPLIES_ITEM_SENTIMENT_SUCCESS";
export const PATCH_REPLIES_ITEM_SENTIMENT_FAILURE = "REPLIES/PATCH_REPLIES_ITEM_SENTIMENT_FAILURE";

export const POST_SAVED_REPLIES = "REPLIES/POST_SAVED_REPLIES";
export const POST_SAVED_REPLIES_SUCCESS = "REPLIES/POST_SAVED_REPLIES_SUCCESS";
export const POST_SAVED_REPLIES_FAILURE = "REPLIES/POST_SAVED_REPLIES_FAILURE";

export const DELETE_SAVED_REPLIES = "REPLIES/DELETE_SAVED_REPLIES";
export const DELETE_SAVED_REPLIES_SUCCESS = "REPLIES/DELETE_SAVED_REPLIES_SUCCESS";
export const DELETE_SAVED_REPLIES_FAILURE = "REPLIES/DELETE_SAVED_REPLIES_FAILURE";

export const DELETE_BRAND_REPLY = "REPLIES/DELETE_BRAND_REPLY";
export const DELETE_BRAND_REPLY_SUCCESS = "REPLIES/DELETE_BRAND_REPLY_SUCCESS";
export const DELETE_BRAND_REPLY_FAILURE = "REPLIES/DELETE_BRAND_REPLY_FAILURE";

export const GET_REPLIES_PAGE = "REPLIES/GET_REPLIES_PAGE";
export const GET_REPLIES_PAGE_SUCCESS = "REPLIES/GET_REPLIES_PAGE_SUCCESS";

export const POST_COMMENT_REPLY = "REPLIES/POST_COMMENT_REPLY";
export const POST_COMMENT_REPLY_SUCCESS = "REPLIES/POST_COMMENT_REPLY_SUCCESS";
export const POST_COMMENT_REPLY_FAILURE = "REPLIES/POST_COMMENT_REPLY_FAILURE";

export const POST_SAVED_REPLY = "REPLIES/POST_SAVED_REPLY";
export const POST_SAVED_REPLY_SUCCESS = "REPLIES/POST_SAVED_REPLY_SUCCESS";
export const POST_SAVED_REPLY_FAILURE = "REPLIES/POST_SAVED_REPLY_FAILURE";

export const PUT_SAVED_REPLY = "REPLIES/PUT_SAVED_REPLY";
export const PUT_SAVED_REPLY_SUCCESS = "REPLIES/PUT_SAVED_REPLY_SUCCESS";
export const PUT_SAVED_REPLY_FAILURE = "REPLIES/PUT_SAVED_REPLY_FAILURE";

export const GET_REPLIES_AI_SUGGESTION = "REPLIES/GET_REPLIES_AI_SUGGESTION";
export const GET_REPLIES_AI_SUGGESTION_SUCCESS = "REPLIES/GET_REPLIES_AI_SUGGESTION_SUCCESS";
export const GET_REPLIES_AI_SUGGESTION_FAILURE = "REPLIES/GET_REPLIES_AI_SUGGESTION_FAILURE";

export const PERFORM_REPLIES_ITEMS_ACTION_SUCCESS = "REPLIES/PERFORM_REPLIES_ITEMS_ACTION_SUCCESS";
export const PERFORM_REPLIES_ITEMS_ACTION_SUCCESS_SUCCESS =
  "REPLIES/PERFORM_REPLIES_ITEMS_ACTION_SUCCESS_SUCCESS";
export const PERFORM_REPLIES_ITEMS_ACTION_SUCCESS_FAILURE =
  "REPLIES/PERFORM_REPLIES_ITEMS_ACTION_SUCCESS_FAILURE";

export const PERFORM_REPLIES_PARENT_ACTION_SUCCESS =
  "REPLIES/PERFORM_REPLIES_PARENT_ACTION_SUCCESS";
export const PERFORM_REPLIES_PARENT_ACTION_SUCCESS_SUCCESS =
  "REPLIES/PERFORM_REPLIES_PARENT_ACTION_SUCCESS_SUCCESS";
export const PERFORM_REPLIES_PARENT_ACTION_SUCCESS_FAILURE =
  "REPLIES/PERFORM_REPLIES_PARENT_ACTION_SUCCESS_FAILURE";

export const POST_REPLY_BB_TAG = "REPLIES/POST_REPLY_BB_TAG";
export const DELETE_REPLY_BB_TAG = "REPLIES/DELETE_REPLY_BB_TAG";

export const SET_SAVED_REPLY_BB_TAGS = "REPLIES/SET_SAVED_REPLY_BB_TAGS";
export const SET_SAVED_REPLY_CUSTOM_TAGS = "REPLIES/SET_SAVED_REPLY_CUSTOM_TAGS";

export const CLEAR_REPLIES = "REPLIES/CLEAR_REPLIES";
export const CLEAR_SAVED_REPLY_ALL_TAGS = "REPLIES/CLEAR_SAVED_REPLY_ALL_TAGS";
export const CLEAR_REPLIES_COMMENT_LIST = "REPLIES/CLEAR_REPLIES_COMMENT_LIST";
export const CLEAR_REPLYING_THREAD_COMMENT = "REPLIES/CLEAR_REPLYING_THREAD_COMMENT";

export const SET_DRAFT_REPLY = "REPLIES/SET_DRAFT_REPLY";
