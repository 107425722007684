import { createReducer, ActionType } from "typesafe-actions";
import { IGuidelinesState, IGuidelinesResponse } from "./types";
import { getGuidelinesActionSuccess } from "./actions";
import {
  GET_MODERATION_GUIDELINES,
  GET_MODERATION_GUIDELINES_SUCCESS,
  GET_MODERATION_GUIDELINES_FAILURE,
} from "./actionTypes";

const initialState = {
  moderationGuidelines: {} as IGuidelinesResponse,
  fetchingGetGuidelines: false,
  fetchedGetGuidelines: false,
  fetchedGetGuidelinesFail: false,
};

export const moderationGuidelinesReducer = createReducer<IGuidelinesState>(initialState, {
  [GET_MODERATION_GUIDELINES]: (state: IGuidelinesState) => ({
    ...state,
    fetchingGetGuidelines: true,
    fetchedGetGuidelines: false,
    fetchedGetGuidelinesFail: false,
  }),
  [GET_MODERATION_GUIDELINES_SUCCESS]: (
    state: IGuidelinesState,
    action: ActionType<typeof getGuidelinesActionSuccess>,
  ) => ({
    ...state,
    moderationGuidelines: action.payload,
    fetchingGetGuidelines: false,
    fetchedGetGuidelines: true,
    fetchedGetGuidelinesFail: false,
  }),
  [GET_MODERATION_GUIDELINES_FAILURE]: (state: IGuidelinesState) => ({
    ...state,
    fetchingGetGuidelines: false,
    fetchedGetGuidelines: false,
    fetchedGetGuidelinesFail: true,
  }),
});
