import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { ISourcesState } from "./types";

const initialState: ISourcesState = {
  sources: [],

  fetchingSourcesList: false,
  fetchedSourcesList: false,
  fetchedSourcesListFail: false,

  creatingSource: false,
  createdSource: false,
  creatingSourceFailed: false,
  creatingSourceFailedMessage: "",

  updatingSource: false,
  updatedSource: false,
  updatingSourceFailed: false,
  updatingSourceFailedMessage: "",

  deletingSource: false,
  deletedSource: false,
  deletingSourceFailed: false,
  deletingSourceFailedMessage: "",
};

const clearMutationsState = (draft: ISourcesState) => {
  draft.creatingSource = false;
  draft.createdSource = false;
  draft.creatingSourceFailed = false;

  draft.updatingSource = false;
  draft.updatedSource = false;
  draft.updatingSourceFailed = false;

  draft.deletingSource = false;
  draft.deletedSource = false;
  draft.deletingSourceFailed = false;
};

export const sourcesReducer = createReducer<ISourcesState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getSourcesListAction, state =>
    produce(state, draft => {
      draft.fetchingSourcesList = true;
      draft.fetchedSourcesList = false;
      draft.fetchedSourcesListFail = false;
    }),
  )
  .handleAction(actions.getSourcesListSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.sources = action.payload.items.map(source => ({
        ...source,
        type_id: source.type.id,
      }));
      draft.fetchingSourcesList = false;
      draft.fetchedSourcesList = true;
      draft.fetchedSourcesListFail = false;
    }),
  )
  .handleAction(actions.getSourcesListFailureAction, state =>
    produce(state, draft => {
      draft.sources = [];
      draft.fetchingSourcesList = false;
      draft.fetchedSourcesList = false;
      draft.fetchedSourcesListFail = true;
    }),
  )
  .handleAction(actions.createSourceAction, state =>
    produce(state, draft => {
      clearMutationsState(draft);

      draft.creatingSource = true;
      draft.createdSource = false;
      draft.creatingSourceFailed = false;
    }),
  )
  .handleAction(actions.createSourceSuccessAction, state =>
    produce(state, draft => {
      draft.creatingSource = false;
      draft.createdSource = true;
      draft.creatingSourceFailed = false;
    }),
  )
  .handleAction(actions.createSourceFailureAction, (state, action) =>
    produce(state, draft => {
      draft.creatingSource = false;
      draft.createdSource = false;
      draft.creatingSourceFailed = true;
      draft.creatingSourceFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.updateSourceAction, state =>
    produce(state, draft => {
      clearMutationsState(draft);

      draft.updatingSource = true;
      draft.updatedSource = false;
      draft.updatingSourceFailed = false;
    }),
  )
  .handleAction(actions.updateSourceSuccessAction, state =>
    produce(state, draft => {
      draft.updatingSource = false;
      draft.updatedSource = true;
      draft.updatingSourceFailed = false;
    }),
  )
  .handleAction(actions.updateSourceFailureAction, (state, action) =>
    produce(state, draft => {
      draft.updatingSource = false;
      draft.updatedSource = false;
      draft.updatingSourceFailed = true;
      draft.updatingSourceFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.deleteSourceAction, state =>
    produce(state, draft => {
      clearMutationsState(draft);

      draft.deletingSource = true;
      draft.deletedSource = false;
      draft.deletingSourceFailed = false;
    }),
  )
  .handleAction(actions.deleteSourceSuccessAction, state =>
    produce(state, draft => {
      draft.deletingSource = false;
      draft.deletedSource = true;
      draft.deletingSourceFailed = false;
    }),
  )
  .handleAction(actions.deleteSourceFailureAction, (state, action) =>
    produce(state, draft => {
      draft.deletingSource = false;
      draft.deletedSource = false;
      draft.deletingSourceFailed = true;
      draft.deletingSourceFailedMessage = action.payload;
    }),
  );
