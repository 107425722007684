import { StateObservable } from "redux-observable";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "../";
import { Actions, IGuidelinesResponse } from "./types";
import { GET_MODERATION_GUIDELINES } from "./actionTypes";
import { getGuidelinesActionSuccess, getGuidelinesActionFailure } from "./actions";

import { moderationGuidelines } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const getAssetsEpic = (action$: Observable<Actions>, state$: StateObservable<RootState>) => {
  return action$.pipe(
    filter(isOfType(GET_MODERATION_GUIDELINES)),
    switchMap(() => {
      return ajax
        .get<IGuidelinesResponse>(
          moderationGuidelines,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getGuidelinesActionSuccess(data)),
          catchError(e => handleError(e, getGuidelinesActionFailure)),
        );
    }),
  );
};
