import { createReducer, ActionType } from "typesafe-actions";
import { IKpiDataState } from "./types";
import { getKpiDataSuccessAction } from "./actions";
import { GET_KPI_DATA, GET_KPI_DATA_SUCCESS, GET_KPI_DATA_FAILURE } from "./actionTypes";

const initialState = {
  data: null,
  fetching: false,
  fetched: false,
  fetchedFail: false,
};

export const kpiDataReducer = createReducer<IKpiDataState>(initialState, {
  [GET_KPI_DATA]: (state: IKpiDataState) => ({
    ...state,
    fetched: false,
    fetching: true,
  }),
  [GET_KPI_DATA_SUCCESS]: (
    state: IKpiDataState,
    action: ActionType<typeof getKpiDataSuccessAction>,
  ) => ({
    ...state,
    data: action.payload,
    fetched: true,
    fetching: false,
  }),
  [GET_KPI_DATA_FAILURE]: (state: IKpiDataState) => ({
    ...state,
    fetched: false,
    fetching: false,
    fetchedFail: true,
  }),
});
