import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Card, TabNavItem, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import SentimentBar from "@components/_common/SentimentBar/SentimentBar";

import s from "./ReplyModal.module.less";

import { getPlatformIconBasicColor } from "@utils/platform";
import { IUserDetailsProps, CategoryType } from "./ReplyModal.type";

import { HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { CommentIcon, MentionIcon, SendIcon, StarIcon, HashIcon, MapPinIcon } from "@assets/index";

const COMMENT_CATEGORY_ICON_MAP = {
  comments: <CommentIcon />,
  private_messages: <SendIcon />,
  public_mentions: <MentionIcon />,
  reviews: <StarIcon />,
  hashtag_mentions: <HashIcon />,
};

const UserDetails: FC<IUserDetailsProps> = ({
  replyData,
  selectedCategory,
  onChangeCategory,
  onRefresh,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbUserDetails}>
      <div>
        <div className={s.bbUserDetailsTitle}>{t("components:reply:userDetails")}</div>
        <Card className={s.bbAuthorDetailsCard}>
          <div className={s.bbAuthorDetails}>
            <div className={s.bbPlatformIcon}>
              {getPlatformIconBasicColor(replyData.author_detail.platform_type.id)}
            </div>
            <div className={s.bbAuthorName}>{replyData.author_detail.author_name}</div>
          </div>
          {!!replyData.author_detail.author_location && (
            <div className={s.bbAuthorLocation}>
              <MapPinIcon />
              <span>{replyData.author_detail.author_location}</span>
            </div>
          )}
          {replyData.author_detail.author_total_reviews_count && (
            <div className={s.bbAuthorTotalReviews}>
              <span>{`
              ${replyData.author_detail.author_total_reviews_count} ${t(
                "components:reply:reviews:trustpilot:label",
              )}`}</span>
              <Tooltip title={t("components:reply:reviews:trustpilot:tooltip")}>
                <span className={s.bbAuthorTotalReviewsHelp}>
                  <HelpIcon />
                </span>
              </Tooltip>
            </div>
          )}
          <div className={s.bbAuthorTotalConversations}>
            {replyData.author_detail.comment_stats.total_comments}
            {replyData.post_placement === "REVIEW"
              ? t("components:reply:totalReviews")
              : t("components:reply:totalConversations")}
          </div>
          {replyData.author_detail.show_sentiment_bar && (
            <SentimentBar height={12} data={replyData.author_detail.comment_stats} />
          )}
        </Card>
        <div className={s.bbUserDetailsNavigation}>
          {replyData.author_detail.categories.map(category => {
            const categoryId = category.id as CategoryType;
            const icon = COMMENT_CATEGORY_ICON_MAP[categoryId];

            return (
              <TabNavItem
                key={category.id}
                icon={icon}
                label={category.label}
                count={category.count}
                isNotClickable={category.is_disabled}
                onClick={() => {
                  if (!category.is_disabled) {
                    onChangeCategory(category);
                  }
                }}
                isActive={selectedCategory?.id === category.id}
              />
            );
          })}
        </div>
      </div>
      <div>
        {!!selectedCategory?.updated_information && (
          <div className={s.bbLastUpdated}>
            {selectedCategory.updated_information}{" "}
            <span onClick={onRefresh} className={s.bbLastUpdatedRefresh}>
              {t("generic:refresh")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
