import { createReducer, ActionType } from "typesafe-actions";
import { IAlertsState } from "./types";
import { getAlertsActionSuccess, getAlertCommentsActionSuccess } from "./actions";
import {
  GET_ALERTS,
  RESET_ALERTS_DATA,
  GET_ALERTS_SUCCESS,
  GET_ALERTS_FAILURE,
  GET_ALERT_COMMENTS,
  CLEAR_ALERT_COMMENTS,
  GET_ALERT_COMMENTS_SUCCESS,
  GET_ALERT_COMMENTS_FAILURE,
} from "./actionTypes";

const initialState = {
  data: [],
  cursor: undefined,
  fetchedAlerts: false,
  fetchingAlerts: false,
  fetchedAlertsFail: false,

  comments: [],
  fetchingAlertComments: false,
  fetchedAlertComments: false,
  fetchedAlertCommentsFail: false,
};

export const alertsReducer = createReducer<IAlertsState>(initialState, {
  [GET_ALERTS]: (state: IAlertsState) => ({
    ...state,
    fetchingAlerts: true,
    fetchedAlerts: false,
    fetchedAlertsFail: false,
  }),
  [GET_ALERTS_SUCCESS]: (
    state: IAlertsState,
    action: ActionType<typeof getAlertsActionSuccess>,
  ) => ({
    ...state,
    comments: [],
    data: [...state.data, ...action.payload.data],
    cursor: action.payload.cursor,
    fetchingAlerts: false,
    fetchedAlerts: true,
    fetchedAlertsFail: false,
  }),
  [GET_ALERTS_FAILURE]: (state: IAlertsState) => ({
    ...state,
    fetchingAlerts: false,
    fetchedAlerts: false,
    fetchedAlertsFail: true,
  }),
  [RESET_ALERTS_DATA]: (state: IAlertsState) => ({
    ...state,
    data: [],
    comments: [],
    fetchedAlerts: false,
    fetchingAlerts: false,
    fetchedAlertsFail: false,
  }),
  [GET_ALERT_COMMENTS]: (state: IAlertsState) => ({
    ...state,
    comments: [],
    fetchingAlertComments: true,
    fetchedAlertComments: false,
    fetchedAlertCommentsFail: false,
  }),
  [GET_ALERT_COMMENTS_SUCCESS]: (
    state: IAlertsState,
    action: ActionType<typeof getAlertCommentsActionSuccess>,
  ) => ({
    ...state,
    comments: action.payload,
    fetchingAlertComments: false,
    fetchedAlertComments: true,
    fetchedAlertCommentsFail: false,
  }),
  [GET_ALERT_COMMENTS_FAILURE]: (state: IAlertsState) => ({
    ...state,
    fetchingAlertComments: false,
    fetchedAlertComments: false,
    fetchedAlertCommentsFail: true,
  }),
  [CLEAR_ALERT_COMMENTS]: (state: IAlertsState) => ({
    ...state,
    comments: [],
    fetchingAlertComments: false,
    fetchedAlertComments: false,
    fetchedAlertCommentsFail: false,
  }),
});
