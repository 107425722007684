import { FormInstance } from "antd";
import { useEffect, useState } from "react";

import { IObjectOption, handleMultiselectState } from "@bbdevcrew/bb_ui_kit_fe";

export function useTagState(
  options: IObjectOption[],
  customprops?: { form?: FormInstance; name: string },
  selectedValues?: string[],
) {
  const [excludedValues, setExcludedValues] = useState<string[]>([]);
  const [tagFilterValues, setTagFilterValues] = useState<string[]>([]);

  const _onChange = (tagValue: string) => {
    const newState = handleMultiselectState(options, tagFilterValues, tagValue);
    const newExcludedState = excludedValues?.filter(exv => !newState.includes(exv));

    setTagFilterValues(newState);
    setExcludedValues(newExcludedState);

    if (customprops?.form) {
      customprops?.form.setFieldsValue({
        [customprops.name]: newState || undefined,
      });

      const currentExcludedFilters = customprops.form.getFieldValue("exclusion_filters") || {};

      customprops?.form.setFieldsValue({
        exclusion_filters: {
          ...currentExcludedFilters,
          [customprops.name]: newExcludedState || undefined,
        },
      });

      return;
    }
  };

  const _onExcludeChange = (tagValue: string) => {
    const newState = handleMultiselectState(options, excludedValues, tagValue);
    const newSelectedState = tagFilterValues.filter(tag => !newState.includes(tag));

    setExcludedValues(newState);
    setTagFilterValues(newSelectedState);

    if (customprops?.form) {
      const currentExcludedFilters = customprops.form.getFieldValue("exclusion_filters") || {};

      customprops?.form.setFieldsValue({
        exclusion_filters: {
          ...currentExcludedFilters,
          [customprops.name]: newState || undefined,
        },
      });
      customprops?.form.setFieldsValue({
        [customprops.name]: newSelectedState || undefined,
      });
    }
  };

  const _onClear = () => {
    setTagFilterValues([]);
    customprops?.form?.setFieldsValue({ [customprops.name]: [] });

    setExcludedValues([]);
    customprops?.form?.setFieldsValue({
      exclusion_filters: {
        ...customprops?.form.getFieldValue("exclusion_filters"),
        [customprops.name]: [],
      },
    });
  };

  const _setTagFilterValues = (values: string[]) => {
    setTagFilterValues(values);

    if (customprops?.form) {
      customprops?.form.setFieldsValue({
        [customprops.name]: values || undefined,
      });
    }
  };

  useEffect(() => {
    if (selectedValues) setTagFilterValues(selectedValues);
    // eslint-disable-next-line
  }, [selectedValues?.length]);

  useEffect(() => {
    if (customprops) {
      const value = customprops?.form?.getFieldValue(customprops.name) || [];
      const currentExcludedFilters = customprops?.form?.getFieldValue("exclusion_filters") || {};

      setTagFilterValues(value);
      setExcludedValues(currentExcludedFilters[customprops.name] || []);
    }
    // eslint-disable-next-line
  }, [customprops?.form]);

  return {
    tagFilterValues,
    excludedValues,
    _setTagFilterValues,
    _onChange,
    _onExcludeChange,
    _onClearValues: _onClear,
  };
}

export function useSingleSelectState(
  customprops?: { form?: FormInstance; name: string },
  incomingValue?: string,
) {
  const [selectValue, setSelectValue] = useState<string>();

  const _onChange = (value: string) => {
    setSelectValue(value);

    if (customprops?.form) {
      customprops?.form.setFieldsValue({
        [customprops.name]: value || undefined,
      });
    }
  };

  useEffect(() => {
    if (incomingValue) {
      setSelectValue(incomingValue);
      customprops?.form?.setFieldsValue({
        [customprops.name]: incomingValue || undefined,
      });
    }
    // eslint-disable-next-line
  }, [incomingValue]);

  useEffect(() => {
    if (customprops) {
      setSelectValue(customprops?.form?.getFieldValue(customprops.name) || undefined);
    }
    // eslint-disable-next-line
  }, [customprops?.form]);

  return {
    selectValue,
    _onChange,
  };
}
