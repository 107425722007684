import jstz from "jstz";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { createRef, FC, MouseEvent } from "react";

import s from "./Step2.module.less";
import footerStyle from "../footer/Footer.module.less";

import {
  Button,
  Toggle,
  InputText,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Typography, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import SanitizedInputValidation from "@components/_common/SanitizedInputValidation";

import { IStep2Props, IFormStep2 } from "./Step2.type";

import { signingUserUpSelector } from "@store/user/selectors";

const { Title } = Typography;

const Step2View: FC<IStep2Props> = ({ isAgencyView, onFinish, onReturn }) => {
  const formRef = createRef<FormInstance>();
  const { t } = useTranslation();

  const userSigningUp = useSelector(signingUserUpSelector);

  const onInternalReturn = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onReturn();
  };

  const onInternalFinish = (values: IFormStep2) =>
    onFinish({
      ...values,
      is_agency: isAgencyView ? true : !!values.is_agency,
    });

  return (
    <div className={s.bbStep2}>
      <Title level={5} className={s.bbStep2Title}>
        {isAgencyView ? t("pages:signup:agency:step2:title") : t("pages:signup:step2:right:title")}
      </Title>
      <div className={s.bbStep2Form}>
        <Form
          ref={formRef}
          id="step3form"
          hideRequiredMark
          layout="vertical"
          onFinish={onInternalFinish}
          className={s.bbStep2MaxWidth}
        >
          {isAgencyView && (
            <Form.Item
              name="company_name"
              label={t("pages:signup:agency:step2:agencyName")}
              validateTrigger="onBlur"
              validateFirst
              rules={[
                {
                  required: true,
                  type: "string",
                  message: t("pages:signup:step2:right:form:company_name:required"),
                },
                { ...SanitizedInputValidation },
              ]}
            >
              <InputText
                round
                size="large"
                thickinfocolor
                maxLength={50}
                autoFocus={isAgencyView}
              />
            </Form.Item>
          )}
          <Form.Item
            name="first_name"
            label={
              isAgencyView
                ? t("pages:signup:agency:step2:firstName")
                : t("pages:signup:step2:right:form:first_name:label")
            }
            validateTrigger="onBlur"
            validateFirst
            rules={[
              {
                required: true,
                type: "string",
                message: t("pages:signup:step2:right:form:first_name:required"),
              },
              { ...SanitizedInputValidation },
            ]}
          >
            <InputText round size="large" thickinfocolor maxLength={50} autoFocus={!isAgencyView} />
          </Form.Item>
          <Form.Item
            name="last_name"
            label={
              isAgencyView
                ? t("pages:signup:agency:step2:lastName")
                : t("pages:signup:step2:right:form:last_name:label")
            }
            validateTrigger="onBlur"
            validateFirst
            rules={[
              {
                required: true,
                type: "string",
                message: t("pages:signup:step2:right:form:last_name:required"),
              },
              { ...SanitizedInputValidation },
            ]}
          >
            <InputText round thickinfocolor maxLength={50} size="large" />
          </Form.Item>

          {!isAgencyView && (
            <Form.Item
              name="company_name"
              label={t("pages:signup:step2:right:form:company_name:label")}
              validateTrigger="onBlur"
              validateFirst
              rules={[
                {
                  required: true,
                  type: "string",
                  message: t("pages:signup:step2:right:form:company_name:required"),
                },
                { ...SanitizedInputValidation },
              ]}
            >
              <InputText round thickinfocolor maxLength={50} size="large" />
            </Form.Item>
          )}
          <Form.Item hidden={true} initialValue={jstz.determine().name()} />
          {!isAgencyView && (
            <div className={s.bbStep2Agency}>
              <Form.Item noStyle validateFirst name="is_agency" valuePropName="checked">
                <Toggle id="is_agency" />
              </Form.Item>
              <span className={s.bbStep2AgencyLabel}>
                {t("pages:signup:step2:right:form:is_agency:label")}
              </span>
            </div>
          )}
        </Form>
      </div>

      <div className={footerStyle.bbStepFooter}>
        <Button
          _size="lg"
          _type="custom"
          onClick={onInternalReturn}
          className={footerStyle.bbStepFooterBackBtn}
        >
          <ChevronLeftIcon />
          {t("generic:back")}
        </Button>
        <Button
          _size="lg"
          _type="primary"
          form="step3form"
          type="submit"
          loading={userSigningUp}
          disabled={userSigningUp}
        >
          {t("generic:continue")}
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};

export default Step2View;
