import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { IListenProjectsState } from "./types";

const initialState: IListenProjectsState = {
  cursor: "",
  comments: [],
  projects: [],

  fetchingListenProjects: false,
  fetchedListenProjects: false,
  fetchedListenProjectsFail: false,

  fetchingListenProjectsSectionComments: false,
  fetchedListenProjectsSectionComments: false,
  fetchedListenProjectsSectionCommentsFail: false,
};

export const listenReducer = createReducer<IListenProjectsState, ActionType<typeof actions>>(
  initialState,
)
  .handleAction(actions.getListenProjectsAction, state =>
    produce(state, draft => {
      draft.fetchingListenProjects = true;
      draft.fetchedListenProjects = false;
      draft.fetchedListenProjectsFail = false;
    }),
  )
  .handleAction(actions.getListenProjectsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.projects = action.payload.items;
      draft.fetchingListenProjects = false;
      draft.fetchedListenProjects = true;
      draft.fetchedListenProjectsFail = false;
    }),
  )
  .handleAction(actions.getListenProjectsFailureAction, state =>
    produce(state, draft => {
      draft.projects = [];
      draft.fetchingListenProjects = false;
      draft.fetchedListenProjects = false;
      draft.fetchedListenProjectsFail = true;
    }),
  )
  .handleAction(actions.getListenProjectCommentsAction, state =>
    produce(state, draft => {
      draft.fetchingListenProjectsSectionComments = true;
      draft.fetchedListenProjectsSectionComments = false;
      draft.fetchedListenProjectsSectionCommentsFail = false;
    }),
  )
  .handleAction(actions.getListenProjectCommentsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.comments = action.payload.items;
      draft.cursor = action.payload.cursor;
      draft.info = action.payload.info;
      draft.navigation_id = action.payload.navigation_id;
      draft.fetchingListenProjectsSectionComments = false;
      draft.fetchedListenProjectsSectionComments = true;
      draft.fetchedListenProjectsSectionCommentsFail = false;
    }),
  )
  .handleAction(actions.getListenProjectCommentsFailureAction, state =>
    produce(state, draft => {
      draft.fetchingListenProjectsSectionComments = false;
      draft.fetchedListenProjectsSectionComments = false;
      draft.fetchedListenProjectsSectionCommentsFail = true;
    }),
  );
