import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./BreakdownChartLegend.module.less";

const BreakdownChartLegend: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbBreakdownLegend}>
      <div className={s.bbBreakdownLegendSentiments}>
        <span className={s.bbBreakdownLegendSentiment}>
          <div className={s.bbLegendBrandSafety}></div>
          {t("components:postTrends:charts:brand_safety")}
        </span>
        <span className={s.bbBreakdownLegendSentiment}>
          <div className={s.bbLegendBrandSuitability}></div>
          {t("components:postTrends:charts:brand_suitability")}
        </span>
      </div>
    </div>
  );
};

export default BreakdownChartLegend;
