import { TFunction } from "i18next";

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_DAY = 86400;
const SECONDS_IN_MONTH = 2592000;
const SECONDS_IN_YEAR = 31536000;

export const extractDate = (date: string) => {
  const d = new Date(date.replace(/-/g, "/"));
  const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(d);
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(d);
  const day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(d);

  return `${day} ${month}, ${year}`;
};

export const dateList = (t: TFunction, isYTSelected: boolean) => [
  {
    value: "current_day",
    label: t("components:filters:comments:items:dataRangeOptions:current_day"),
  },
  {
    value: "yesterday",
    label: t("components:filters:comments:items:dataRangeOptions:yesterday"),
  },
  {
    value: "last_7_days",
    label: t("components:filters:comments:items:dataRangeOptions:last_7_days"),
  },
  {
    value: "last_30_days",
    label: t("components:filters:comments:items:dataRangeOptions:last_30_days"),
  },
  {
    value: "last_12_months",
    label: t("components:filters:comments:items:dataRangeOptions:last_12_months"),
    disabled: isYTSelected,
    tooltip: isYTSelected ? t("components:filters:dates:ytNoAnalysis") : "",
  },
  {
    value: "",
    label: t("components:filters:comments:items:dataRangeOptions:all_time"),
    disabled: isYTSelected,
    tooltip: isYTSelected ? t("components:filters:dates:ytNoAnalysis") : "",
  },
  {
    value: "custom",
    label: t("components:filters:comments:items:dataRangeOptions:custom"),
  },
];

export const fromNow = (date: Date) => {
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);
  let interval = seconds / SECONDS_IN_YEAR;

  if (interval > 1) return Math.floor(interval) + " years";

  interval = seconds / SECONDS_IN_MONTH;
  if (interval > 1) return Math.floor(interval) + " months";

  interval = seconds / SECONDS_IN_DAY;
  if (interval > 1) return Math.floor(interval) + " days";

  interval = seconds / SECONDS_IN_HOUR;
  if (interval > 1) return Math.floor(interval) + " hours";

  interval = seconds / SECONDS_IN_MINUTE;
  if (interval > 1) return Math.floor(interval) + " minutes";

  return Math.floor(seconds) + " seconds";
};

export const hoursFromNow = (timestamp: number) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return (currentTimestamp - timestamp) / 3600;
};

export const daysFromNow = (date: Date) => {
  const now = new Date();
  const difference = date.getTime() - now.getTime();
  const daysLeft = Math.ceil(difference / (1000 * 3600 * 24));

  return daysLeft;
};
