import { useTranslation } from "react-i18next";
import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import SaveFilterModal from "./SaveFilterModal";
import { SavedFiltersList } from "./SavedFiltersList";
import DeleteFilterModal from "./DeleteFilterModal";

import {
  postSavedFilterAction,
  deleteSavedFilterAction,
  resetSavedFiltersDataAction,
} from "@store/savedFilters/actions";
import { predefinedFiltersSelector } from "@store/savedFilters/selectors";

import { ISaveForm, ISavedFiltersProps } from "./types";
import { IFilterItem } from "../../AppFilters.type";

import s from "./SavedFilters.module.less";

export const SavedFilter: React.FC<ISavedFiltersProps> = ({
  onSavedChange,
  savedFilterValue,
  initialFilterData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isSavedFilterModalOpen, setIsSavedFilterModalOpen] = useState(false);
  const [isDeleteFilterModalOpen, setIsDeleteFilterModalOpen] = useState(false);
  const [deleteFilterItem, setDeleteFilterItem] = useState<IFilterItem>();
  const [isSavedFilterOpen, setIsSavedFilterOpen] = useState(false);

  const predefinedFilters = useSelector(predefinedFiltersSelector);

  const savedFilters = useMemo(() => {
    return predefinedFilters.find(({ id }) => id === "savedFilters")?.items;
  }, [predefinedFilters]);

  const onSaveFilter = (formData: ISaveForm, overwrite?: boolean) => {
    return dispatch(
      postSavedFilterAction({
        name: formData.name,
        overwrite,
        request: initialFilterData,
      }),
    );
  };

  const onDeleteFilter = () => {
    deleteFilterItem?.id && dispatch(deleteSavedFilterAction(deleteFilterItem?.id.toString()));

    setIsDeleteFilterModalOpen(false);
  };

  const onSavedFilterModalCancel = () => {
    setIsSavedFilterModalOpen(false);
    dispatch(resetSavedFiltersDataAction());
  };

  const onDropdownClick = (open: boolean) => {
    setIsSavedFilterOpen(open);
  };

  const onDropdownToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsSavedFilterOpen(!isSavedFilterOpen);
  };

  const onSavedModalClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsSavedFilterModalOpen(true);
  };

  const handleDeleteSavedFilter = (item: IFilterItem) => {
    if (item) {
      setDeleteFilterItem(item);
    }
    setIsDeleteFilterModalOpen(true);
  };

  return (
    <div className={s.bbSavedFilter}>
      <Button _type="custom" onClick={onSavedModalClick} className={s.bbSavedFilterBtn}>
        {t("generic:save")}
      </Button>

      <SavedFiltersList
        isSavedFilterOpen={isSavedFilterOpen}
        predefinedFilters={predefinedFilters}
        onSavedChange={onSavedChange}
        onDropdownClick={onDropdownClick}
        onDropdownToggle={onDropdownToggle}
        savedFilterValue={savedFilterValue}
        handleDeleteSavedFilter={handleDeleteSavedFilter}
      />
      {isSavedFilterModalOpen && (
        <SaveFilterModal
          onSaveFilter={onSaveFilter}
          onSaveFilterCancel={onSavedFilterModalCancel}
          savedFilters={savedFilters || []}
        />
      )}
      {isDeleteFilterModalOpen && (
        <DeleteFilterModal
          deleteFilterItem={deleteFilterItem}
          onDeleteFilter={onDeleteFilter}
          onDeleteFilterCancel={() => setIsDeleteFilterModalOpen(false)}
        />
      )}
    </div>
  );
};
