import classNames from "classnames";
import { useSelector } from "react-redux";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import BatchModeButton from "@containers/BatchMode/BatchModeButton";
import { InputWhite, SelectStyled, IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import BatchModeSelectAll from "@containers/BatchMode/BatchModeSelectAll";
import AssignToMeButton from "../../../_common/AssignToMeButton/AssignToMeButton";

import { assignToMeLoadingSelector } from "@store/assign/selectors";
import { inboxStatsSelector } from "@store/inboxStats/selectors";

import s from "./InboxCommentsHeader.module.less";

import { getDefaultSortOptions, getDefaultReplyPlatformTypeOptions } from "@utils/comments";
import { IInboxCommentsHeaderProps, RepliedMapEnum } from "./types";
import { IInboxStats } from "@store/inboxStats/types";
import { useBatchMode } from "@containers/BatchMode/BatchMode";

export const InboxCommentsHeader: FC<IInboxCommentsHeaderProps> = ({
  statTabValue,
  statTabParent,
  sortValue,
  replyPlatformTypeValue,
  onSortChange,
  onClickAssignToMe,
  onReplyPlatformChange,
}) => {
  const { t } = useTranslation();
  const { isBatchModeActive } = useBatchMode();

  const assignToMeLoading = useSelector(assignToMeLoadingSelector);
  const inboxStats = useSelector(inboxStatsSelector);

  const normalizeReplyPlatformTypeOptions = useCallback((): IObjectOption[] => {
    return getDefaultReplyPlatformTypeOptions().map((option: IObjectOption) => {
      const count =
        inboxStats?.stats[
          RepliedMapEnum[option.id as keyof typeof RepliedMapEnum] as keyof IInboxStats["stats"]
        ] || 0;
      return {
        ...option,
        label: `${option.label} (${count})`,
      };
    });
    // eslint-disable-next-line
  }, [getDefaultReplyPlatformTypeOptions()]);

  const queueNotEmpty = Number(inboxStats?.stats[statTabValue]) > 0;

  return (
    <div>
      <div data-cy="comments-grid-header" className={s.bbInboxCommentsHeader}>
        <h5 data-cy="comments-title" className={s.bbInboxCommentsHeaderTitle}>
          {!isBatchModeActive && (
            <>
              {statTabParent && (
                <span className={s.bbInboxCommentsHeaderTitlePrefix}>
                  {t(`components:inboxMenu:tabs:${statTabParent}Label`)}
                </span>
              )}{" "}
              {t(`components:inboxMenu:tabs:${statTabValue}Label`)}
            </>
          )}
        </h5>
        <div className={classNames(s.bbInboxCommentsHeaderFilterWrapper, "gap-x-5")}>
          {!isBatchModeActive && statTabValue === "replied" && (
            <SelectStyled
              id="replyPlatformType"
              _size="sm"
              wrapperClassName={s.bbInboxCommentsHeaderReplyPlatform}
              InputVariation={InputWhite}
              value={replyPlatformTypeValue}
              onChange={onReplyPlatformChange}
              options={normalizeReplyPlatformTypeOptions()}
            />
          )}
          {["review", "ai_highlights", "care_highlights"].includes(statTabValue) && (
            <>
              {!isBatchModeActive && queueNotEmpty && (
                <AssignToMeButton
                  loading={assignToMeLoading}
                  loadingIndicatorPosition="center"
                  data-cy="care-inbox__start-shift_button"
                  onActionHandler={closeModal => onClickAssignToMe(statTabValue, closeModal)}
                />
              )}
              {isBatchModeActive && <BatchModeSelectAll />}
              <BatchModeButton />
            </>
          )}
          {!isBatchModeActive && (
            <SelectStyled
              _size="sm"
              value={sortValue}
              onChange={onSortChange}
              InputVariation={InputWhite}
              options={getDefaultSortOptions()}
              wrapperClassName="shadow-sm-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};
