import { createReducer, ActionType } from "typesafe-actions";
import { INotificationsState, INotification } from "./types";
import {
  postNotificationActionSuccess,
  postNotificationActionFailure,
  getNotificationsActionSuccess,
  deleteNotificationActionSuccess,
  putNotificationActionSuccess,
  subscribeNotificationActionSuccess,
  unsubscribeNotificationActionSuccess,
} from "./actions";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  POST_NOTIFICATION,
  POST_NOTIFICATION_SUCCESS,
  POST_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  PUT_NOTIFICATION,
  PUT_NOTIFICATION_SUCCESS,
  PUT_NOTIFICATION_FAILURE,
  SUBSCRIBE_NOTIFICATION,
  SUBSCRIBE_NOTIFICATION_SUCCESS,
  SUBSCRIBE_NOTIFICATION_FAILURE,
  UNSUBSCRIBE_NOTIFICATION,
  UNSUBSCRIBE_NOTIFICATION_SUCCESS,
  UNSUBSCRIBE_NOTIFICATION_FAILURE,
  RESET_NOTIFICATION,
} from "./actionTypes";

const initialState = {
  notifications: [] as INotification[],
  isOverWrite: false,
  fetchingPostNotification: false,
  fetchedPostNotification: false,
  fetchedPostNotificationFail: false,
  fetchingGetNotifications: false,
  fetchedGetNotifications: false,
  fetchedGetNotificationsFail: false,
  fetchingDeleteNotification: false,
  fetchedDeleteNotification: false,
  fetchedDeleteNotificationFail: false,
  fetchingPutNotification: false,
  fetchedPutNotification: false,
  fetchedPutNotificationFail: false,
  fetchingSubscribeNotification: false,
  fetchedSubscribeNotification: false,
  fetchedSubscribeNotificationFail: false,
  fetchingUnsubscribeNotification: false,
  fetchedUnsubscribeNotification: false,
  fetchedUnsubscribeNotificationFail: false,
};

export const notificationsReducer = createReducer<INotificationsState>(initialState, {
  //GET NOTIFICATIONS
  [GET_NOTIFICATIONS]: (state: INotificationsState) => ({
    ...state,
    fetchingGetNotifications: true,
    fetchedGetNotifications: false,
    fetchedGetNotificationsFail: false,
    isOverWrite: false,
  }),
  [GET_NOTIFICATIONS_SUCCESS]: (
    state: INotificationsState,
    action: ActionType<typeof getNotificationsActionSuccess>,
  ) => ({
    ...state,
    notifications: action.payload,
    fetchingGetNotifications: false,
    fetchedGetNotifications: true,
    fetchedGetNotificationsFail: false,
  }),
  [GET_NOTIFICATIONS_FAILURE]: (state: INotificationsState) => ({
    ...state,
    fetchingGetNotifications: false,
    fetchedGetNotifications: false,
    fetchedGetNotificationsFail: true,
  }),
  //POST NOTIFICATION
  [POST_NOTIFICATION]: (state: INotificationsState) => ({
    ...state,
    fetchingPostNotification: true,
    fetchedPostNotification: false,
    fetchedPostNotificationFail: false,
    isOverWrite: false,
  }),
  [POST_NOTIFICATION_SUCCESS]: (
    state: INotificationsState,
    action: ActionType<typeof postNotificationActionSuccess>,
  ) => {
    const newNotificationList = [...state.notifications];

    if (action.meta.overwrite) {
      const nIndex = newNotificationList.findIndex(n => n.id === action.payload.id);
      if (nIndex !== -1) {
        newNotificationList[nIndex] = action.payload;
      }
    } else {
      newNotificationList.push(action.payload);
    }

    return {
      ...state,
      notifications: newNotificationList,
      fetchingPostNotification: false,
      fetchedPostNotification: true,
      fetchedPostNotificationFail: false,
      isOverWrite: false,
    };
  },
  [POST_NOTIFICATION_FAILURE]: (
    state: INotificationsState,
    action: ActionType<typeof postNotificationActionFailure>,
  ) => ({
    ...state,
    isOverWrite: action.payload.status === 409,
    fetchingPostNotification: false,
    fetchedPostNotification: false,
    fetchedPostNotificationFail: true,
  }),
  //DELETE NOTIFICATION
  [DELETE_NOTIFICATION]: (state: INotificationsState) => ({
    ...state,
    fetchingDeleteNotification: true,
    fetchedDeleteNotification: false,
    fetchedDeleteNotificationFail: false,
    isOverWrite: false,
  }),
  [DELETE_NOTIFICATION_SUCCESS]: (
    state: INotificationsState,
    action: ActionType<typeof deleteNotificationActionSuccess>,
  ) => {
    let deleteNotificationList = [...(state.notifications as INotification[])];

    deleteNotificationList = deleteNotificationList
      .filter((notification: INotification) => {
        return notification.id !== action.payload;
      })
      .map((notification: INotification, index: number) => {
        return {
          ...notification,
          key: index,
        };
      });

    return {
      ...state,
      notifications: deleteNotificationList,
      fetchingDeleteNotification: false,
      fetchedDeleteNotification: true,
      fetchedDeleteNotificationFail: false,
    };
  },
  [DELETE_NOTIFICATION_FAILURE]: (state: INotificationsState) => ({
    ...state,
    fetchingDeleteNotification: false,
    fetchedDeleteNotification: false,
    fetchedDeleteNotificationFail: true,
  }),
  //PUT NOTIFICATION
  [PUT_NOTIFICATION]: (state: INotificationsState) => ({
    ...state,
    fetchingPutNotification: true,
    fetchedPutNotification: false,
    fetchedPutNotificationFail: false,
    isOverWrite: false,
  }),
  [PUT_NOTIFICATION_SUCCESS]: (
    state: INotificationsState,
    action: ActionType<typeof putNotificationActionSuccess>,
  ) => {
    let putNotificationList = [...(state.notifications as INotification[])];

    putNotificationList = putNotificationList.map((notification: INotification, index: number) => {
      if (notification.id === action.payload.id) {
        return {
          ...action.payload,
          key: index,
        };
      }
      return {
        ...notification,
        key: index,
      };
    });

    return {
      ...state,
      notifications: putNotificationList,
      fetchingPutNotification: false,
      fetchedPutNotification: true,
      fetchedPutNotificationFail: false,
    };
  },
  [PUT_NOTIFICATION_FAILURE]: (state: INotificationsState) => ({
    ...state,
    fetchingPutNotification: false,
    fetchedPutNotification: false,
    fetchedPutNotificationFail: true,
  }),
  [RESET_NOTIFICATION]: (state: INotificationsState) => ({
    ...state,
    isOverWrite: false,
    fetchingPostNotification: false,
    fetchedPostNotification: false,
    fetchedPostNotificationFail: false,
    fetchingGetNotifications: false,
    fetchedGetNotifications: false,
    fetchedGetNotificationsFail: false,
    fetchingDeleteNotification: false,
    fetchedDeleteNotification: false,
    fetchedDeleteNotificationFail: false,
    fetchingPutNotification: false,
    fetchedPutNotification: false,
    fetchedPutNotificationFail: false,
    fetchingSubscribeNotification: false,
    fetchedSubscribeNotification: false,
    fetchedSubscribeNotificationFail: false,
    fetchingUnsubscribeNotification: false,
    fetchedUnsubscribeNotification: false,
    fetchedUnsubscribeNotificationFail: false,
  }),

  //SUBSCRIBE NOTIFICATION
  [SUBSCRIBE_NOTIFICATION]: (state: INotificationsState) => ({
    ...state,
    fetchingSubscribeNotification: true,
    fetchedSubscribeNotification: false,
    fetchedSubscribeNotificationFail: false,
    isOverWrite: false,
  }),
  [SUBSCRIBE_NOTIFICATION_SUCCESS]: (
    state: INotificationsState,
    action: ActionType<typeof subscribeNotificationActionSuccess>,
  ) => {
    let subscribeNotificationList = [...(state.notifications as INotification[])];

    subscribeNotificationList = subscribeNotificationList.map((notification: INotification) => {
      if (notification.id === action.payload.id) {
        const users = notification.users;
        users?.push(action.payload.user);

        return {
          ...notification,
          users: users,
        };
      }
      return notification;
    });

    return {
      ...state,
      notifications: subscribeNotificationList,
      fetchingSubscribeNotification: false,
      fetchedSubscribeNotification: true,
      fetchedSubscribeNotificationFail: false,
    };
  },
  [SUBSCRIBE_NOTIFICATION_FAILURE]: (state: INotificationsState) => ({
    ...state,
    fetchingSubscribeNotification: false,
    fetchedSubscribeNotification: false,
    fetchedSubscribeNotificationFail: true,
  }),
  //UNSUBSCRIBE NOTIFICATION
  [UNSUBSCRIBE_NOTIFICATION]: (state: INotificationsState) => ({
    ...state,
    fetchingUnsubscribeNotification: true,
    fetchedUnsubscribeNotification: false,
    fetchedUnsubscribeNotificationFail: false,
    isOverWrite: false,
  }),
  [UNSUBSCRIBE_NOTIFICATION_SUCCESS]: (
    state: INotificationsState,
    action: ActionType<typeof unsubscribeNotificationActionSuccess>,
  ) => {
    let unsubscribeNotificationList = [...(state.notifications as INotification[])];

    unsubscribeNotificationList = unsubscribeNotificationList.map((notification: INotification) => {
      if (notification.id === action.payload.id) {
        return {
          ...notification,
          users: notification?.users?.filter(user => user.id !== action.payload.userId),
        };
      }
      return notification;
    });

    return {
      ...state,
      notifications: unsubscribeNotificationList,
      fetchingUnsubscribeNotification: false,
      fetchedUnsubscribeNotification: true,
      fetchedUnsubscribeNotificationFail: false,
    };
  },
  [UNSUBSCRIBE_NOTIFICATION_FAILURE]: (state: INotificationsState) => ({
    ...state,
    fetchingUnsubscribeNotification: false,
    fetchedUnsubscribeNotification: false,
    fetchedUnsubscribeNotificationFail: true,
  }),
});
