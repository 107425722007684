import { createReducer, ActionType } from "typesafe-actions";
import { getDocumentsSuccess, getDocumentsUrlSuccess } from "./actions";
import {
  GET_DOCUMENT,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_URL,
  GET_DOCUMENT_URL_FAILURE,
  GET_DOCUMENT_URL_SUCCESS,
} from "./actionTypes";
import { IDocumentState } from "./types";

const initialState = {
  items: [],
  gettingDocuments: false,
  getDocumentFail: false,
  reportFile: "",
};

export const documentReducer = createReducer<IDocumentState>(initialState, {
  [GET_DOCUMENT]: (state: IDocumentState) => ({
    ...state,
    gettingDocuments: true,
    getDocumentFail: false,
  }),
  [GET_DOCUMENT_SUCCESS]: (
    state: IDocumentState,
    action: ActionType<typeof getDocumentsSuccess>,
  ) => ({
    ...state,
    items: action.payload.length ? action.payload : [],
    gettingDocuments: false,
    getDocumentFail: false,
  }),
  [GET_DOCUMENT_FAILURE]: (state: IDocumentState) => ({
    ...state,
    gettingDocuments: false,
    getDocumentFail: true,
  }),
  [GET_DOCUMENT_URL]: (state: IDocumentState) => ({
    ...state,
  }),
  [GET_DOCUMENT_URL_SUCCESS]: (
    state: IDocumentState,
    action: ActionType<typeof getDocumentsUrlSuccess>,
  ) => {
    return {
      ...state,
      reportFile: action.payload.url,
    };
  },
  [GET_DOCUMENT_URL_FAILURE]: (state: IDocumentState) => ({
    ...state,
  }),
});
