import { FormProps } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useState, useCallback } from "react";

import {
  Input,
  debounce,
  IObjectOption,
  useSearchInput,
  SelectStyledMulti,
  DropdownMenuPlacementType,
  handleMultiselectState,
} from "@bbdevcrew/bb_ui_kit_fe";
import { FormInstance } from "antd";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./DynamicSelectStyledMultiFormWrapper.module.less";

import {
  AllowedAutocompleteFieldType,
  IGetAutocompleteOptionsPayload,
} from "@store/autocomplete/types";
import { mergeDedupe } from "../helper";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

interface IDynamicSelectStyledMultiFormWrapperProps extends FormProps {
  placeholder?: string;
  InputVariation?: typeof Input;
  dropdownPlacement?: DropdownMenuPlacementType;
  dropdownMatchSelectWidth?: boolean;
  customprops?: { form?: FormInstance; name: string };
  sanitizeFieldName: string;
  additionalLabelFields?: string[];
  fetchFilters?: string;
  showDefaultPlaceholder?: boolean;
}

const DynamicSelectStyledMultiFormWrapper: FC<IDynamicSelectStyledMultiFormWrapperProps> = ({
  placeholder,
  customprops,
  dropdownPlacement,
  dropdownMatchSelectWidth,
  sanitizeFieldName,
  showDefaultPlaceholder = true,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filterValues, setFilterValues] = useState<string[]>([]);
  const [options, setOptions] = useState<IObjectOption[]>([]);

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  const { SearchInput, isSearched, searchTerm, setSearchTerm } = useSearchInput(
    t("generic:search"),
  );

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const fetchValues = (_sanitizeFieldName: string, term: string) => {
    getAutocompleteOptions({
      field: _sanitizeFieldName as AllowedAutocompleteFieldType,
      query: term,
    });
  };

  useEffect(() => {
    setFilterValues(customprops?.form?.getFieldValue(customprops?.name) || []);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSearched) fetchValues(sanitizeFieldName, searchTerm);
    // eslint-disable-next-line
  }, [isSearched, searchTerm]);

  useEffect(() => {
    const fieldName = sanitizeFieldName as keyof typeof autocompleteOptions;
    const _options = autocompleteOptions[fieldName].map(item => ({
      ...item,
      additionalLabel: props?.additionalLabelFields?.map(field => item[field as keyof typeof item]),
    }));
    const searchedOptions = searchTerm
      .split(",")
      .map(term => term.trim())
      .filter(trm => !!trm);

    setOptions(_options as IObjectOption[]);

    if (searchTerm.includes(",") && _options.length <= searchedOptions.length) {
      const updatedFilterValues = mergeDedupe(
        filterValues,
        _options.map(o => o.id),
      );

      setFilterValues(updatedFilterValues);

      if (customprops?.form) {
        return customprops?.form.setFieldsValue({
          [customprops?.name]: updatedFilterValues,
        });
      }
    }
    // eslint-disable-next-line
  }, [autocompleteOptions[sanitizeFieldName as keyof typeof autocompleteOptions]]);

  const onInternalChange = (incomingValue: string) => {
    const newState = handleMultiselectState(options, filterValues, incomingValue);

    setFilterValues(newState);

    if (customprops?.form) {
      return customprops?.form.setFieldsValue({
        [customprops?.name]: newState || undefined,
      });
    }
  };

  const onClearValues = () => {
    setFilterValues([]);
    if (customprops?.form) {
      return customprops?.form.setFieldsValue({
        [customprops?.name]: undefined,
      });
    }
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    const handleOnClick = () => {
      if (tooltipProps.onClick) {
        tooltipProps.onClick();
      }
      fetchValues(sanitizeFieldName, searchTerm);
    };

    const triggerProps = {
      ...tooltipProps,
      _size: "sm",
      onClick: handleOnClick,
      onClear: onClearValues,
      showDefaultPlaceholder,
    } as IClearableTriggerProps;

    if (filterValues.length === 0) {
      return ClearableTrigger(triggerProps);
    }

    const tooltipElement = filterValues.map(value => (
      <span key={value} className={s.bbDisplayBlock}>
        {value}
      </span>
    ));

    return ClearableTrigger({ tooltip: tooltipElement, ...triggerProps });
  };

  return (
    <div data-cy={`app-filter-${customprops?.name}`}>
      <SelectStyledMulti
        id={props.id}
        options={options}
        value={filterValues}
        placeholder={placeholder}
        highlightTerm={searchTerm}
        onChange={onInternalChange}
        DropdownHeader={SearchInput}
        excludePlaceholder={t("generic:exclude")}
        excludedPlaceholder={t("generic:excluded")}
        TriggerVariation={TriggerWithTooltip}
        dropdownPlacement={dropdownPlacement}
        onDropdownClose={() => setSearchTerm("")}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        dropdownMenuClassName={s.bbDropdownMenuClassName}
      />
    </div>
  );
};

export default DynamicSelectStyledMultiFormWrapper;
