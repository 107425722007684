import { createReducer } from "typesafe-actions";

import { ICompareState } from "./types";
import { GET_COMPARE } from "./actionTypes";

const initialState = {
  fetchingCompare: false,
  fetchedCompare: false,
  fetchedCompareFail: false,
};

export const compareReducer = createReducer<ICompareState>(initialState, {
  [GET_COMPARE]: (state: ICompareState) => ({
    ...state,
    fetchingCompare: true,
    fetchedCompare: false,
    fetchedCompareFail: false,
  }),
});
