import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import ToReviewEmptyState from "./ToReviewEmptyState";
import AssignedToMeEmptyState from "./AssignedToMeEmptyState";

import { IInboxStats } from "@store/inboxStats/types";
import { AssignToMeClickHandlerType } from "../inboxCommentsHeader/types";
import { inboxStatsSelector } from "@store/inboxStats/selectors";

interface IEmptyStateProps {
  tab: keyof IInboxStats;
  onAssignClick: AssignToMeClickHandlerType;
}

const EmptyState: FC<IEmptyStateProps> = ({ tab, onAssignClick }) => {
  const { t } = useTranslation();

  const inboxStats = useSelector(inboxStatsSelector);

  if (tab === "review" && inboxStats?.stats["review"] === 0) return <ToReviewEmptyState />;

  if (tab === "assigned_to_me" && inboxStats?.stats["assigned_to_me"] === 0) {
    if (inboxStats?.stats["review"] === 0) return <AssignedToMeEmptyState />;
    else return <AssignedToMeEmptyState showAssignBtn onAssignClick={onAssignClick} />;
  }

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>{t("components:comments:tableEmpty")}</span>}
    />
  );
};

export default EmptyState;
