import React from "react";

import ErrorPosting from "./ErrorPosting";

import s from "./PublishingPost.module.less";

import { IPublishingPost } from "@store/publishings/types";
import { getPostMediaTypeIcon } from "../../../_common/PostPreviewTooltip/helpers";

interface IPublishingPostImagePreviewProps {
  post: IPublishingPost;
}

export default function PublishingPostImagePreview({ post }: IPublishingPostImagePreviewProps) {
  return (
    <>
      <div className={s.bbPostPictureWrapper}>
        <img src={post.picture} className={s.bbPostPicture} />
        <div className={s.bbGradientOverlay}></div>
        <ErrorPosting post={post} />
      </div>
      {!!post.message && <div className={s.bbPostCaptionCropped}>{post.message}</div>}
      <div className={s.bbPostMediaType}>{getPostMediaTypeIcon(post)}</div>
    </>
  );
}
