import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";

import { RatingReviewStars } from "./RatingReviewStars";
import { SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import s from "./RatingReview.module.less";

import { sanitizeUnderScoreAndUppercase } from "../helper";
import { handleMultiselectState } from "@bbdevcrew/bb_ui_kit_fe";
import { IRatingReviewFiltersProps } from "./RatingReview.types";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

import { ThumbsUpIcon } from "@assets/index";

const cx = cn.bind(s);

const RECOMMEND_OPTIONS = [
  { id: "POSITIVE", iconClass: s.bbThumbIcon },
  {
    id: "NEGATIVE",
    iconClass: cx(s.bbThumbIcon, s.bbNoRecommendIcon),
  },
];

export const RatingReview: FC<IRatingReviewFiltersProps> = ({ customprops, options = [] }) => {
  const { t } = useTranslation();

  const [filterValues, setFilterValues] = useState<string[]>([]);

  const onInternalChange = (ratingValue: string) => {
    const newState = handleMultiselectState(options, filterValues, ratingValue);

    setFilterValues(newState);

    if (customprops?.form) {
      return customprops?.form.setFieldsValue({
        review_types: newState || undefined,
      });
    }
  };

  useEffect(() => {
    setFilterValues(customprops?.form?.getFieldValue("review_types"));
  }, [customprops?.form]);

  const generateOptions = () => {
    return options.map(option => {
      const isFacebookOption = option.id === "NEGATIVE" || option.id === "POSITIVE";
      return {
        id: option.id,
        label: isFacebookOption ? option.label : "",
        leftLabel: (
          <div className={s.bbOptionLabel}>
            {isFacebookOption ? (
              <div className={RECOMMEND_OPTIONS.find(item => item.id === option.id)?.iconClass}>
                <ThumbsUpIcon />
              </div>
            ) : (
              <RatingReviewStars rating={parseInt(option.label)} />
            )}
          </div>
        ),
      };
    });
  };

  const _onClear = () => {
    customprops?.form?.setFieldsValue({ review_types: [] });
    setFilterValues([]);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!filterValues?.length) {
      return ClearableTrigger(tooltipProps);
    }

    const tooltipElement = filterValues.map(value => (
      <span key={value} style={{ display: "block" }}>
        {sanitizeUnderScoreAndUppercase(value)}
      </span>
    ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <SelectStyledMulti
      _size="sm"
      id="review_types"
      className={s.bbRatingReviewItem}
      value={filterValues}
      options={generateOptions()}
      TriggerVariation={TriggerWithTooltip}
      onChange={onInternalChange}
      placeholder={t("generic:all")}
      excludePlaceholder={t("generic:exclude")}
      excludedPlaceholder={t("generic:excluded")}
      dropdownPlacement="bottomRight"
    />
  );
};
