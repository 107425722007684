import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { SavedFilterActions } from "./types";
import { RootState } from "../";
import {
  deleteSavedFilterFailureAction,
  deleteSavedFilterSuccessAction,
  getPredefinedFiltersFailureAction,
  getPredefinedFiltersSuccessAction,
  postSavedFilterFailureAction,
  postSavedFilterSuccessAction,
} from "./actions";
import { GET_PREDEFINED_FILTERS, POST_SAVED_FILTER, DELETE_SAVED_FILTER } from "./actionTypes";

import { predefinedFilters, savedFilters } from "@utils/paths";
import { IFilterItem, IPredefinedFilter } from "@components/_common/AppFilter/AppFilters.type";
import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";

// GET SAVED FILTERS
export const getSavedFilter = (
  action$: Observable<SavedFilterActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_PREDEFINED_FILTERS)),
    switchMap(() => {
      return ajax
        .get<IPredefinedFilter[]>(
          predefinedFilters,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getPredefinedFiltersSuccessAction(data)),
          catchError(e => handleError(e, getPredefinedFiltersFailureAction)),
        );
    }),
  );

// POST SAVED FILTER
export const postSavedFilter = (
  action$: Observable<SavedFilterActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_SAVED_FILTER)),
    switchMap(a => {
      return ajax
        .post<IFilterItem>(
          savedFilters,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => postSavedFilterSuccessAction(data, a.payload.overwrite ? true : false)),
          catchError(e => of(postSavedFilterFailureAction(e))),
        );
    }),
  );

export const deleteSavedFilter = (
  action$: Observable<SavedFilterActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_SAVED_FILTER)),
    switchMap(a => {
      return ajax
        .delete(
          `${savedFilters}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteSavedFilterSuccessAction(a.payload)),
          catchError(e => handleError(e, deleteSavedFilterFailureAction)),
        );
    }),
  );
