import { FormProps } from "rc-field-form";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { Grid, FormInstance } from "antd";
import { Input, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import { InputSize, IObjectOption, DropdownMenuPlacementType } from "@bbdevcrew/bb_ui_kit_fe";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

interface ISelectStyledWrapperProps extends FormProps {
  placeholder?: string;
  options: IObjectOption[];
  _size?: keyof typeof InputSize;
  InputVariation?: typeof Input;
  dropdownMenuClassName?: string;
  dropdownPlacement?: DropdownMenuPlacementType;
  customprops?: { form?: FormInstance; name: string };
  fieldName: string;
}

export function SelectStyledWrapper({
  _size,
  options,
  customprops,
  InputVariation = Input,
  dropdownPlacement,
  dropdownMenuClassName,
  ...props
}: ISelectStyledWrapperProps) {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const [filterValues, setFilterValues] = useState<string>();

  const onInternalChange = (clickedValue: string) => {
    if (clickedValue === filterValues) {
      setFilterValues(undefined);
      customprops?.form?.setFieldsValue({
        [customprops.name]: undefined,
      });
      return;
    }
    setFilterValues(clickedValue);
    customprops?.form?.setFieldsValue({
      [customprops.name]: clickedValue,
    });
  };

  useEffect(() => {
    setFilterValues(customprops?.form?.getFieldValue(customprops.name) || []);
    // eslint-disable-next-line
  }, []);

  const _onClear = () => {
    setFilterValues(undefined);
    customprops?.form?.setFieldsValue({
      [customprops.name]: undefined,
    });
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!filterValues?.length) {
      return ClearableTrigger(tooltipProps);
    }
    const tooltipElement = <span style={{ display: "block" }}>{filterValues}</span>;

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <div data-cy={`app-filter-${customprops?.name}`}>
      <SelectStyledMulti
        _size={_size}
        id={props.id}
        options={options}
        value={filterValues?.length ? [filterValues] : []}
        onChange={onInternalChange}
        InputVariation={InputVariation}
        TriggerVariation={TriggerWithTooltip}
        dropdownPlacement={dropdownPlacement}
        dropdownMatchSelectWidth={!screens.md}
        placeholder={t("generic:all")}
        excludePlaceholder={t("generic:exclude")}
        excludedPlaceholder={t("generic:excluded")}
        dropdownMenuClassName={dropdownMenuClassName}
      />
    </div>
  );
}
