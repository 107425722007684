import { createReducer, ActionType } from "typesafe-actions";
import { IStatsState, IDataStatsProps } from "./types";
import { postStatsActionSuccess } from "./actions";
import {
  POST_STATS,
  POST_STATS_SUCCESS,
  POST_STATS_FAILURE,
  RESET_STATS_DATA,
} from "./actionTypes";

const initialState = {
  data: {} as IDataStatsProps,
  isOverWrite: false,
  fetchingStats: false,
  fetchedStats: false,
  fetchedStatsFail: false,
};

export const statsReducer = createReducer<IStatsState>(initialState, {
  [POST_STATS]: (state: IStatsState) => ({
    ...state,
    fetchingStats: true,
    fetchedStats: false,
    fetchedStatsFail: false,
  }),
  [POST_STATS_SUCCESS]: (
    state: IStatsState,
    action: ActionType<typeof postStatsActionSuccess>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingStats: false,
    fetchedStats: true,
    fetchedStatsFail: false,
  }),
  [POST_STATS_FAILURE]: (state: IStatsState) => ({
    ...state,
    fetchingStats: false,
    fetchedStats: false,
    fetchedStatsFail: true,
  }),
  [RESET_STATS_DATA]: (state: IStatsState) => ({
    ...state,
    fetchingStats: false,
    fetchedStats: false,
    fetchedStatsFail: false,
  }),
});
