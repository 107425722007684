import { action } from "typesafe-actions";
import {
  GET_PREDEFINED_FILTERS,
  GET_PREDEFINED_FILTERS_SUCCESS,
  GET_PREDEFINED_FILTERS_FAILURE,
  POST_SAVED_FILTER,
  POST_SAVED_FILTER_SUCCESS,
  POST_SAVED_FILTER_FAILURE,
  DELETE_SAVED_FILTER,
  DELETE_SAVED_FILTER_SUCCESS,
  DELETE_SAVED_FILTER_FAILURE,
  RESET_SAVED_FILTERS_DATA,
} from "./actionTypes";
import { IPostSavedFilterBodyProps } from "./types";
import { IFilterItem, IPredefinedFilter } from "@components/_common/AppFilter/AppFilters.type";

// GET
export const getPredefinedFiltersAction = () => action(GET_PREDEFINED_FILTERS);
export const getPredefinedFiltersSuccessAction = (predefinedFilters: IPredefinedFilter[]) =>
  action(GET_PREDEFINED_FILTERS_SUCCESS, predefinedFilters);
export const getPredefinedFiltersFailureAction = () => action(GET_PREDEFINED_FILTERS_FAILURE);

// POST
export const postSavedFilterAction = (props: IPostSavedFilterBodyProps) =>
  action(POST_SAVED_FILTER, props);
export const postSavedFilterSuccessAction = (savedFilter: IFilterItem, overwrite: boolean) =>
  action(POST_SAVED_FILTER_SUCCESS, savedFilter, { overwrite });
export const postSavedFilterFailureAction = (data: {
  status: number;
  response: { has_alerts: boolean };
}) => action(POST_SAVED_FILTER_FAILURE, data);

// DELETE
export const deleteSavedFilterAction = (id: string) => action(DELETE_SAVED_FILTER, id);
export const deleteSavedFilterSuccessAction = (id: string) =>
  action(DELETE_SAVED_FILTER_SUCCESS, id);
export const deleteSavedFilterFailureAction = () => action(DELETE_SAVED_FILTER_FAILURE);

export const resetSavedFiltersDataAction = () => action(RESET_SAVED_FILTERS_DATA);
