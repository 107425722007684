import { ajax } from "rxjs/ajax";
import { NotificationActions } from "./types";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "..";
import {
  deleteNotificationActionFailure,
  deleteNotificationActionSuccess,
  getNotificationsActionFailure,
  getNotificationsActionSuccess,
  postNotificationActionFailure,
  postNotificationActionSuccess,
  putNotificationActionFailure,
  putNotificationActionSuccess,
  subscribeNotificationActionFailure,
  subscribeNotificationActionSuccess,
  unsubscribeNotificationActionFailure,
  unsubscribeNotificationActionSuccess,
} from "./actions";
import {
  GET_NOTIFICATIONS,
  POST_NOTIFICATION,
  DELETE_NOTIFICATION,
  PUT_NOTIFICATION,
  SUBSCRIBE_NOTIFICATION,
  UNSUBSCRIBE_NOTIFICATION,
} from "./actionTypes";
import { INotification } from "./types";

import { notifications } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

// GET NOTIFICATIONS
export const getNotificationsEpic = (
  action$: Observable<NotificationActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_NOTIFICATIONS)),
    switchMap(() => {
      return ajax
        .get<{ items: INotification[] }>(
          notifications,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data =>
            getNotificationsActionSuccess(
              data.items.map((item, key) => ({
                key,
                ...item,
              })),
            ),
          ),
          catchError(e => handleError(e, getNotificationsActionFailure)),
        );
    }),
  );

// POST NOTIFICATION
export const postNotificationEpic = (
  action$: Observable<NotificationActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_NOTIFICATION)),
    switchMap(a => {
      return ajax
        .post<INotification>(
          notifications,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => {
            return [postNotificationActionSuccess(data, a.payload.overwrite ? true : false)];
          }),
          catchError(e => handleError(e, postNotificationActionFailure)),
        );
    }),
  );

// DELETE NOTIFICATION
export const deleteNotificationEpic = (
  action$: Observable<NotificationActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_NOTIFICATION)),
    switchMap(a => {
      return ajax
        .delete(
          `${notifications}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteNotificationActionSuccess(a.payload)),
          catchError(e => handleError(e, deleteNotificationActionFailure)),
        );
    }),
  );

// PUT NOTIFICATION
export const putNotificationEpic = (
  action$: Observable<NotificationActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PUT_NOTIFICATION)),
    switchMap(a => {
      return ajax
        .put<INotification>(
          `${notifications}/${a.payload.id}`,
          a.payload.data,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(res => putNotificationActionSuccess(res)),
          catchError(e => handleError(e, putNotificationActionFailure)),
        );
    }),
  );

// SUBSCRIBE NOTIFICATION
export const subscribeNotificationEpic = (
  action$: Observable<NotificationActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(SUBSCRIBE_NOTIFICATION)),
    switchMap(a => {
      return ajax
        .post(
          `${notifications}/${a.payload.id}/subscription`,
          {},
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => subscribeNotificationActionSuccess(a.payload.id, a.payload.user)),
          catchError(e => handleError(e, subscribeNotificationActionFailure)),
        );
    }),
  );

// UNSUBSCRIBE NOTIFICATION
export const unsubscribeNotificationEpic = (
  action$: Observable<NotificationActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(UNSUBSCRIBE_NOTIFICATION)),
    switchMap(a => {
      return ajax
        .delete(
          `${notifications}/${a.payload.id}/subscription`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => unsubscribeNotificationActionSuccess(a.payload.id, a.payload.userId)),
          catchError(e => handleError(e, unsubscribeNotificationActionFailure)),
        );
    }),
  );
