import { DefaultRootState } from "react-redux";
import { RootState } from "..";

export const fromPublishings = (state: RootState) => state.publishings;

export const getPublishingsListSelector = (state: RootState) =>
  fromPublishings(state).publishingsList;
export const getCalendarHolidaysSelector = (state: RootState) => fromPublishings(state).holidays;

export const getPublishingsListPendingSelector = (state: RootState) =>
  fromPublishings(state).fetchingPublishingList;
export const getPublishingsListSuccessfulSelector = (state: RootState) =>
  fromPublishings(state).fetchedPublishingList;
export const getPublishingsListFailureSelector = (state: RootState) =>
  fromPublishings(state).fetchedPublishingListFail;

export const creatingPostPublishingSelector = (state: RootState) =>
  fromPublishings(state).creatingPostPublishing;
export const createdPostPublishingSelector = (state: RootState) =>
  fromPublishings(state).createdPostPublishing;
export const createdPostPublishingDraftSelector = (state: RootState) =>
  fromPublishings(state).createdDraftPostPublishing;
export const scheduledPostPublishingSelector = (state: RootState) =>
  fromPublishings(state).scheduledPostPublishing;
export const creatingPostPublishingFailedSelector = (state: RootState) =>
  fromPublishings(state).creatingPostPublishingFailed;

export const updatingPostPublishingSelector = (state: RootState) =>
  fromPublishings(state).updatingPostPublishing;
export const updatedPostPublishingSelector = (state: RootState) =>
  fromPublishings(state).updatedPostPublishing;
export const updatingPostPublishingSelectorFailure = (state: RootState) =>
  fromPublishings(state).updatingPostPublishingFailed;
export const scheduledUpdatedPostSelector = (state: RootState) =>
  fromPublishings(state).scheduledEditedPostPublishing;
export const updatedPostPublishingDraftSelector = (state: RootState) =>
  fromPublishings(state).editedPostPublishingDraft;

export const postPublishingFailedMessageSelector = (state: RootState) =>
  fromPublishings(state).postPublishingFailedMessage;

export const deletingPostFailedMessageSelector = (state: RootState) =>
  fromPublishings(state).deletePostFailedMessage;
export const deletingPostSelectorSuccessful = (state: RootState) =>
  fromPublishings(state).deletePostFullfilled;

export const urlPreviewsSelector = (state: RootState) => fromPublishings(state).urlPreviews;
export const isUrlPreviewRemovedSelector = (state: RootState) =>
  fromPublishings(state).isUrlPreviewRemoved;
export const fetchingUrlPreviewSelector = (state: RootState) =>
  fromPublishings(state).fetchingUrlPreviews;
export const fetchingUrlPreviewsSuccessfulSelector = (state: RootState) =>
  fromPublishings(state).fetchedUrlPreviewsSuccess;
export const fetchingUrlPreviewsFailureSelector = (state: RootState) =>
  fromPublishings(state).fetchedUrlPreviewsFail;

export const TTCreatorInfoSelector = (state: DefaultRootState, assetId?: string) =>
  fromPublishings(state as RootState).ttCreatorInfo.find(item => item.asset_id === assetId);
export const TTCreatorInfosSelector = (state: RootState) => fromPublishings(state).ttCreatorInfo;
export const fetchingTTCreatorInfosSelector = (state: RootState) =>
  fromPublishings(state).fetchingTTCreatorInfoAssets;
export const fetchingTTCreatorInfoSelector = (state: DefaultRootState, assetId?: string) =>
  fromPublishings(state as RootState).fetchingTTCreatorInfoAssets.find(item => item === assetId);
export const fetchedTTCreatorInfosSuccessfulSelector = (state: RootState) =>
  fromPublishings(state).fetchedTTCreatorInfoSuccess;
export const fetchedTTCreatorInfoFailureSelector = (state: RootState) =>
  fromPublishings(state).fetchedTTCreatorInfoFail;
export const fetchedTTCreatorInfoErrorDataSelector = (state: RootState) =>
  fromPublishings(state).TTCreatorInfoErrorData;

export const fetchingInstagramAssetInfoSelector = (state: RootState) =>
  fromPublishings(state).fetchingInstagramAssetInfo;
export const fetchedInstagramAssetInfoSuccessSelector = (state: RootState) =>
  fromPublishings(state).fetchedInstagramAssetInfoSuccess;
export const fetchedInstagramAssetInfoFailureSelector = (state: RootState) =>
  fromPublishings(state).fetchedInstagramAssetInfoFail;
export const instagramAssetInfoSelector = (state: RootState) =>
  fromPublishings(state).instagramAssetInfo;

export const fetchingInstagramProductsSelector = (state: RootState) =>
  fromPublishings(state).fetchingInstagramProducts;
export const fetchedInstagramProductsSuccessSelector = (state: RootState) =>
  fromPublishings(state).fetchedInstagramProductsSuccess;
export const fetchedInstagramProductsFailureSelector = (state: RootState) =>
  fromPublishings(state).fetchedInstagramProductsFail;
export const instagramProductsSelector = (state: RootState) =>
  fromPublishings(state).instagramProducts;

export const fetchingYouTubeCategoriesSelector = (state: RootState) =>
  fromPublishings(state).fetchingYouTubeCategories;
export const fetchedYouTubeCategoriesSuccessSelector = (state: RootState) =>
  fromPublishings(state).fetchedYouTubeCategoriesSuccess;
export const fetchedYouTubeCategoriesFailureSelector = (state: RootState) =>
  state.fromPublishings(state).fetchedYouTubeCategoriesFail;
export const youtubeCategoriesSelector = (state: RootState) => state.publishings.youtubeCategories;

export const youTubePlaylistsSelector = (state: RootState) =>
  fromPublishings(state).youtubePlaylists;
export const fetchingYouTubePlaylistsSelector = (state: RootState) =>
  fromPublishings(state).fetchingYouTubePlaylists;
export const fetchedYouTubePlaylistsSuccessSelector = (state: RootState) =>
  fromPublishings(state).fetchedYouTubePlaylistsSuccess;
export const fetchedYouTubePlaylistsFailureSelector = (state: RootState) =>
  fromPublishings(state).fetchedYouTubePlaylistsFail;
