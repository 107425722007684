import React, { FC } from "react";
import { useSelector } from "react-redux";

import AgentPerformanceWidgets from "./AgentPerformanceWidgets";

import { getDashboardSelector, getDashboardSelectorPending } from "@store/dashboard/selectors";
import { meSelector } from "@store/me/selectors";

import { engageConfig, managedAgentsConfig } from "./config";
import { IAgentPerformanceProps } from "./AgentPerformance.type";

const AgentPerformance: FC<IAgentPerformanceProps> = ({ filters }) => {
  const me = useSelector(meSelector);

  const dashboard = useSelector(getDashboardSelector);
  const dashboardFetching = useSelector(getDashboardSelectorPending);

  return (
    <AgentPerformanceWidgets
      loading={dashboardFetching}
      data={dashboard?.explorer_agent_performance}
      config={me?.client?.is_care_managed ? managedAgentsConfig : engageConfig}
      filters={filters}
    />
  );
};

export default AgentPerformance;
