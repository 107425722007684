import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import ReplierNameTrigger from "./ReplierNameTrigger";
import AssetsFilterSingle from "../../AppFilter/filters/Assets/AssetsSingle";

import s from "./ReplierName.module.less";

import { getPlatformIconRound, isYoutubePlatform, platformIconName } from "@utils/platform";
import { IReplierName } from "../Reply.type";
import { IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";

const ReplierName: FC<IReplierName> = ({
  platformId,
  replierInfo,
  replyingAsset,
  setReplyingAsset,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const isYTPlatform = platformId ? isYoutubePlatform(platformId) : false;

  const [allAssets, setAllAssets] = useState<IObjectOption[]>([]);

  useEffect(() => {
    const defaultAssetId = replierInfo?.assets.find(a => a.selected)?.id;
    const currentAsset = allAssets.find(a => a.id === defaultAssetId);

    setReplyingAsset(currentAsset);
    form.setFieldsValue({
      asset_ids: currentAsset?.id,
    });
    // eslint-disable-next-line
  }, [allAssets, replierInfo]);

  const onAssetsInputChange = (assetId: string) => {
    const asset = allAssets.find(a => a?.id === assetId);

    setReplyingAsset(asset);
    form.setFieldsValue({
      asset_ids: asset?.id,
    });
  };

  return (
    <span className={s.bbReplierNameWrapper}>
      <span data-testid="reply-as">{t("components:reply:replyingAs")}</span>
      {platformId === "twitter" && !!replierInfo?.enabled ? (
        <Form autoComplete="off" form={form}>
          <Form.Item
            name="asset_ids"
            key="asset_ids"
            className={s.bbReplierName}
            shouldUpdate={(prevValues: { id: string }, curValues: { id: string }) =>
              prevValues.id !== curValues.id
            }
          >
            <AssetsFilterSingle
              id="asset_ids"
              onChange={onAssetsInputChange}
              additionalLabelFields={["id"]}
              value={replyingAsset?.id}
              fetchFilters="assets_for_replier_asset"
              TriggerVariation={<ReplierNameTrigger isOpen={true} selectedAsset={replyingAsset} />}
              customprops={{
                form,
                name: "asset_ids",
              }}
              showDefaultPlaceholder={false}
              getAutoCompleteOptions={assets => setAllAssets(assets)}
            />
          </Form.Item>
        </Form>
      ) : (
        <>
          <span data-testid="replier-name" className={s.bbReplierNameAssetLabel}>
            {!!replierInfo?.assets?.length && replierInfo?.assets[0]?.label}
          </span>
          <span>
            {!isYTPlatform && (
              <span data-testid="reply-platform" className={s.bbReplyPlatform}>
                {getPlatformIconRound(platformIconName(platformId))}
              </span>
            )}
          </span>
        </>
      )}
    </span>
  );
};

export default ReplierName;
