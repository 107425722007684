import { useSelector } from "react-redux";
import React, { FC } from "react";

import { SelectOld } from "@bbdevcrew/bb_ui_kit_fe";

import { predefinedFiltersSelector } from "@store/savedFilters/selectors";

import { ISingleAssetFilterProps } from "./Assets.type";

const AssetsGroups: FC<ISingleAssetFilterProps> = ({ value, placeholder, customprops }) => {
  const options = useSelector(predefinedFiltersSelector)
    ?.find(item => item.id === "stickyFilters")
    ?.items?.map(filterItem => ({
      value: filterItem.id,
      label: filterItem.name,
    }));

  const onInternalChange = (assetGroupId: string) => {
    if (customprops?.form) {
      return customprops?.form.setFieldsValue({
        asset_group_id: assetGroupId,
      });
    }
  };
  return (
    <SelectOld
      noBorder
      allowClear
      size="middle"
      value={value}
      onChange={onInternalChange}
      options={options || []}
      dropdownMatchSelectWidth={false}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      data-cy="asset-groups-filter-select"
      placeholder={placeholder}
    />
  );
};

export default AssetsGroups;
