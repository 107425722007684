import React from "react";
import moment from "moment";
import { i18n } from "i18next";

import { FormInstance } from "antd";
import { Icon } from "@bbdevcrew/bb_ui_kit_fe";

import { IFilterItem, IFormFilterRequest } from "@components/_common/AppFilter/AppFilters.type";

import { selectedPostTypes } from "./selectedPostTypes";
import { getSentimentOptions } from "./sentimentOptions";
import { DeleteFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { IPlatformType } from "@store/platform/types";
import { IMe, ITwitterType } from "@store/me/types";
import { IFilters } from "@store/filters/types";
import { getI18next } from "../languages/i18next";

const i18nextinstance = getI18next() as i18n;

export const rangeTimeformat = "HH:mm";
export const rangeDateformat = "YYYY-MM-DD";
export const formatLocalDateTime = "YYYY-MM-DDTHH:mm:ssZ";

const GET_VALUES_FIELD_MAPPING = {
  ad_ids: "ads",
  post_ids: "post",
  author_ids: "author",
  asset_ids: "asset",
  languages: "language",
  campaign_ids: "campaign",
  adset_ids: "adset",
  post_tags: "post_tags",
  targetings: "ads_targeting",
};

export const mapFilterItemToSelectProps = (
  filterItems: IFilterItem[],
  onClickSuffixElem?: () => void,
) =>
  filterItems.map((filterItem: IFilterItem) => ({
    ...filterItem,
    value: filterItem.id,
    label: filterItem.name,
    suffixElem: onClickSuffixElem ? <Icon _size="sm" icon={DeleteFilledIcon} /> : undefined,
    onClickSuffixElem,
  }));

export const getPlatforms = (platformTypes: IPlatformType[]) => {
  const platformList = platformTypes.map(({ id, label, description }) => {
    return { label, value: id, tooltip: description };
  });

  return platformList;
};

export const isTTorYTEnabled = (me?: IMe) => {
  return !!me?.client_data?.platform_types?.filter(
    platform => platform.id.includes("tiktok") || platform.id.includes("youtube"),
  ).length;
};

export const normalizeTwitterTypes = (twitterTypes: ITwitterType[]) =>
  twitterTypes.map(ttype => ({ ...ttype, value: ttype.id }));

export const normalizeDateRangeForAPI = (form: FormInstance<IFormFilterRequest>) => {
  const formData: IFormFilterRequest = form.getFieldsValue(true);
  const copyFilterData = { ...formData };

  if (
    copyFilterData.data_range_option === "custom" &&
    copyFilterData.dates &&
    copyFilterData.dates[0]
  ) {
    const [start_date, end_date] = copyFilterData.dates;

    const startDate = moment(start_date.format(`${rangeDateformat} ${rangeTimeformat}`)).format(
      formatLocalDateTime,
    );
    const endDate = moment(end_date.format(`${rangeDateformat} ${rangeTimeformat}`)).format(
      formatLocalDateTime,
    );

    copyFilterData.start_time = startDate;
    copyFilterData.end_time = endDate;
    delete copyFilterData.dates;

    return copyFilterData;
  }

  if (
    (copyFilterData.data_range_option !== "custom" && copyFilterData.dates?.length) ||
    !copyFilterData.dates?.length
  ) {
    delete copyFilterData.dates;
    delete copyFilterData.start_time;
    delete copyFilterData.end_time;
    return copyFilterData;
  }

  return formData;
};

export const getFetchValuesFieldName = (fieldName: string) => {
  let sanitizedFieldName = fieldName;

  sanitizedFieldName =
    GET_VALUES_FIELD_MAPPING[fieldName as keyof typeof GET_VALUES_FIELD_MAPPING] || fieldName;

  return sanitizedFieldName;
};

export const getDefaultFilterValue = (me?: IMe) => {
  const sentimentOptions = getSentimentOptions(i18nextinstance.t).map(o => o.value);

  const filteredOptions = isTTorYTEnabled(me)
    ? sentimentOptions
    : sentimentOptions.filter(option => option !== "not_applicable");

  const defaultFilters: IFilters = {
    data_range_option: "last_30_days",
    keyword_query_in_translation: true,
    post_types: selectedPostTypes(me?.post_types) || [],
    platform_types: me?.client_data.platform_types.map(p => p.id) || [],
    sentiments: filteredOptions,
  };

  return defaultFilters;
};
