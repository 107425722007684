import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SavedReplyCard from "../common/itemCard/ItemCard";

import { IReplyList } from "./helper";

import { deleteSavedRepliesAction } from "@store/replies/actions";
import { getSavedRepliesSelector } from "@store/replies/selectors";

import s from "./CustomReplyList.module.less";

export default function CustomReplyList({ onViewChange, onReplyClick }: IReplyList) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const savedReplies = useSelector(getSavedRepliesSelector);

  const deleteSavedReplies = useCallback(
    id => {
      dispatch(deleteSavedRepliesAction(id));
    },
    [dispatch],
  );

  const onClickDeleteSavedReplies = (id: string) => {
    deleteSavedReplies(id);
  };

  return (
    <div className={s.bbCustomReplyList}>
      {savedReplies?.items.map(({ id, name, message, tags, custom_tags }) => {
        const tagLabels: { id: string; label: string }[] = [...tags, ...custom_tags].map(tag => ({
          id: tag.id || "",
          label: tag.label,
        }));

        return (
          <SavedReplyCard
            name={name}
            message={message}
            key={id}
            onItemClick={onReplyClick}
            tags={tagLabels}
            actions={[
              {
                key: "edit",
                label: t("generic:edit"),
                onActionClick: () => onViewChange("edit", id),
              },
              {
                key: "delete",
                label: t("generic:delete"),
                onActionClick: () => onClickDeleteSavedReplies(id),
                confirmationText: t("components:reply:savedReplies:list:deletePrompt"),
              },
            ]}
          />
        );
      })}
    </div>
  );
}
