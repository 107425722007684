import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { Actions, IDataSearchProps } from "./types";
import { RootState } from "..";
import { POST_SEARCH } from "./actionTypes";
import { postSearchActionFailure, postSearchActionSuccess } from "./actions";

import { search } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const postSearchEpic = (action$: Observable<Actions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(POST_SEARCH)),
    switchMap(a => {
      return ajax
        .post<IDataSearchProps>(
          `${search}${a.payload.query ? `?${a.payload.query}` : ""}`,
          a.payload.data,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data =>
            postSearchActionSuccess({
              ...data,
              comments: data.comments.map((c, index) => ({
                ...c,
                index,
                key: c.id + index,
              })),
            }),
          ),
          catchError(e => handleError(e, postSearchActionFailure)),
        );
    }),
  );
