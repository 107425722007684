import { createReducer, ActionType } from "typesafe-actions";
import { IModerationStreamsState } from "./types";
import {
  getModerationStreamsActionSuccess,
  getModerationStreamsCommentsActionSuccess,
} from "./actions";
import {
  GET_MODERATION_STREAMS,
  GET_MODERATION_STREAMS_SUCCESS,
  GET_MODERATION_STREAMS_FAILURE,
  GET_MODERATION_STREAMS_COMMENTS,
  GET_MODERATION_STREAMS_COMMENTS_SUCCESS,
  GET_MODERATION_STREAMS_COMMENTS_FAILURE,
} from "./actionTypes";

const initialState = {
  streams: [],
  comments: [],
  fetchingModerationStreams: false,
  fetchedModerationStreams: false,
  fetchedModerationStreamsFail: false,
  fetchingModerationStreamsComments: false,
  fetchedModerationStreamsComments: false,
  fetchedModerationStreamsCommentsFail: false,
};

export const moderationStreamsReducer = createReducer<IModerationStreamsState>(initialState, {
  // GET_MODERATION_STREAMS
  [GET_MODERATION_STREAMS]: (state: IModerationStreamsState) => ({
    ...state,
    fetchingModerationStreams: true,
    fetchedModerationStreams: false,
    fetchedModerationStreamsFail: false,
  }),
  [GET_MODERATION_STREAMS_SUCCESS]: (
    state: IModerationStreamsState,
    action: ActionType<typeof getModerationStreamsActionSuccess>,
  ) => ({
    ...state,
    streams: action.payload,
    fetchingModerationStreams: false,
    fetchedModerationStreams: true,
    fetchedModerationStreamsFail: false,
  }),
  [GET_MODERATION_STREAMS_FAILURE]: (state: IModerationStreamsState) => ({
    ...state,
    fetchingModerationStreams: false,
    fetchedModerationStreams: false,
    fetchedModerationStreamsFail: true,
  }),

  // GET_MODERATION_STREAMS_COMMENTS
  [GET_MODERATION_STREAMS_COMMENTS]: (state: IModerationStreamsState) => ({
    ...state,
    fetchingModerationStreamsComments: true,
    fetchedModerationStreamsComments: false,
    fetchedModerationStreamsCommentsFail: false,
  }),
  [GET_MODERATION_STREAMS_COMMENTS_SUCCESS]: (
    state: IModerationStreamsState,
    action: ActionType<typeof getModerationStreamsCommentsActionSuccess>,
  ) => ({
    ...state,
    comments: action.payload,
    fetchingModerationStreamsComments: false,
    fetchedModerationStreamsComments: true,
    fetchedModerationStreamsCommentsFail: false,
  }),
  [GET_MODERATION_STREAMS_COMMENTS_FAILURE]: (state: IModerationStreamsState) => ({
    ...state,
    fetchingModerationStreamsComments: false,
    fetchedModerationStreamsComments: false,
    fetchedModerationStreamsCommentsFail: true,
  }),
});
