import React, { FC } from "react";

import s from "./BreakdownChartTooltip.module.less";

import { TooltipProps } from "recharts";

const BreakdownChartTooltip: FC<TooltipProps<string, string>> = ({ active, payload }) => {
  const bars = payload;

  return (
    <React.Fragment>
      {active && bars?.length ? (
        <div className={s.bbBreakdownChartTooltip}>
          <div className={s.bbItemName}>{bars[0].payload.label}</div>
          <div className={s.bbStatsContainer}>
            {bars.map(bar => (
              <div className={s.bbStatContainer} key={bar.name}>
                <div className={s.bbStatSquare} style={{ background: bar.color }}></div>
                <div className={s.bbStatItem}>
                  <div className={s.bbStatName}>{bar.name}</div>
                  <div> {bar.value} </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default BreakdownChartTooltip;
