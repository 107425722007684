import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Col, Empty } from "antd";
import ReactResizeDetector from "react-resize-detector";
import { Card, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import CommentsChart from "../../../_common/commentsChart/CommentsChart";

import s from "./CommentsByTagChart.module.less";

import { getSafetyCommentTagsLegendColor } from "../helpers";
import { IOverviewPerformanceTag } from "@store/dashboard/types";
import { ICommentsChartLegend } from "../../../_common/commentsChart/CommentsChart.types";

interface ICommentsByTagChartProps {
  title: string;
  subTitle: string;
  loading: boolean;
  withLegend?: boolean;
  data: IOverviewPerformanceTag[];
  showCalculatedPercentage?: boolean;
}

const CommentsByTagChart: FC<ICommentsByTagChartProps> = ({
  data,
  loading,
  title,
  subTitle,
  withLegend = false,
  showCalculatedPercentage = true,
}) => {
  const { t } = useTranslation();

  const [legendItems, setLegendItems] = useState<ICommentsChartLegend[]>([]);

  useEffect(() => {
    if (withLegend && data.length) {
      // Remove duplicates
      const uniqueItems: IOverviewPerformanceTag[] = [
        ...data
          .reduce((a, c) => {
            a.set(c.category, c);
            return a;
          }, new Map())
          .values(),
      ];

      // Create legend structure
      const legend: ICommentsChartLegend[] = uniqueItems.map(({ category, category_name }) => {
        return {
          id: category,
          label: category_name,
          tooltipProps: {
            title: t(`components:overviewSafety:hiddenByTag:legend:${category}`),
          },
          color: getSafetyCommentTagsLegendColor(category),
        };
      });

      setLegendItems(legend);
    }
    // eslint-disable-next-line
  }, [data.length]);

  return (
    <ReactResizeDetector
      handleWidth
      refreshMode="throttle"
      refreshOptions={{ leading: true, trailing: true }}
    >
      {(props: { width: number }) => (
        <Col xs={24}>
          <Card headerTitle={title} headerSubTitle={subTitle}>
            <div className={s.bbCommentsByTagChart} style={{ width: props.width }}>
              {loading ? (
                <div className={s.bbCommentsByTagLoading}>
                  <Loading isLoading />
                </div>
              ) : data.length ? (
                <CommentsChart
                  showCalculatedPercentage={showCalculatedPercentage}
                  data={data}
                  width={props.width}
                  legendItems={legendItems}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  className={s.bbCommentsByTagEmpty}
                  description={t("components:comments:tableEmpty")}
                  imageStyle={{
                    height: 40,
                  }}
                />
              )}
            </div>
          </Card>
        </Col>
      )}
    </ReactResizeDetector>
  );
};

export default CommentsByTagChart;
