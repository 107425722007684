import React, { useMemo } from "react";

import { Icon, Input, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import SelectedExcludedIndicator from "./SelectedExcludedIndicator";

import { ITriggerProps } from "@bbdevcrew/bb_ui_kit_fe";
import { generateInputValueState, generateInputValueStateSingle } from "./helpers";

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

export function SelectTrigger({
  id,
  _size,
  value,
  isOpen,
  disabled,
  excludedValues,
  wrapperClassName,
  onClick,
  tooltip,
  suffixEl,
  InputVariation = Input,
  showDefaultPlaceholder,
  placeholder,
  ...props
}: ITriggerProps) {
  const { all, selected, excluded } = useMemo(() => {
    return typeof value === "string"
      ? generateInputValueStateSingle(value)
      : generateInputValueState(value, excludedValues, placeholder, showDefaultPlaceholder);
    // eslint-disable-next-line
  }, [value, excludedValues]);

  const _onClick = () => {
    if (!disabled) onClick();
  };

  return (
    <Tooltip placement="topLeft" title={tooltip}>
      <div id="trigger" data-cy="trigger" onClick={_onClick} className="relative max-w-full">
        <InputVariation
          id={id}
          value={all}
          _size={_size}
          wrapperClassName={`${wrapperClassName} ${isOpen ? "shadow-focus" : ""}`}
          suffixEl={
            suffixEl ? (
              suffixEl
            ) : (
              <Icon _size={_size} icon={isOpen ? ChevronUpIcon : ChevronDownIcon} />
            )
          }
          placeholder={showDefaultPlaceholder || (!selected && !excluded) ? placeholder : undefined}
          {...props}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
        />

        {(!!selected || !!excluded) && (
          <SelectedExcludedIndicator selected={selected} excluded={excluded} />
        )}
      </div>
    </Tooltip>
  );
}

export default SelectTrigger;
