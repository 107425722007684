import { i18nextInstance } from "../../../App";
import { IAreaProps, IColorData } from "./Dashboard.type";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

export const tooltipDelay = process.env.TOOLTIP_DELAY ? parseFloat(process.env.TOOLTIP_DELAY) : 1.2;

export const colorData = {
  universally_harmful: palette.colorUniversallyHarmful,
  sensitive: palette.colorSensitive,
} as IColorData;

export const getAreas = (): IAreaProps[] => [
  {
    name: "universally_harmful",
    color: colorData["universally_harmful"],
    label: i18nextInstance.t("pages:dashboard:charts:universally_harmful"),
    tooltipString: i18nextInstance.t("pages:dashboard:charts:tooltips:universally_harmful"),
  },
  {
    name: "sensitive",
    color: colorData["sensitive"],
    label: i18nextInstance.t("pages:dashboard:charts:sensitive"),
    tooltipString: i18nextInstance.t("pages:dashboard:charts:tooltips:sensitive"),
  },
];
