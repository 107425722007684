import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { FC, useEffect, useState } from "react";

import { Row, Col } from "antd";
import CareSection from "./CareSection";
import SafetySection from "./SafetySection";
import SectionDivider from "../OverviewSectionDivider";
import { InputWhite, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";

import {
  overviewCareSelector,
  getDashboardSelector,
  getDashboardSelectorPending,
  getDashboardSelectorSuccessful,
} from "@store/dashboard/selectors";
import { meSelector } from "@store/me/selectors";

import s from "./ExternalAppOverview.module.less";

import { ReplySelectOptions, isNotEmpty } from "./helpers";

import { SafetyIcon, CommentIcon } from "@assets/index";
import { ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ExternalAppOverview: FC = () => {
  const { t } = useTranslation();

  const [replySelectValue, setReplySelectValue] = useState<string>("");

  const me = useSelector(meSelector);
  const dashboard = useSelector(getDashboardSelector);
  const careData = useSelector(overviewCareSelector);
  const dashboardFetching = useSelector(getDashboardSelectorPending);
  const dashboardFetched = useSelector(getDashboardSelectorSuccessful);

  useEffect(() => {
    if (dashboard && dashboardFetched && careData) {
      if (
        isNotEmpty(careData.care_bb_replied_stats.items) ||
        isNotEmpty(careData.tags_bb_replied_stats.items)
      ) {
        setReplySelectValue(ReplySelectOptions.REPLIED_BY_BB);
      } else if (
        isNotEmpty(careData.care_platform_replied_stats.items) ||
        isNotEmpty(careData.tags_platform_replied_stats.items)
      ) {
        setReplySelectValue(ReplySelectOptions.REPLIED_IN_PLATFORM);
      } else setReplySelectValue(ReplySelectOptions.REPLIED_BY_ALL);
    }
  }, [dashboard, dashboardFetched, careData]);

  const goToPath = (path: string) => {
    window.location.href = `${window.location.origin}${path}`;
  };

  return (
    <>
      {me?.client?.is_moderation_managed && (
        <>
          <Col xs={24}>
            <SectionDivider
              icon={<SafetyIcon />}
              title={t("pages:overviewSections:safety:titleManaged")}
              description={t("pages:overviewSections:safety:descriptionManaged")}
              onActionButtonClick={() => goToPath("/moderation")}
              actionButtonText={
                <>
                  <span>{t("pages:overviewSections:safety:action")}</span>
                  <ChevronRightIcon />
                </>
              }
            />
          </Col>
          <Col xs={24}>
            <Row gutter={[28, 28]} className={s.bbSafetyWrapper}>
              <SafetySection
                loading={dashboardFetching}
                data={dashboard?.explorer_overview_safety}
              />
            </Row>
          </Col>
        </>
      )}

      {me?.client?.is_care_managed && (
        <>
          <Col xs={24}>
            <SectionDivider
              icon={<CommentIcon />}
              title={t("pages:overviewSections:care:titleManaged")}
              description={t("pages:overviewSections:care:descriptionManaged")}
              onActionButtonClick={() => goToPath("/care")}
              actionButtonText={
                <>
                  <span>{t("pages:overviewSections:care:action")}</span>
                  <ChevronRightIcon />
                </>
              }
              additionalAction={
                <>
                  {replySelectValue && (
                    <SelectStyled
                      _size="md"
                      value={replySelectValue}
                      onChange={val => setReplySelectValue(val)}
                      InputVariation={InputWhite}
                      wrapperClassName={s.bbReplySelect}
                      options={Object.values(ReplySelectOptions)}
                    />
                  )}
                </>
              }
            />
          </Col>
          <Col xs={24}>
            <Row gutter={[28, 28]} className={s.bbCareWrapper}>
              <CareSection
                loading={dashboardFetching}
                replySelectValue={replySelectValue}
                data={dashboard?.explorer_overview_care}
              />
            </Row>
          </Col>
        </>
      )}
    </>
  );
};

export default ExternalAppOverview;
