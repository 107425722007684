import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";

import { performCommentAction, updateCommentSentimentAction } from "@store/comments/actions";

import s from "./MessageCardDropdown.module.less";
import sentimentStyles from "../../CommentCard/commentActions/CommentActions.module.less";

import { IMessage } from "@store/replies/types";
import { IFilters } from "@store/filters/types";
import { isLinkedinPlatform, isTiktokPlatform } from "@utils/platform";
import { SENTIMENT_ACTIONS } from "../../CommentCard/commentActions/helper";
import { IActionDropdownItem } from "../../ActionsDropdown/ActionsDropdown.type";

import {
  StarIcon,
  StarFilledIcon,
  ArchiveIcon,
  ArchiveFilledIcon,
  SmileyIcon,
} from "@assets/index";
import { EyeOffIcon, EyeOffFilledIcon, DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const useCommentActionDropdownItems = (
  message: IMessage,
  filters: IFilters,
  platformId: string,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModal] = useState(false);

  const isHidden = message.is_hidden;
  const isStarred = message.is_starred;
  const isArchived = message.is_ignored;

  const canDelete = !message.is_deleted && isLinkedinPlatform(platformId);
  const canStar = !message.is_ignored;
  const canHideUnhide = message.can_hide_unhide && !isLinkedinPlatform(platformId);
  const canChangeSentiment = !isTiktokPlatform(platformId);

  const hideComment = useCallback(() => {
    if (message.id) {
      dispatch(
        performCommentAction({
          id: message.id,
          value: !isHidden,
          action: "hide",
          filters,
        }),
      );
    }
  }, [dispatch, isHidden, message.id, filters]);

  const starComment = useCallback(() => {
    if (message.id) {
      dispatch(
        performCommentAction({
          id: message.id,
          value: !isStarred,
          action: "star",
          filters,
        }),
      );
    }
  }, [dispatch, isStarred, message.id, filters]);

  const archiveComment = useCallback(() => {
    if (message.id) {
      dispatch(
        performCommentAction({
          id: message.id,
          value: !isArchived,
          action: "ignore",
          filters,
        }),
      );
    }
  }, [dispatch, isArchived, message.id, filters]);

  const updateCommentSentiment = useCallback(
    (id, sentiment) => {
      dispatch(updateCommentSentimentAction(id, { sentiment }));
    },
    [dispatch],
  );

  const handleToggleConfirmationModal = () => {
    setIsDeleteConfirmationModal(prev => !prev);
  };

  const deleteComment = () => {
    dispatch(
      performCommentAction({
        id: message.id,
        value: true,
        action: "delete",
        filters,
      }),
    );
  };

  const handleConfirmDeleteComment = () => {
    deleteComment();
    handleToggleConfirmationModal();
  };

  const items: IActionDropdownItem[] = [
    {
      id: "star",
      selected: isStarred,
      disabled: !canStar,
      onClick: starComment,
      label: isStarred ? t("generic:starred") : t("generic:star"),
      tooltipText: isStarred && canStar ? t("components:comments:tooltips:starRemove") : undefined,
      icon: isStarred ? <StarFilledIcon /> : <StarIcon />,
    },
    {
      id: "archive",
      selected: isArchived,
      disabled: !canStar,
      onClick: archiveComment,
      label: isArchived ? t("generic:archived") : t("generic:archive"),
      tooltipText: isArchived ? t("components:comments:tooltips:archiveRemove") : undefined,
      icon: isArchived ? <ArchiveFilledIcon /> : <ArchiveIcon />,
    },
  ];

  if (canChangeSentiment) {
    items.unshift({
      id: "sentiment",
      label: t("components:comments:actions:changeSentiment"),
      icon: <SmileyIcon />,
      iconClassName: sentimentStyles.bbSentimentIconClass,
      subItems: SENTIMENT_ACTIONS.map(sentiment => {
        return {
          id: sentiment,
          onClick: () => updateCommentSentiment(message.id, sentiment),
          label: t(`components:comments:actions:sentiments:${sentiment}`),
          iconClassName: classNames(
            s.bbChangeSentiment,
            sentimentStyles.bbActionCircleClass,
            sentimentStyles[`bbActionCircleClass-${sentiment}`],
          ),
        };
      }),
    });
  }

  if (canHideUnhide) {
    items.unshift({
      id: "hide",
      selected: isHidden,
      onClick: hideComment,
      label: isHidden ? t("generic:hidden") : t("generic:hide"),
      tooltipText: isHidden ? t("generic:unhide") : undefined,
      icon: isHidden ? <EyeOffFilledIcon /> : <EyeOffIcon />,
    });

    if (canDelete) {
      items.unshift({
        id: "delete",
        onClick: handleToggleConfirmationModal,
        label: t("generic:delete"),
        icon: <DeleteIcon />,
      });
    }
  }

  return {
    actionDropdownItems: items,
    deleteConfirmationModal: {
      isOpen: isDeleteConfirmationModalOpen,
      onConfirm: handleConfirmDeleteComment,
      onCancel: handleToggleConfirmationModal,
    },
  };
};
