import classNames from "classnames";
import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Layout, Grid } from "antd";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../Appfooter/AppFooter";
import AppHeaderMobile from "../AppHeader/AppHeaderMobile";

import s from "./AppLayout.module.less";

import { IAppLayout } from "./AppLayout.type";
import { selectItem } from "../AppHeader/AppHeaderCommons";
import { filtersPanelWidthSelector } from "@store/filters/selectors";

const { Content } = Layout;

const AppLayout: FC<IAppLayout> = ({
  menu,
  extra,
  children,
  basename,
  hasHeader,
  hasFooter,
  hasFilters,
  avatarUrl,
  selectedKey,
  defaultSelectedKey,
  onPreRedirect = () => Promise.resolve(),
}) => {
  const screens = Grid.useBreakpoint();
  const { content, beforeContent, afterContent } = children;

  const filtersPanelWidth = useSelector(filtersPanelWidthSelector);
  const isFooterFixed = window.location.pathname.includes("/settings/asset-management/add-assets");

  const props = {
    basename: basename || "",
    selectedKey: selectedKey ? selectedKey : "",
    defaultSelectedKey,
    screens,
    menu,
    extra,
    avatarUrl,
    onPreRedirect,
  };

  return (
    <Layout>
      {hasHeader || hasHeader === undefined ? (
        !screens.lg ? (
          <AppHeaderMobile {...props} />
        ) : (
          <AppHeader {...props} />
        )
      ) : null}
      {/* TODO: remove, filter usage edge-case */}
      <div
        style={
          hasFilters && screens.md
            ? {
                width: `calc(100vw - ${filtersPanelWidth}px)`,
              }
            : undefined
        }
        className={s.bbAppLayoutContentWrapper}
      >
        <Content
          className={classNames(s.bbLayoutContent, {
            [s.bbLayoutContentSmall]: selectedKey && selectItem(menu, selectedKey) ? false : true,
            [s.bbLayoutContentFull]: hasFooter === false,
          })}
        >
          <div>
            {beforeContent}
            {content}
            {afterContent}
          </div>
        </Content>
      </div>
      {(hasFooter || hasFooter === undefined) && <AppFooter isFixed={isFooterFixed} />}
    </Layout>
  );
};

export default AppLayout;
