import { ISortableTableColumn } from "@bbdevcrew/bb_ui_kit_fe";
import { i18nextInstance } from "../../../../App";
import { ScreenMap } from "antd/lib/_util/responsiveObserve";

export const getColumns = (
  t: typeof i18nextInstance.t,
  screens: ScreenMap,
): ISortableTableColumn[] => {
  const columnsSizes = {
    sm: [0, 12, 10, 0, 0],
    md: [6, 6, 5, 5, 0],
    lg: [5, 5, 4, 4, 4],
  };
  const columnsSize = columnsSizes[screens.lg ? "lg" : screens.md ? "md" : "sm"];

  return [
    {
      name: t("components:messageTypeDetailTable:categoryColumn"),
      id_name: "category",
      isSortable: true,
      colSpan: columnsSize[0],
    },
    {
      name: t("components:messageTypeDetailTable:messageTypeColumn"),
      id_name: "messageType",
      isSortable: true,
      colSpan: columnsSize[1],
    },
    {
      name: t("components:messageTypeDetailTable:volumeColumn"),
      id_name: "volume",
      isSortable: true,
      colSpan: columnsSize[2],
    },
    {
      name: t("components:messageTypeDetailTable:percentageColumn"),
      id_name: "percentage",
      isSortable: true,
      colSpan: columnsSize[3],
    },
    {
      name: t("components:messageTypeDetailTable:percentageChangeColumn"),
      id_name: "percentageChange",
      isSortable: true,
      colSpan: columnsSize[4],
    },
  ];
};
