import cn from "classnames/bind";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox } from "@bbdevcrew/bb_ui_kit_fe";

import ButtonPermissionGuard from "../ButtonPermissionGuard/ButtonPermissionGuard";

import { IReplyBottomActions } from "./Reply.type";

import s from "./Reply.module.less";

import { SendReplyIcon } from "@assets/index";

const cx = cn.bind(s);

const ReplyBottomActions: FC<IReplyBottomActions> = ({
  comment,
  message,
  isPrivateReply,
  isReplyBtnLoading,
  isReplyBtnDisabled,
  replyMessageLength,
  maxReplyMessageLength,
  onInternalReplyClick,
}) => {
  const { t } = useTranslation();

  const [isAutoNavigation, setIsAutoNavigation] = useState(
    JSON.parse(localStorage.getItem("AutoNavigation") || "true"),
  );

  const onAutoNavigationToggle = () => {
    setIsAutoNavigation(!isAutoNavigation);
    localStorage.setItem("AutoNavigation", (!isAutoNavigation).toString());
  };

  return (
    <div className={cx(s.bbReplyInputActions, s.bbReplyInputActionsBottom)}>
      <div className={s.bbReplyInputActionsLeft}>
        {replyMessageLength >= maxReplyMessageLength && (
          <span className={s.bbReplyInputErrorMessage}>
            {t("components:reply:charLimitError", {
              amount: maxReplyMessageLength,
            })}
          </span>
        )}
        <span className={s.bbAutoNavigationToggle} onClick={onAutoNavigationToggle}>
          <Checkbox id={"auto-navigation"} checked={isAutoNavigation} _size="sm" />

          <span className={s.bbAutoNavigationTitle}>
            {t("components:reply:autoNavigationCheckbox")}
          </span>
        </span>
      </div>
      <ButtonPermissionGuard
        message={comment && comment.actions.reply_tooltip}
        permission={!!comment ? comment.actions.can_reply : true}
      >
        <Button
          _size="md"
          _type="primary"
          loading={isReplyBtnLoading}
          onClick={onInternalReplyClick}
          disabled={!message.trim().length || isReplyBtnDisabled}
          className={s.bbReplyBtn}
        >
          <SendReplyIcon />
          {isPrivateReply
            ? t("components:reply:sendPrivateReply")
            : t("components:reply:sendReply")}
        </Button>
      </ButtonPermissionGuard>
    </div>
  );
};

export default ReplyBottomActions;
