import classNames from "classnames/bind";
import React, { FC, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AppHeaderExtraMenu from "./ExtraMenu";
import { Layout, Drawer, Menu, Avatar } from "antd";
import UpgradePlanButton from "./UpgradePlanButton";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";
import { Icon, Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { useTrialSubscription } from "@utils/useTrialSubscription";
import { IAppHeaderProps, IMenuItem, IAppSubfeature } from "./AppHeader.type";
import { isItemActive, onFeatureRedirect, onSubfeatureRedirect } from "./AppHeaderCommons";

import s from "./AppHeaderMobile.module.less";

import { HelpIcon, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { Logo, BarsIcon, LockIcon, UserFilledIcon } from "@assets/index";

const AppHeaderMobile: FC<IAppHeaderProps> = ({
  menu,
  extra,
  basename,
  avatarUrl,
  selectedKey,
  defaultSelectedKey,
  onPreRedirect = () => Promise.resolve(),
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isTrialSubscription = useTrialSubscription();

  const [leftDrawer, setLeftDrawer] = useState(false);
  const [rightDrawer, setRightDrawer] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(
    location.search === "?subscription-modal=true",
  );

  const renderFeature = (item: IMenuItem) => (
    <div className={s.bbFeatureWrapper}>
      <div>{item.label}</div>
      {item.is_disabled && item.tooltip && (
        <div>
          <Tooltip title={item.tooltip || ""} placement="bottom">
            <span className={s.bbFeatureWrapperIcon}>
              <HelpIcon />
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );

  const onSubfeatureClick = (item: IAppSubfeature) => {
    if (!item.is_disabled) {
      setLeftDrawer(false);
      setRightDrawer(false);

      return (
        !isItemActive(item) &&
        onSubfeatureRedirect(navigate, onPreRedirect, item, item.basename === basename)
      );
    }
  };

  const renderSubfeature = (item: IAppSubfeature) => {
    return (
      <Menu.Item
        id={item.id}
        key={item.id}
        disabled={item.is_disabled}
        onClick={() => onSubfeatureClick(item)}
      >
        <div
          className={classNames(s.bbSubfeature, {
            [s.bbSubfeatureDisabled]: item.is_disabled,
          })}
        >
          {item.label}
          {item.is_disabled ? (
            <Tooltip
              placement="bottom"
              title={item.is_disabled && item.tooltip ? item.tooltip : ""}
            >
              <span className={s.bbSubfeatureDisabledIcon}>
                <LockIcon />
              </span>
            </Tooltip>
          ) : null}
        </div>
      </Menu.Item>
    );
  };

  const onTitleClick = (item: IMenuItem) => {
    onSubfeatureClick(item);
  };

  const renderMenuMain = (item: IMenuItem) => {
    return item?.subfeatures?.length ? (
      <Menu.SubMenu
        key={item.id}
        disabled={item.is_disabled}
        title={renderFeature(item)}
        icon={
          <img
            alt="menu_item_icon"
            className={s.bbAppHeaderMenuItemIcon}
            src={item.is_disabled ? item.iconInactiveUrl : item.iconActiveUrl}
          />
        }
      >
        {item?.subfeatures?.map(sub => renderSubfeature(sub))}
      </Menu.SubMenu>
    ) : (
      <Menu.Item
        key={item.id}
        disabled={item.is_disabled}
        onClick={() => onTitleClick(item)}
        title={item.label}
        icon={
          <img
            alt="menu_item_icon"
            className={s.bbAppHeaderMenuItemIcon}
            src={item.is_disabled ? item.iconInactiveUrl : item.iconActiveUrl}
          />
        }
      >
        {item.label}
      </Menu.Item>
    );
  };

  const onSubscriptionModalOpen = () => {
    setRightDrawer(false);
    setShowSubscriptionModal(true);
  };

  const onLogoClick = () => {
    const overviewSubfeature = menu
      .find(mainItem => mainItem.id === "insights")
      ?.subfeatures?.find(subFeature => subFeature.id === "overview");

    onFeatureRedirect(
      onPreRedirect,
      overviewSubfeature as IMenuItem,
      overviewSubfeature?.basename === basename,
    );
  };

  return (
    <React.Fragment>
      <Drawer
        mask={false}
        width={"100%"}
        placement="left"
        closable={false}
        visible={leftDrawer}
        destroyOnClose={true}
        className={s.bbAppHeaderMobileDrawer}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKey?.split(",")}
          selectedKeys={selectedKey?.split(",")}
          defaultOpenKeys={menu.map(item => item.id)}
          className={s.bbAppHeaderMenu}
        >
          {menu ? menu.map(item => renderMenuMain(item)) : null}
        </Menu>
      </Drawer>
      <Drawer
        placement="right"
        mask={false}
        width={"100%"}
        closable={false}
        destroyOnClose={true}
        visible={rightDrawer}
        className={s.bbAppHeaderMobileDrawer}
        bodyStyle={{
          padding: 0,
        }}
      >
        <AppHeaderExtraMenu
          basename={basename}
          extra={extra}
          selectedKey={selectedKey}
          defaultSelectedKey={defaultSelectedKey}
          onSubscriptionModalOpen={onSubscriptionModalOpen}
          callbackItemClick={() => setRightDrawer(false)}
        />
      </Drawer>
      <Layout.Header className={s.bbAppHeaderContainer}>
        <div className={s.bbAppHeaderLeftMenu}>
          <Button
            _type="link"
            onClick={() => {
              setLeftDrawer(!leftDrawer);
              setRightDrawer(false);
              return true;
            }}
          >
            <Icon _size="sm" icon={leftDrawer ? CloseIcon : BarsIcon}></Icon>
          </Button>
        </div>

        <div className={s.bbAppHeaderLogo} onClick={onLogoClick}>
          <Logo />
        </div>

        {isTrialSubscription && (
          <div className="flex items-center">
            <UpgradePlanButton
              data-stonly-target="app-global__header--upgrade-plan"
              onClick={onSubscriptionModalOpen}
            />
          </div>
        )}

        <div
          className={s.bbAppHeaderExtraContainer}
          onClick={() => {
            setRightDrawer(!rightDrawer);
            setLeftDrawer(false);
            return true;
          }}
        >
          {!rightDrawer ? (
            <Avatar
              className={s.bbAppHeaderAvatar}
              size={32}
              icon={
                <span className={s.bbAppHeaderAvatarIcon}>
                  <UserFilledIcon />
                </span>
              }
              src={avatarUrl || null}
            />
          ) : (
            <Button _type="link" onClick={() => setRightDrawer(!rightDrawer)}>
              <CloseIcon />
            </Button>
          )}
        </div>
      </Layout.Header>
      <SubscriptionModal
        isVisible={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
    </React.Fragment>
  );
};

export default AppHeaderMobile;
