import moment from "moment";
import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { DATE_FORMAT, TIME_FORMAT } from "./PrivateMessageCard.type";

import s from "./ReplyDateTime.module.less";

import { EyeOffIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IReplyDateTimeProps {
  date?: string;
  author: JSX.Element;
  isHidden?: boolean;
  className?: string;
}

export const ReplyDateTime: FC<IReplyDateTimeProps> = ({ date, author, isHidden, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(s.bbReplyDateTime, className)}>
      <span className={s.bbReplyDateTimeAuthor} data-testid="author">
        {author}
      </span>
      {!!date && (
        <span className={s.bbReplyDateTimeDate} data-testid="date">
          {`${moment(date).format(DATE_FORMAT)} ${t("generic:at")} ${moment(date).format(
            TIME_FORMAT,
          )}`}
        </span>
      )}
      {isHidden && (
        <span className={s.bbReplyDateTimeHidden}>
          <EyeOffIcon />
        </span>
      )}
    </div>
  );
};
