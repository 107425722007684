import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { FC, useState, useEffect } from "react";

import { Col, Grid } from "antd";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../../_common/pieChartWidget/PieChartWidget";

import s from "./../sentimentPieChart/SentimentPieChart.module.less";

import { IPieChartUnit } from "../../search/Search.type";
import { normalizePlatformData } from "../../search/helpers";
import { IFilters } from "@store/filters/types";
import { IOverviewPerformanceItem } from "@store/dashboard/types";

interface IPlatformsPieChartProps {
  loading: boolean;
  filterData: IFilters;
  data: IOverviewPerformanceItem[];
}

const PlatformsPieChart: FC<IPlatformsPieChartProps> = ({ filterData, data, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const [commentPlatformsData, setCommentPlatformsData] = useState<IPieChartUnit[]>([]);
  const [total, setTotal] = useState<IPieChartUnit>({} as IPieChartUnit);

  useEffect(() => {
    const normalizeData = () => {
      const normalizedPlatformData = normalizePlatformData(data);

      const pieChartTotal = normalizedPlatformData.find(
        (unit: IPieChartUnit) => unit.rawName === "total",
      ) as IPieChartUnit;
      const pieChartData = normalizedPlatformData.filter(
        (unit: IPieChartUnit) => unit.rawName !== "total",
      ) as IPieChartUnit[];

      setTotal(pieChartTotal);
      setCommentPlatformsData(pieChartData);
    };

    normalizeData();
  }, [data]);

  const onChartClick = (event: { payload: { rawName: string } }) => {
    const filters = {
      ...filterData,
      platform_types: [event.payload.rawName],
    };

    navigate("/search", {
      state: { filters },
    });
  };

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
      <Card
        headerTitle={t("components:platformsPieChart:headerTitle")}
        headerSubTitle={t("components:platformsPieChart:headerSubTitle")}
      >
        <div className={s.bbPlatformsPieChart}>
          <PieChartWidget
            loading={loading}
            legendPosition={!screens.lg ? "bottom" : "center"}
            defaultValue={total}
            onChartClick={onChartClick}
            chartData={commentPlatformsData.filter(unit => unit.value !== 0)}
          />
        </div>
      </Card>
    </Col>
  );
};

export default PlatformsPieChart;
