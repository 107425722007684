import React, { FC } from "react";

import s from "./LinearProgressBar.module.less";

interface ILinearProgressBarProps {
  percentage: number;
  height?: number;
}

const BAR_OVERLAP = 5;

export const LinearProgressBar: FC<ILinearProgressBarProps> = ({ percentage, height = 20 }) => {
  const bgPercentage = percentage + BAR_OVERLAP;
  const progressCircleSize = height / 2;

  return (
    <div className={s.bbLinearProgressBar}>
      <div className={s.bbLinearProgressBarBg} style={{ height: height }}>
        {percentage ? (
          <>
            <div
              className={s.bbLinearProgressBarFill}
              style={{
                width: percentage + "%",
              }}
            >
              <div
                className={s.bbProgressCircle}
                style={{
                  width: progressCircleSize,
                  height: progressCircleSize,
                }}
              ></div>
            </div>
            <div
              className={s.bbLinearProgressBarFillBg}
              style={{
                height: height,
                right: -bgPercentage + "%",
                width: 100 - bgPercentage + "%",
              }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};
