import { action } from "typesafe-actions";
import { IPostsPayload, IPostsAPIResponse } from "./types";
import {
  GET_POST,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  CLEAR_POST,
  GET_POST_ADS,
  GET_POST_ADS_SUCCESS,
  GET_POST_ADS_FAILURE,
  CLEAR_POST_ADS,
} from "./actionTypes";
import { GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAILURE } from "./actionTypes";
import { IPost } from "@components/_common/Social/Post/Post.type";
import { IAd } from "@components/_common/Social/Ads/Ads.type";

export const getPostsAction = (payload: IPostsPayload) => action(GET_POSTS, payload);
export const getPostsSuccessAction = (compareData: IPostsAPIResponse) =>
  action(GET_POSTS_SUCCESS, compareData);
export const getPostsFailureAction = () => action(GET_POSTS_FAILURE);

export const getPostAction = (id: string) => action(GET_POST, id);
export const getPostSuccessAction = (data: IPost) => action(GET_POST_SUCCESS, data);
export const getPostFailureAction = () => action(GET_POST_FAILURE);

export const clearPostAction = () => action(CLEAR_POST);

export const getPostAdsAction = (id: string) => action(GET_POST_ADS, id);
export const getPostAdsSuccessAction = (data: IAd[]) => action(GET_POST_ADS_SUCCESS, data);
export const getPostAdsFailureAction = () => action(GET_POST_ADS_FAILURE);

export const clearPostAdsAction = () => action(CLEAR_POST_ADS);
