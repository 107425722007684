import { action } from "typesafe-actions";
import {
  POST_SEARCH,
  POST_SEARCH_SUCCESS,
  POST_SEARCH_FAILURE,
  TRANSLATE_SEARCH_COMMENT,
  UPDATE_ALERTS_NOTIFICATION,
  UPDATE_ALERTS_NOTIFICATION_SUCCESS,
  RESET_SEARCH_DATA,
} from "./actionTypes";
import { IDataSearchProps } from "./types";
import { IFilters } from "../filters/types";

export const postSearchAction = (data: IFilters, query = "") =>
  action(POST_SEARCH, {
    data: data,
    query: query,
  });
export const postSearchActionSuccess = (data: IDataSearchProps) =>
  action(POST_SEARCH_SUCCESS, data);
export const postSearchActionFailure = () => action(POST_SEARCH_FAILURE);

export const translateSearchComment = (index: number, isParent: boolean) =>
  action(TRANSLATE_SEARCH_COMMENT, { index: index, isParent: isParent });

export const udpateAlertsNotification = (id: string, data: number) =>
  action(UPDATE_ALERTS_NOTIFICATION, {
    id: id,
    data: data,
  });

export const udpateAlertsNotificationSuccess = () => action(UPDATE_ALERTS_NOTIFICATION_SUCCESS);

export const resetSearchData = () => action(RESET_SEARCH_DATA);
