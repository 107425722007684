import cn from "classnames/bind";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import { PerformanceCardPill } from "./Pill";
import { Card, Help, Loading, numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./PerformanceCard.module.less";

import { IPerformanceCardProps } from "./types";

const cx = cn.bind(s);

export const PerformanceCard: FC<IPerformanceCardProps> = ({
  data,
  type,
  icon,
  title,
  size,
  onClick,
  isLoading,
  titleTooltipProps,
  percentageTooltipProps,
  tooltipIconType = "help",
  children,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (type !== "total") onClick && onClick(type);
  };

  return (
    <Card
      onClick={handleClick}
      className={cx(s[`bbPerformanceCard--${size}`], {
        "cursor-pointer": !!onClick,
      })}
      data-stonly-target={`intelligence-overview__top-boxes--${type}`}
    >
      {isLoading ? (
        <div className={cx(s[`bbPerformanceCardLoading--${size}`])}>
          <Loading isLoading />
        </div>
      ) : data ? (
        <>
          <div data-cy="performance-card-header" className="flex justify-between">
            <span>
              <span className="text-grey-700 font-bold" data-cy="performance-card-title">
                {title}
              </span>
              {!!titleTooltipProps?.title && (
                <Help
                  iconType={tooltipIconType}
                  tooltipProps={titleTooltipProps}
                  iconClassName={s.bbHelpIcon}
                />
              )}
            </span>
            <span
              className={cx(s.bbPerformanceCardIcon, `bbPerformanceCardIcon-${type}`)}
              data-cy="performance-card-icon"
            >
              {icon}
            </span>
          </div>

          <div data-cy="performance-card-body" className="flex">
            <h3
              className="inline font-bold text-grey-900 leading-none"
              data-cy="performance-card-amount"
            >
              {typeof data.count === "number" ? numberFormatter(data?.count) || "" : data.count}
            </h3>
            {data?.change_percentage !== null ? (
              <PerformanceCardPill
                type={type}
                percentageValue={data.change_percentage}
                percentageTooltipProps={percentageTooltipProps}
              />
            ) : (
              <div className={s.bbPerformanceCardPill}></div>
            )}
          </div>

          {children}
        </>
      ) : (
        <Empty
          className={s.bbPerformanceCardEmpty}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("components:empty:description")}
          imageStyle={{
            height: 40,
          }}
        />
      )}
    </Card>
  );
};
