import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SuggestedReplies.module.less";

import { cropText } from "@utils/cropText";

import { MagicSparklesIcon } from "@assets/index";

interface ISuggestedRepliesProps {
  suggestedReplyMessage?: string;
  setSuggestedReply: (message: string) => void;
}

export const SuggestedReplies: FC<ISuggestedRepliesProps> = ({
  suggestedReplyMessage,
  setSuggestedReply,
}) => {
  const { t } = useTranslation();

  if (!suggestedReplyMessage) return null;

  const truncatedMessage = cropText(suggestedReplyMessage, 70);
  const tooltipMessage = truncatedMessage === suggestedReplyMessage ? "" : suggestedReplyMessage;

  return (
    <div data-testid="suggested-reply" className={s.bbSuggestedReply}>
      <span className={s.bbSuggestedReplyAutomatedIcon}>
        <MagicSparklesIcon />
      </span>
      <h3 className={s.bbSuggestedReplyTitle}>{t("components:reply:suggestedReply")}</h3>

      <Tooltip title={tooltipMessage}>
        <Button
          _size="sm"
          _type="secondary"
          onClick={() => setSuggestedReply(suggestedReplyMessage)}
        >
          {truncatedMessage}
        </Button>
      </Tooltip>
    </div>
  );
};
