import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import { IPostsState } from "./types";
import { GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAILURE } from "./actionTypes";
import {
  ADD_POST_TAG,
  ADD_POST_TAG_SUCCESS,
  ADD_POST_TAG_FAILURE,
  DELETE_TAG_FROM_POST_SUCCESS,
} from "../postTags/actionTypes";
import {
  createPostTagAction,
  createPostTagSuccessAction,
  createPostTagFailureAction,
  deleteTagFromPostSuccessAction,
} from "../postTags/actions";
import {
  GET_POST,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  CLEAR_POST,
  GET_POST_ADS,
  GET_POST_ADS_SUCCESS,
  GET_POST_ADS_FAILURE,
  CLEAR_POST_ADS,
} from "./actionTypes";
import { getPostSuccessAction, getPostAdsSuccessAction, getPostsSuccessAction } from "./actions";

import { cleanUpCache, createCacheObject, selectFromCache } from "@utils/cache";

const initialState = {
  cache: {},
  postIds: [],
  ads: [],
  fetchingPosts: false,
  fetchedPosts: false,
  fetchedPostsFail: false,
  fetchingPost: false,
  fetchedPost: false,
  fetchedPostFail: false,
  fetchingPostAds: false,
  fetchedPostAds: false,
  fetchedPostAdsFail: false,
  clearPost: false,
  clearPostAds: false,
};

export const postsReducer = createReducer<IPostsState>(initialState, {
  [GET_POSTS]: produce((draft: IPostsState) => {
    draft.fetchingPosts = true;
    draft.fetchedPosts = false;
    draft.fetchedPostsFail = false;
  }),
  [GET_POSTS_SUCCESS]: produce(
    (draft: IPostsState, action: ActionType<typeof getPostsSuccessAction>) => {
      const cache = draft.cache;
      const postIds = action.payload.items.map(post => {
        cache[post.id] = createCacheObject(post);
        return post.id;
      });

      cleanUpCache(cache);

      draft.cache = cache;
      draft.postIds = postIds;
      draft.pagination = action.payload.pagination;
      draft.sort = action.payload.sort;
      draft.fetchingPosts = false;
      draft.fetchedPosts = true;
      draft.fetchedPostsFail = false;
    },
  ),
  [GET_POSTS_FAILURE]: produce((draft: IPostsState) => {
    draft.postIds = [];
    draft.fetchingPosts = false;
    draft.fetchedPosts = false;
    draft.fetchedPostsFail = true;
  }),
  // POST TAG ACTIONS
  [ADD_POST_TAG]: produce((draft: IPostsState, action: ActionType<typeof createPostTagAction>) => {
    const postId = action.payload?.post_id;
    if (postId) {
      const post = selectFromCache(draft.cache, postId);

      if (post) {
        post.post_tags = [
          ...(post.post_tags || []),
          {
            label: action.payload.label,
          },
        ];
      }
    }
  }),
  [ADD_POST_TAG_SUCCESS]: produce(
    (draft: IPostsState, action: ActionType<typeof createPostTagSuccessAction>) => {
      const post = selectFromCache(draft.cache, action.payload.postId);

      if (post) {
        const newTagIndex = post.post_tags?.findIndex(
          tag => tag.label === action.payload.tag.label,
        );

        if (newTagIndex !== undefined && newTagIndex > -1) {
          post.post_tags[newTagIndex] = action.payload.tag;
        }
      }
    },
  ),
  [ADD_POST_TAG_FAILURE]: produce(
    (draft: IPostsState, action: ActionType<typeof createPostTagFailureAction>) => {
      const postId = action.payload;
      if (postId) {
        const post = selectFromCache(draft.cache, postId);

        if (post) {
          post.post_tags = (post.post_tags || []).filter(tag => tag.id);
        }
      }
    },
  ),
  // DELETE TAG ACTIONS
  [DELETE_TAG_FROM_POST_SUCCESS]: produce(
    (draft: IPostsState, action: ActionType<typeof deleteTagFromPostSuccessAction>) => {
      const post = selectFromCache(draft.cache, action.payload.post_id);
      if (post) {
        post.post_tags = post.post_tags.filter(t => t.id !== action.payload.post_tag_id);
      }
    },
  ),
  [GET_POST]: produce((draft: IPostsState) => {
    draft.fetchingPost = true;
    draft.fetchedPost = false;
    draft.fetchedPostFail = false;
    draft.clearPost = false;
  }),
  [GET_POST_SUCCESS]: produce(
    (draft: IPostsState, action: ActionType<typeof getPostSuccessAction>) => {
      draft.cache[action.payload.id] = createCacheObject(action.payload);
      cleanUpCache(draft.cache);
      draft.postId = action.payload.id;
      draft.fetchingPost = false;
      draft.fetchedPost = true;
      draft.fetchedPostFail = false;
      draft.clearPost = false;
    },
  ),
  [GET_POST_FAILURE]: produce((draft: IPostsState) => {
    draft.fetchingPost = false;
    draft.fetchedPost = false;
    draft.fetchedPostFail = true;
    draft.clearPost = false;
  }),
  [CLEAR_POST]: produce((draft: IPostsState) => {
    draft.postId = undefined;
    draft.fetchingPost = false;
    draft.fetchedPost = false;
    draft.fetchedPostFail = false;
    draft.clearPost = true;
  }),

  [GET_POST_ADS]: produce((draft: IPostsState) => {
    draft.fetchingPostAds = true;
    draft.fetchedPostAds = false;
    draft.fetchedPostAdsFail = false;
  }),
  [GET_POST_ADS_SUCCESS]: produce(
    (draft: IPostsState, action: ActionType<typeof getPostAdsSuccessAction>) => {
      draft.ads = action.payload;
      draft.fetchingPostAds = false;
      draft.fetchedPostAds = true;
      draft.fetchedPostAdsFail = false;
    },
  ),
  [GET_POST_ADS_FAILURE]: produce((draft: IPostsState) => {
    draft.fetchingPostAds = false;
    draft.fetchedPostAds = false;
    draft.fetchedPostAdsFail = true;
  }),
  [CLEAR_POST_ADS]: produce((draft: IPostsState) => {
    draft.ads = [];
    draft.fetchedPostAds = false;
    draft.fetchingPostAds = false;
    draft.fetchedPostAdsFail = false;
  }),
});
