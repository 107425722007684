import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Avatar } from "antd";
import { ReplyPhrase } from "./ReplyPhrase";
import { ReplyDateTime } from "./ReplyDateTime";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import Attachments from "../../CommentCard/cardBody/attachment/Attachment";
import CustomTagsList from "../../CommentCard/customTags/CustomTagsList";
import CommentTagsList from "../../CommentCard/commentTags/CommentTagsList";
import BrandBastionTags from "../../CommentCard/brandBastionTags/BBTags";
import CustomTagsDropdown from "../../CommentCard/customTags/CustomTagsDropdown";
import AiHighlightTags from "../../CommentCard/aiHighlightTags/AiHighlightTags";

import { deleteCommentTagAction, postCommentTagAction } from "@store/commentTags/actions";
import { canManageBBTagsSelector } from "@store/me/selectors";
import { deleteCustomTagFromCommentAction } from "@store/customTags/actions";

import { getInitials } from "../helpers";
import { ICommentTag } from "@store/comments/types";
import { isStoryAttachment } from "@utils/comments";
import { getStoryPreviewMessage } from "./PrivateMessageCard.helpers";
import { AVATAR_SIZE, IPrivateMessageCardProps } from "./PrivateMessageCard.type";
import { useReplyModalContext } from "@containers/ReplyModal/ReplyModalContext";
import { PublicToPrivateReplyDisclaimer } from "../../Reply/publicToPrivateReplyDisclaimer";

import audioStyles from "./../../CommentCard/cardBody/attachment/variants/AudioAttachment.module.less"; // eslint-disable-line max-len
import s from "./PrivateMessageCard.module.less";

import { WarningIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const PrivateMessageCard: FC<IPrivateMessageCardProps> = ({
  id,
  date,
  author,
  message,
  parentId,
  isHidden,
  avatarSrc,
  isOwned,
  replyPhrase,
  createdTime,
  attachments = [],
  messageTranslated = "",
  publicToPrivateCommentId,
  tags,
  aiTags,
  customTagsByUser,
  customTagsByAutomation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canManageBBTag = useSelector(canManageBBTagsSelector);

  const { isMessagesTranlated } = useReplyModalContext();

  const authorExists = !!author;
  const type = isOwned ? "highlighted" : "default";
  const position = isOwned ? "right" : "left";
  const messageLeft = position === "left";
  const messageRight = position === "right";
  const hasAttachments = !!attachments.length;
  const isFallbackAttachment = attachments?.[0]?.type === "fallback";
  const isUnsupportedMediaAttachment = attachments?.[0]?.description === "unsupported_media";
  const isMessageStoryAttachment = hasAttachments && isStoryAttachment(attachments[0].description);
  const hasTags =
    !!tags?.length ||
    !!aiTags?.length ||
    !!customTagsByUser?.length ||
    !!customTagsByAutomation?.length;

  const handleDeleteCustomTag = (tagId?: string) => {
    if (tagId) {
      dispatch(
        deleteCustomTagFromCommentAction({
          id: tagId,
          comment_id: id,
          parent_comment_id: parentId,
        }),
      );
    }
  };

  const handleDeleteBBTag = (tagId: string) => {
    dispatch(
      deleteCommentTagAction({
        tagId,
        commentId: id,
        parentCommentId: parentId,
      }),
    );
  };

  const handleAddBBTag = (tag: ICommentTag) => {
    dispatch(
      postCommentTagAction({
        tagId: tag.id,
        commentId: id,
        parentCommentId: parentId,
      }),
    );
  };

  const userAvatar = (
    <Avatar src={avatarSrc} size={AVATAR_SIZE} className={s.bbPrivateMessageCardUser}>
      {!avatarSrc && author ? getInitials(author) : undefined}
    </Avatar>
  );

  const getMessageWithAttachment = () => {
    const attachment = attachments[0];

    return (
      <>
        {isFallbackAttachment ? (
          <div>
            <Tooltip title={t("components:reply:fallbackLinkTooltip")}>
              <span
                className={s.bbPrivateMessageCardFallbackAttachment}
                onClick={() => window.open(attachment.url, "_blank")}
              >
                {attachment.url}
              </span>
            </Tooltip>

            {` ${message}`}
          </div>
        ) : (
          <>
            {isMessageStoryAttachment && (
              <span className={s.bbStoryPreviewMessage}>
                {getStoryPreviewMessage(attachments[0].description, !!isOwned)}
              </span>
            )}
            <Attachments
              createdTime={createdTime}
              attachments={attachments || []}
              isStoryAttachment={isMessageStoryAttachment}
              className={s[`bbMessageCardAttachment-${position}`]}
              audioClassName={classNames(
                s.bbPrivateMessageContentRegular,
                s[`bbMessageCardTextual-${type}`],
                s[`bbMessageCardPosition-${position}`],
                audioStyles[`bbAudioTrack-${position}`],
              )}
            />
          </>
        )}
        {isMessageStoryAttachment && !!message.trim() && (
          <div
            className={classNames(
              s.bbPrivateMessageContent,
              s.bbPrivateMessageContentRegular,
              s[`bbMessageCardPosition-${position}`],
              s[`bbMessageCardTextual-${type}`],
            )}
          >
            {message}
          </div>
        )}
      </>
    );
  };

  const renderPublicToPrivateDisclaimer = (commentId: string) => (
    <PublicToPrivateReplyDisclaimer
      commentId={commentId}
      text={t("components:reply:replyPrivately:disclaimerResponded")}
    />
  );

  return (
    <>
      {!!publicToPrivateCommentId
        ? renderPublicToPrivateDisclaimer(publicToPrivateCommentId)
        : null}
      <div
        data-testid="message-card"
        className={classNames("flex flex-col", s[`bbPrivateMessageCard-${position}`], {
          [s.bbMessageCardOffsetLeft]: !authorExists && messageLeft,
          [s.bbMessageCardOffsetRight]: !authorExists && messageRight,
        })}
      >
        <div
          className={classNames(
            s.bbPrivateMessageContainer,
            s[`bbPrivateMessageContainer-${position}`],
          )}
        >
          {authorExists && messageLeft ? userAvatar : null}
          <div>
            <div className="flex items-center">
              <div
                className={classNames(
                  s.bbPrivateMessageContent,
                  s[`bbMessageCardPosition-${position}`],
                  s[`bbMessageCardTextual-${type}`],
                  {
                    [s.bbPrivateMessageContentTransparent]:
                      hasAttachments && !isFallbackAttachment && !isUnsupportedMediaAttachment,
                    [s.bbPrivateMessageContentRegular]: !(
                      hasAttachments &&
                      !isFallbackAttachment &&
                      !isUnsupportedMediaAttachment
                    ),
                  },
                )}
              >
                <p data-testid="message">
                  {isMessagesTranlated && messageTranslated ? (
                    messageTranslated
                  ) : hasAttachments && attachments[0].description === "unsupported_media" ? (
                    <> </>
                  ) : hasAttachments ? (
                    getMessageWithAttachment()
                  ) : (
                    message
                  )}
                </p>
                <ReplyPhrase
                  replyPhrase={replyPhrase}
                  className={hasAttachments ? s.bbAttachmentReplyPhrase : undefined}
                />
              </div>
              {!isOwned && (
                <div className="ml-2 flex items-center">
                  <CustomTagsDropdown
                    commentId={id}
                    parentCommentId={parentId}
                    hasTags={!!customTagsByUser || !!customTagsByAutomation}
                    customTagsAutomated={customTagsByAutomation || []}
                    customTagsByUser={customTagsByUser || []}
                    type="custom"
                  />
                  {canManageBBTag && (
                    <div className="ml-1">
                      <BrandBastionTags
                        tags={tags || []}
                        onAddTag={handleAddBBTag}
                        onDeleteTag={handleDeleteBBTag}
                        type="custom"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {authorExists && position === "right" ? userAvatar : null}
        </div>

        {hasAttachments && attachments[0].description === "unsupported_media" && (
          <div
            className={classNames(s.bbPrivateMessageError, {
              [s.bbPrivateMessageErrorLeft]: messageLeft,
              [s.bbPrivateMessageErrorRight]: messageRight,
            })}
          >
            <WarningIcon />
            {t("components:reply:unsupportedMedia")}
          </div>
        )}

        {!isOwned && hasTags && (
          <div
            className={classNames(s.bbPrivateMessageCardTags, {
              [s.bbPrivateMessageCardTagsWithAuthor]: authorExists,
            })}
          >
            <CommentTagsList tags={tags || []} commentId={id} />

            <AiHighlightTags tags={aiTags} />

            <CustomTagsList
              customTagsAutomated={customTagsByAutomation}
              customTagsByUser={customTagsByUser}
              onDeleteTag={handleDeleteCustomTag}
            />
          </div>
        )}

        {authorExists && (
          <ReplyDateTime
            date={date}
            isHidden={isHidden}
            className={classNames(s[`bbPrivateMessageReplyDateTime-${position}`])}
            author={
              <>
                <span className="font-bold">{author}</span>
                {hasAttachments && attachments[0].description === "story_mention" && (
                  <>
                    <span>{t(`components:reply:mention_${isOwned ? "owned" : "not_owned"}`)}</span>
                    <span className={s.bbPrivateMessageReplyStory}>{t("generic:story")}</span>
                  </>
                )}
                {hasAttachments && attachments[0].description === "story_reply" && (
                  <span className="font-bold">
                    {t(`components:reply:storyReply_${isOwned ? "owned" : "not_owned"}`)}
                  </span>
                )}
              </>
            }
          />
        )}
      </div>
    </>
  );
};
