import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";

import s from "./Breakdown.module.less";

import { Languages } from "@utils/languages";
import { Countries } from "@utils/countries";
import { PostTypeIcon, PlatformTypeIcon } from "./platformTypes";
import { Tooltip, IAxisTick } from "@bbdevcrew/bb_ui_kit_fe";
import {
  ICommentBreakDownItem,
  ICommentBreakdownUnit,
  IHiddenBreakDownItem,
  IHiddenBreakdownUnit,
} from "@store/dashboard/types";

interface IChartLabelProps {
  type: string;
  tooltipLabel: string;
  chartLabelWidth: number;
  breakdownUnit: ICommentBreakdownUnit | IHiddenBreakdownUnit;
  labelTickProps: IAxisTick;
}

const ChartLabel: FC<IChartLabelProps> = ({
  type,
  tooltipLabel,
  breakdownUnit,
  labelTickProps,
  chartLabelWidth,
}) => {
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const labelMargin = 12;
  const maxTextLength = screens.md ? 40 : 20;
  const itemId = labelTickProps.payload.value;
  const items = breakdownUnit.items as (ICommentBreakDownItem | IHiddenBreakDownItem)[];
  const item = items.find(unit => unit.id === itemId);

  const tooltipTitle = `${t("components:breakdown:filter")} ${tooltipLabel?.toLowerCase()}`;

  const additionalText =
    item && item?.label.length > maxTextLength
      ? item?.label.substr(0, maxTextLength).concat("...")
      : item?.label;

  const getBreakdownChartIcon = (chartType: string, value: string) => {
    let Icon = "";

    switch (chartType) {
      case "platform_type":
        Icon = PlatformTypeIcon[value as keyof typeof PlatformTypeIcon];
        break;
      case "post_type":
        Icon = PostTypeIcon[value as keyof typeof PostTypeIcon];
        break;
      case "language":
        Icon = Languages[value as keyof typeof Languages];
        break;
      case "ad_targeting":
        Icon = Countries[value as keyof typeof Countries];
        break;
    }
    return <>{Icon ? <Icon /> : null}</>;
  };

  const showDetails = () => {
    return ["asset_name", "campaign_name", "adset_name", "ad_name"].includes(type);
  };

  return (
    <Tooltip placement="topLeft" title={tooltipTitle}>
      <foreignObject
        x={5}
        y={labelTickProps.y - labelMargin}
        cursor="pointer"
        className={s.bbBreakdownChartLabel}
        style={{ width: chartLabelWidth }}
      >
        <div className={s.bbBreakdownChartLabelContainer}>
          <div className={s.bbBreakdownChartLabelContent}>
            <g transform={`translate(${labelTickProps.x},${labelTickProps.y - labelMargin})`}>
              <div style={{ width: chartLabelWidth }}>
                {getBreakdownChartIcon(type, itemId.toLowerCase())}
                <span>
                  {type === "ad_targeting" ? item?.id + " - " : null}
                  {additionalText}
                </span>
              </div>

              {showDetails() ? (
                <tspan textAnchor="left" x="0" dy="20" className={s.bbDetails}>
                  {item?.id}
                  {item?.details?.platform_type
                    ? getBreakdownChartIcon("platform_type", item?.details?.platform_type)
                    : null}
                  {item?.details?.asset_type}
                </tspan>
              ) : null}
            </g>
          </div>
        </div>
      </foreignObject>
    </Tooltip>
  );
};

export default ChartLabel;
