import { useSelector } from "react-redux";
import React, { FC, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import AppLayout from "@components/_common/AppLayout/AppLayout";

import { managedAppFeature, meSelector } from "@store/me/selectors";

import { IMenuItem } from "@components/_common/AppHeader/AppHeader.type";
import { IRestrictedRoutesProps } from "./Routes.type";
import { ManagedSubFeatureComponents } from "./helpers";
import { useRoutes } from "@utils/useRoutes";

const ManagedRoutes: FC<IRestrictedRoutesProps> = ({ menu, path }) => {
  const { extraMenuItems, redirectCallback } = useRoutes(path);

  const me = useSelector(meSelector);
  const feature = useSelector(managedAppFeature);

  const subfeatures = useMemo(() => feature?.subfeatures || [], [feature]);

  return (
    <AppLayout
      hasFilters
      basename="managed"
      extra={extraMenuItems}
      avatarUrl={me?.client?.logo}
      defaultSelectedKey="transparency"
      menu={menu as unknown as IMenuItem[]}
      selectedKey={(path && path.replace(/\//g, "")) || "transparency"}
    >
      {{
        content: (
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={{
                    pathname: subfeatures.length ? subfeatures[0].id : "transparency",
                    search: window.location.search,
                  }}
                />
              }
            />
            {subfeatures.length
              ? subfeatures?.map(({ id, is_disabled }) => {
                  const RouteComponent =
                    ManagedSubFeatureComponents[id as keyof typeof ManagedSubFeatureComponents] ||
                    null;

                  return (
                    <Route
                      key={id}
                      path={id}
                      element={
                        <PrivateRoute isDisabled={is_disabled}>
                          <RouteComponent />
                        </PrivateRoute>
                      }
                    />
                  );
                })
              : null}
            <Route
              path="unauthorized"
              element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
            />
            <Route path="*" element={<NotFoundView redirectCallback={redirectCallback} />} />
          </Routes>
        ),
      }}
    </AppLayout>
  );
};

export default ManagedRoutes;
