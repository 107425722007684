import {
  GET_LISTEN_PROJECTS,
  GET_LISTEN_PROJECTS_SUCCESS,
  GET_LISTEN_PROJECTS_FAILURE,
  GET_LISTEN_PROJECT_COMMENTS,
  GET_LISTEN_PROJECT_COMMENTS_SUCCESS,
  GET_LISTEN_PROJECT_COMMENTS_FAILURE,
} from "./actionTypes";
import { createAction } from "typesafe-actions";
import {
  IListenProjectsResponse,
  IListenProjectCommentsPayload,
  IListenProjectCommentsResponse,
} from "./types";

export const getListenProjectsAction = createAction(GET_LISTEN_PROJECTS)<void>();
export const getListenProjectsSuccessAction = createAction(
  GET_LISTEN_PROJECTS_SUCCESS,
)<IListenProjectsResponse>();
export const getListenProjectsFailureAction = createAction(GET_LISTEN_PROJECTS_FAILURE)<void>();

export const getListenProjectCommentsAction = createAction(
  GET_LISTEN_PROJECT_COMMENTS,
)<IListenProjectCommentsPayload>();
export const getListenProjectCommentsSuccessAction = createAction(
  GET_LISTEN_PROJECT_COMMENTS_SUCCESS,
)<IListenProjectCommentsResponse>();
export const getListenProjectCommentsFailureAction = createAction(
  GET_LISTEN_PROJECT_COMMENTS_FAILURE,
)<void>();
