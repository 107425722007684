import React from "react";
import { useTranslation } from "react-i18next";

import { Col, ColProps, Row } from "antd";

import { InformationIcon, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { PerformanceCard } from "../../../_common/performanceCard";

import { IEngagementsProps } from "./EngagementCard.type";

import s from "./EngagementCard.module.less";

const EngagementTrends: React.FC<IEngagementsProps> = props => {
  const { t } = useTranslation();
  const { engagement, type, icon, isLoading, onClick } = props;

  const getEngagementTitle = () => {
    return (
      <React.Fragment>
        {t(`components:engagementsTrends:${type}:title`)}
        <Tooltip title={t(`components:engagementsTrends:${type}:tooltip`)}>
          <span className={s.bbEngagementCardTooltipIcon}>
            <InformationIcon />
          </span>
        </Tooltip>
      </React.Fragment>
    );
  };

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
      <PerformanceCard
        size="sm"
        isLoading={isLoading}
        onClick={onClick}
        type={type}
        title={getEngagementTitle()}
        icon={icon}
        data={engagement}
      />
    </Col>
  );
};

export const EngagementCardsWrapper: React.FC<ColProps> = ({ children, xs, sm, md, lg, xl }) => {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Row gutter={[10, 10]}>{children}</Row>
    </Col>
  );
};

export default EngagementTrends;
