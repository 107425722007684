import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Empty } from "antd";
import { Card, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../../_common/pieChartWidget/PieChartWidget";

import s from "./ReviewsWidget.module.less";

import { normalizeReviewWidgetData } from "./ReviewWidget.helpers";
import { IReviewsWidgetDistributionProps } from "./ReviewsWidget.types";

export const ReviewsDistribution: FC<IReviewsWidgetDistributionProps> = ({
  data = [],
  loading,
}) => {
  const { t } = useTranslation();

  const normalizedPlatformData = normalizeReviewWidgetData(data);

  return (
    <Col xs={24} lg={18}>
      <Card
        className={s.bbCard}
        headerTitle={t("components:reviewWidget:distribution:headerTitle")}
        headerSubTitle={t("components:reviewWidget:distribution:headerSubTitle")}
      >
        {loading ? (
          <div className={s.bbReviewWidgetLoading}>
            <Loading isLoading />
          </div>
        ) : normalizedPlatformData?.length ? (
          <PieChartWidget
            loading={false}
            sizes={[230, 230]}
            radiuses={[85, 110]}
            chartData={normalizedPlatformData.filter(unit => unit.value !== 0)}
            legendClassName={s.bbReviewLegend}
            label={t("generic:reviews")}
            emptyLabel={t("components:empty:description")}
          />
        ) : (
          <Empty
            className={s.bbReviewWidgetEmpty}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("components:empty:description")}
          />
        )}
      </Card>
    </Col>
  );
};
