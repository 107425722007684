import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";

import { Grid } from "antd";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";
import { InputWhite, UserAvatar, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";

import {
  usersSelector,
  getUsersPendingSelector,
  getUsersSuccessfulSelector,
} from "@store/users/selectors";
import { getUsersAction } from "@store/users/actions";

import s from "./AssignUser.module.less";

import { IUser } from "@store/users/types";
import { unAssignedOption } from "./helper";
import { IAssignUserProps } from "./AssignUser.type";
import { useSearch, handleMultiselectState } from "@bbdevcrew/bb_ui_kit_fe";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

export const AssignUser = ({ value = [], customprops }: IAssignUserProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const [assignedUsers, setAssignedUsers] = useState<string[]>(value);
  const [options, setOptions] = useState<IUser[]>([]);

  const users = useSelector(usersSelector);
  const usersFetching = useSelector(getUsersPendingSelector);
  const usersFetched = useSelector(getUsersSuccessfulSelector);

  const isUnassignedOpt = (id: string) => id === "unassigned";

  const transformedUsers = useMemo(() => {
    return (options || []).map(({ id, first_name, last_name, avatar, is_disabled }) => ({
      id,
      label: `${first_name} ${last_name} ${
        is_disabled ? `(${t("generic:disabled")})`.toUpperCase() : ""
      }`,
      iconPosition: "left" as const,
      disabled: is_disabled,
      icon: !isUnassignedOpt(id) && (
        <UserAvatar
          img={avatar}
          lastName={last_name}
          firstName={first_name}
          className={s.bbUserAvatar}
        />
      ),
    }));
  }, [options, t]);

  const { options: searchedOptions, SearchInput } = useSearch(
    transformedUsers,
    t("generic:search"),
  );

  useEffect(() => {
    if (users) setOptions([unAssignedOption, ...users]);
  }, [users]);

  const _onChange = (changedValue: string) => {
    const user = options.find(({ id }) => id === changedValue);

    if (!assignedUsers.includes(changedValue) && user?.is_disabled) {
      return;
    }

    const newState = handleMultiselectState(searchedOptions, assignedUsers, changedValue);

    customprops?.form?.setFieldsValue({ assigned_users: newState });

    setAssignedUsers(newState);
  };

  useEffect(() => {
    if (!usersFetched && !usersFetching) dispatch(getUsersAction());
  }, [usersFetched, usersFetching, dispatch]);

  const _onClear = () => {
    customprops?.form?.setFieldsValue({ assigned_users: [] });
    setAssignedUsers([]);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!assignedUsers.length) {
      return ClearableTrigger(tooltipProps);
    }
    const tooltipElement = transformedUsers
      .filter(user => assignedUsers.includes(user.id))
      .map(_user => (
        <span key={_user.id} className={s.bbDisplayBlock}>
          {_user.label}
        </span>
      ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <SelectStyledMulti
      _size="sm"
      id="assign-users"
      onChange={_onChange}
      value={assignedUsers}
      options={searchedOptions}
      InputVariation={InputWhite}
      TriggerVariation={TriggerWithTooltip}
      DropdownHeader={SearchInput}
      dropdownPlacement="bottomRight"
      placeholder={t("generic:all")}
      excludePlaceholder={t("generic:exclude")}
      excludedPlaceholder={t("generic:excluded")}
      dropdownMatchSelectWidth={!screens.md}
      dropdownMenuClassName={s.bbAssignUserDropdownMenu}
    />
  );
};
