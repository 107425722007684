import { jsPDF } from "jspdf";

// a4 measurements in mm are 210 x 297
export const PDF_MM_WIDTH = 297;
export const PDF_MM_HEIGHT = 210;
export const PAGE_PADDING_LEFT = 18;
export const PAGE_MARGIN_BOTTOM = 10;
export const CANVAS_SETTINGS = {
  allowTaint: true,
  useCORS: true,
  backgroundColor: "rgba(0,0,0,0)",
  windowWidth: 1600,
  scale: 2,
};

const getCanvasDimentions = (canvas: HTMLCanvasElement) => {
  const width = PDF_MM_WIDTH - PAGE_PADDING_LEFT * 2;
  const height = (canvas.height * width) / canvas.width;

  return { width, height };
};

export const fillBackground = (canvas: HTMLCanvasElement, pdf: jsPDF) => {
  pdf.setFillColor("#eff2f7");
  pdf.rect(0, 0, canvas.width, canvas.height, "F");
};

export const drawSlideTitle = (
  pdf: jsPDF,
  title: string,
  chipTitle: string,
  chipWidth?: number,
) => {
  pdf.setDrawColor("#D2E0FB");
  pdf.setFillColor("#D2E0FB");
  pdf.roundedRect(18, 28, chipWidth || 24, 7, 3, 3, "FD");

  pdf.setTextColor("#082663");
  pdf.setFontSize(12);
  pdf.text(chipTitle, 21, 33);

  pdf.setTextColor("#000");
  pdf.setFontSize(32);
  pdf.text(title, 45, 35);
};

export const addSnapshotToCanvas = (pdf: jsPDF, canvas: HTMLCanvasElement, imageY: number) => {
  const { width, height } = getCanvasDimentions(canvas);
  // Adjust image height to fit within PDF page height
  const imgHeight =
    height > PDF_MM_HEIGHT - imageY ? PDF_MM_HEIGHT - imageY - PAGE_MARGIN_BOTTOM : height;

  const imgData = canvas.toDataURL("img/png");
  pdf.addImage(imgData, "PNG", PAGE_PADDING_LEFT, imageY, width, imgHeight);
};
