import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import s from "./AppFooter.module.less";

interface AppFooter {
  isFixed?: boolean;
}

const AppFooter: FC<AppFooter> = ({ isFixed }) => {
  const { t } = useTranslation();

  return (
    <footer
      className={classNames(s.bbAppFooter, {
        [s.bbAppFooterFixed]: isFixed,
      })}
    >
      {t("components:footer:copyrightText", {
        year: new Date().getFullYear(),
      })}
      <span className={s.bbCompanyLink}>
        <a
          href="https://www.brandbastion.com/intelligence"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("components:footer:companyName")}
        </a>
      </span>
      {" • "}
      <span>
        <a href="mailto:help@brandbastion.com">{t("components:footer:support")}</a>
      </span>
    </footer>
  );
};

export default AppFooter;
