import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Menu } from "antd";
import { Chip, Icon, Button, Tooltip, Dropdown } from "@bbdevcrew/bb_ui_kit_fe";
import DeleteConfirmation from "@components/_common/DeleteConfirmation/DeleteConfirmation";

import s from "./ItemCard.module.less";

import { ICON_MAPPING } from "./helpers";
import { IItemCardProps, IItemAction } from "./types";

import { MoreActionsIcon } from "@assets/index";

const ItemCard: React.FC<IItemCardProps> = ({
  name,
  message,
  isDefault,
  tooltip,
  actions = [],
  tags,
  onItemClick,
}) => {
  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<IItemAction>();

  const renderActions = () => {
    if (!actions.length) {
      return null;
    }

    const handleClick = (action: IItemAction) => {
      if (action.confirmationText) {
        setConfirmationAction(action);
      } else {
        action.onActionClick();
      }
      setIsDropdownOpen(false);
    };

    return (
      <div className={s.bbModalItemCardContainerAction}>
        <Dropdown
          open={isDropdownOpen}
          menuClassName={s.bbModalItemCardContainerActionMenu}
          setOpen={open => setIsDropdownOpen(open)}
          trigger={
            <Button
              _type="link"
              className={s.bbModalItemCardContainerActionTrigger}
              onClick={() => setIsDropdownOpen(true)}
            >
              <Icon _size="sm" icon={MoreActionsIcon} />
            </Button>
          }
        >
          <Menu>
            {actions.map(action => {
              return (
                <Menu.Item
                  key={action.key}
                  icon={ICON_MAPPING[action.key]}
                  onClick={() => {
                    handleClick(action);
                  }}
                >
                  {t(action.label)}
                </Menu.Item>
              );
            })}
          </Menu>
        </Dropdown>
      </div>
    );
  };

  return (
    <div className={s.bbModalItemCard}>
      <div
        className={classnames(s.bbModalItemCardContainer, {
          [s.bbModalItemCardContainerClickable]: onItemClick,
        })}
      >
        <Tooltip title={tooltip} mouseEnterDelay={0.2}>
          <div
            onClick={() => onItemClick && onItemClick(message || name)}
            className={s.bbModalItemCardContainerBody}
          >
            <div className={s.bbModalItemCardContainerBodyTitle}>
              {name}
              {isDefault && (
                <span className={s.bbModalItemCardContainerBodyDefaultChip}>
                  {t("generic:default")}
                </span>
              )}
            </div>
            {message && <div className={s.bbModalItemCardContainerBodyDescription}>{message}</div>}
            {!!tags?.length && (
              <div className={s.bbModalItemCardTags}>
                {tags.map(({ id, label }) => (
                  <Chip key={id} _size="xs" text={label} _type="white" />
                ))}
              </div>
            )}
          </div>
        </Tooltip>

        {renderActions()}
      </div>
      {confirmationAction?.confirmationText && (
        <DeleteConfirmation
          message={confirmationAction.confirmationText}
          onConfirm={() => confirmationAction?.onActionClick()}
          onCancel={() => setConfirmationAction(undefined)}
        />
      )}
    </div>
  );
};

export default ItemCard;
