import { createReducer, ActionType } from "typesafe-actions";
import { ISearchState, IDataSearchProps, IComment } from "./types";
import { postSearchActionSuccess, translateSearchComment } from "./actions";
import {
  POST_SEARCH,
  POST_SEARCH_SUCCESS,
  POST_SEARCH_FAILURE,
  TRANSLATE_SEARCH_COMMENT,
  RESET_SEARCH_DATA,
} from "./actionTypes";

const initialState = {
  data: {} as IDataSearchProps,
  aggregations: {},
  isOverWrite: false,
  fetchingSearch: false,
  fetchedSearch: false,
  fetchedSearchFail: false,
  fetchingPostSearchAggregations: false,
  fetchedPostSearchAggregations: false,
  fetchedPostSearchAggregationsFail: false,
};

export const searchReducer = createReducer<ISearchState>(initialState, {
  [POST_SEARCH]: (state: ISearchState) => ({
    ...state,
    fetchingSearch: true,
    fetchedSearch: false,
    fetchedSearchFail: false,
  }),
  [POST_SEARCH_SUCCESS]: (
    state: ISearchState,
    action: ActionType<typeof postSearchActionSuccess>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingSearch: false,
    fetchedSearch: true,
    fetchedSearchFail: false,
  }),
  [POST_SEARCH_FAILURE]: (state: ISearchState) => ({
    ...state,
    fetchingSearch: false,
    fetchedSearch: false,
    fetchedSearchFail: true,
  }),
  [TRANSLATE_SEARCH_COMMENT]: (
    state: ISearchState,
    action: ActionType<typeof translateSearchComment>,
  ) => {
    if (!action.payload.isParent) {
      return {
        ...state,
        data: {
          ...state.data,
          comments: Object.assign([], {
            ...state.data.comments,
            [action.payload.index]: {
              ...state.data.comments[action.payload.index],
              is_translated: action.payload.isParent
                ? state.data.comments[action.payload.index].is_translated
                : !state.data.comments[action.payload.index].is_translated,
            },
          }),
        },
      };
    } else if (state.data.comments[action.payload.index].parent && action.payload.isParent) {
      return {
        ...state,
        data: {
          ...state.data,
          comments: Object.assign([], {
            ...state.data.comments,
            [action.payload.index]: {
              ...state.data.comments[action.payload.index],
              parent: {
                ...(state.data.comments[action.payload.index].parent as IComment),
                is_translated: !(state.data.comments[action.payload.index].parent as IComment)
                  .is_translated,
              },
            },
          }),
        },
      };
    } else {
      return {
        ...state,
        fetchingSearch: false,
        fetchedSearch: false,
        fetchedSearchFail: false,
      };
    }
  },
  [RESET_SEARCH_DATA]: (state: ISearchState) => ({
    ...state,
    fetchingSearch: false,
    fetchedSearch: false,
    fetchedSearchFail: false,
  }),
});
