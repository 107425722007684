import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Row, Col } from "antd";
import { PerformanceCard } from "../../_common/performanceCard";

import { ICommentEngagementCardsProps } from "./CommentEngagementCards.type";

import s from "./CommentEngagementCards.module.less";

import { IPerformanceUnit } from "../../_common/performanceCard/types";

import { ReplyIcon } from "@assets/index";
import { EyeOffFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

const CommentEngagementCards: FC<ICommentEngagementCardsProps> = ({ loading, data }) => {
  const { t } = useTranslation();

  const titleTooltipProps = {
    title: t("components:commentEngagementTrends:repliesIssuedTooltip"),
    style: {
      width: 135,
    },
  };

  const getEngagementDataByType = (type: string) =>
    data.find(({ id }) => id === type) as IPerformanceUnit;

  return (
    <Col xs={24}>
      <div
        id="comment-engagement-cards"
        data-cy="comment-engagements-container"
        className={s.CommentEngagementCardsContainer}
      >
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <PerformanceCard
              size="sm"
              type="total"
              icon={<EyeOffFilledIcon />}
              isLoading={loading}
              data={getEngagementDataByType("conversations_hidden")}
              title={t("components:commentEngagementTrends:commentsHidden")}
            />
          </Col>
          <Col xs={24} md={12}>
            <PerformanceCard
              size="sm"
              type="total"
              icon={<ReplyIcon />}
              isLoading={loading}
              data={getEngagementDataByType("replies_issued")}
              title={t("components:commentEngagementTrends:repliesIssued")}
              titleTooltipProps={titleTooltipProps}
            />
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default CommentEngagementCards;
