import React from "react";
import { useTranslation } from "react-i18next";

import s from "./AppHeader.module.less";

import { IClientPlan } from "@store/me/types";
import { getPlanIcon } from "./helper";

interface IClinetPlanBadgeProps {
  type: IClientPlan;
}

const ClinetPlanBadge: React.FC<IClinetPlanBadgeProps> = ({ type }) => {
  const { t } = useTranslation();
  return (
    <div
      className={s.bbAppHeaderChipPlan}
      data-stonly-target={`app-global__header--${
        type.id === "GROWTH_SEMI" ? "growth" : type.id?.toLowerCase()
      }`}
    >
      <div className={s.bbAppHeaderChipPlanIcon}>{getPlanIcon(type.id)}</div>
      <span>{t(`header:clientPlanBadge:${type.id.toLowerCase()}`)}</span>
    </div>
  );
};

export default ClinetPlanBadge;
