import { i18n } from "i18next";
import Animate from "rc-animate";
import React, { FC, MouseEvent } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";

import { Row, Col, Result } from "antd";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./NotFound.module.less";

import { ResultStatusType } from "antd/lib/result";
import { getI18next } from "../../languages/i18next";

interface INotFoundViewProps {
  type?: ResultStatusType;
  redirectCallback?: () => void;
}

const i18nextInstance = getI18next() as i18n;

const NotFoundView: FC<INotFoundViewProps> = ({ redirectCallback, type = "404" }) => {
  const { t } = useTranslation();

  const backToHome = (e: MouseEvent) => {
    e.preventDefault();
    redirectCallback?.();
  };

  return (
    <I18nextProvider i18n={i18nextInstance}>
      <div className={s.bbRootNotFoundPage}>
        <Row justify="center" align="middle">
          <Col
            xs={{
              span: 22,
              offset: 1,
            }}
          >
            <Animate transitionName="fade" transitionAppear>
              <div className={s.bbContainerNotFound}>
                <Row justify="center" align="middle">
                  <Col>
                    <Result
                      title={type}
                      status={type}
                      subTitle={t(`pages:${type}:message`)}
                      extra={
                        <Button className="mt-5" onClick={backToHome}>
                          {t(`pages:${type}:button`)}
                        </Button>
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Animate>
          </Col>
        </Row>
      </div>
    </I18nextProvider>
  );
};

export default NotFoundView;
