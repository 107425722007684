/* eslint-disable max-len */
import React from "react";

import { i18n } from "i18next";
import { goToPath } from "./helpers";
import { getI18next } from "../../../../languages/i18next";
import { IExplorerAgentConfig } from "./AgentPerformance.type";
import { CommentIcon, ReplyIcon, TagIcon, TimeIcon, UserCommentIcon } from "@assets/index";

export const i18nextInstance = getI18next() as i18n;

export const engageConfig: IExplorerAgentConfig = {
  sectionDivider: {
    icon: <CommentIcon />,
    title: i18nextInstance.t("pages:overviewSections:care:title"),
    description: i18nextInstance.t("pages:overviewSections:care:description"),
    action: i18nextInstance.t("pages:overviewSections:care:action"),
    onActionClick: () => goToPath("/care"),
  },
  performanceWidgets: [
    {
      type: "total",
      title: i18nextInstance.t("components:agentPerformance:repliesIssued:title"),
      titleTooltipProps: {
        title: i18nextInstance.t("components:agentPerformance:repliesIssued:titleTooltip"),
      },
      icon: <ReplyIcon />,
    },
    {
      type: "total",
      title: i18nextInstance.t("components:agentPerformance:responseNeeded:title"),
      icon: <TagIcon />,
    },
    {
      type: "total",
      title: i18nextInstance.t("components:agentPerformance:responseTime:title"),
      titleTooltipProps: {
        title: i18nextInstance.t("components:agentPerformance:responseTime:titleTooltip"),
        style: { width: 180 },
      },
      icon: <TimeIcon />,
    },
  ],
  userStats: {
    title: i18nextInstance.t("components:agentPerformance:responseIssuedAndTime:title"),
    subTitle: i18nextInstance.t("components:agentPerformance:responseIssuedAndTime:subTitle"),
    colors: ["#0049BE", "#A56EFF"],
    legends: [
      i18nextInstance.t("components:agentPerformance:responseIssuedAndTime:legend:repliesIssued"),
      i18nextInstance.t("components:agentPerformance:responseIssuedAndTime:legend:responseTime"),
    ],
  },
};

export const managedAgentsConfig: IExplorerAgentConfig = {
  sectionDivider: {
    icon: <UserCommentIcon />,
    title: i18nextInstance.t("pages:overviewSections:agents:title"),
    description: i18nextInstance.t("pages:overviewSections:agents:description"),
  },
  performanceWidgets: [
    {
      type: "total",
      title: i18nextInstance.t("components:agentPerformance:repliesIssued:titleManaged"),
      titleTooltipProps: {
        title: i18nextInstance.t("components:agentPerformance:repliesIssued:titleTooltip"),
      },
      icon: <ReplyIcon />,
    },
    {
      type: "total",
      title: i18nextInstance.t("components:agentPerformance:responseTime:titleManaged"),
      titleTooltipProps: {
        title: i18nextInstance.t("components:agentPerformance:responseTime:titleTooltip"),
        style: { width: 180 },
      },
      icon: <TimeIcon />,
    },
  ],
  userStats: {
    title: i18nextInstance.t("components:agentPerformance:responseIssuedAndTime:titleManaged"),
    subTitle: i18nextInstance.t("components:agentPerformance:responseIssuedAndTime:subTitle"),
    colors: ["#0049BE", "#A56EFF"],
    legends: [
      i18nextInstance.t("components:agentPerformance:responseIssuedAndTime:legend:repliesIssued"),
      i18nextInstance.t("components:agentPerformance:responseIssuedAndTime:legend:responseTime"),
    ],
  },
};
