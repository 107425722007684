import { createReducer, ActionType } from "typesafe-actions";
import { IAlertTypesState } from "./types";
import { getAlertTypesActionSuccess } from "./actions";
import { GET_ALERT_TYPES, GET_ALERT_TYPES_SUCCESS, GET_ALERT_TYPES_FAILURE } from "./actionTypes";

const initialState = {
  data: [],
  fetchingAlertTypes: false,
  fetchedAlertTypes: false,
  fetchedAlertTypesFail: false,
};

export const alertTypesReducer = createReducer<IAlertTypesState>(initialState, {
  [GET_ALERT_TYPES]: (state: IAlertTypesState) => ({
    ...state,
    fetchingAlertTypes: true,
    fetchedAlertTypes: false,
    fetchedAlertTypesFail: false,
  }),
  [GET_ALERT_TYPES_SUCCESS]: (
    state: IAlertTypesState,
    action: ActionType<typeof getAlertTypesActionSuccess>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingAlertTypes: false,
    fetchedAlertTypes: true,
    fetchedAlertTypesFail: false,
  }),
  [GET_ALERT_TYPES_FAILURE]: (state: IAlertTypesState) => ({
    ...state,
    fetchingAlertTypes: false,
    fetchedAlertTypes: false,
    fetchedAlertTypesFail: true,
  }),
});
