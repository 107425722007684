import { RootState } from "../";
import { getCurrentModuleName } from "@utils/paths";

export const fromComments = (state: RootState) => state.comments;

// Return "tab-specific" comments, or ALL comments
export const commentsSelector = (state: RootState) => {
  const allComments = fromComments(state).comments;

  switch (state.comments.replyType) {
    case "hidden":
      return allComments.filter(({ is_hidden }) => is_hidden);
    case "replied":
      return allComments.filter(({ reply }) => reply.is_sent);
    case "starred":
      return allComments.filter(({ reply }) => reply.is_starred);
    case "ignored":
      return allComments.filter(({ reply }) => reply.is_ignored);
    case "flagged":
      return allComments.filter(({ moderation }) => moderation.is_flagged);
    case "done":
      return allComments.filter(({ reply }) => reply.is_ignored || reply.is_sent);
    case "ai_highlights":
      return allComments.filter(({ reply }) => !reply.is_ignored && !reply.is_sent);
    case "ai_response_needed":
      return allComments.filter(({ reply, is_hidden }) => !(reply.is_ignored && is_hidden));
    case "ai_potentially_harmful":
      return allComments.filter(({ reply, is_hidden }) => !(reply.is_ignored && is_hidden));
    case "reviewed":
      return allComments.filter(({ moderation, is_hidden }) => moderation.is_reviewed || is_hidden);
    case "review":
      return allComments.filter(({ reply, moderation, is_hidden, tooltips }) => {
        const isCommentUpdated = !!tooltips?.edit;
        if (getCurrentModuleName() === "moderation") {
          return !moderation.is_reviewed && !is_hidden;
        } else if (!!state.me.data?.client?.auto_action_config?.archive_hidden) {
          return (!reply.is_sent || isCommentUpdated) && !reply.is_ignored && !is_hidden;
        } else return (!reply.is_sent || isCommentUpdated) && !reply.is_ignored;
      });
    default:
      return allComments;
  }
};

export const getAllCommentsSelector = (state: RootState) => fromComments(state).comments;

export const postCommentsSuccessfulSelector = (state: RootState) =>
  fromComments(state).fetchedPostComments;
export const commentsPageCursorSelector = (state: RootState) => fromComments(state).cursor;
export const fetchingCommentsSelector = (state: RootState) =>
  fromComments(state).fetchingPostComments;

export const getCommentByIdFetchingSelector = (state: RootState) =>
  fromComments(state).fetchingComment;
export const getCommentByIdSuccessfulSelector = (state: RootState) =>
  fromComments(state).fetchedComment;
export const getCommentByIdFailedSelector = (state: RootState) =>
  fromComments(state).fetchedCommentFail;
export const getCommentSelector = (state: RootState) => fromComments(state).comment;

export const commentsPageInfoSelector = (state: RootState) => fromComments(state).info;
export const commentsNavigationIdSelector = (state: RootState) => fromComments(state).navigation_id;

export const postUnhideCommentsPendingSelector = (state: RootState) =>
  fromComments(state).fetchingPostUnhideComments;
export const postUnhideCommentsSuccessfulSelector = (state: RootState) =>
  fromComments(state).fetchedPostUnhideComments;
export const postUnhideCommentsFailureSelector = (state: RootState) =>
  fromComments(state).fetchedPostUnhideCommentsFail;

export const getCommentsCSVSelector = (state: RootState) => fromComments(state).commentsCSV;
export const getCommentsCSVSelectorPending = (state: RootState) =>
  fromComments(state).fetchingCommentsCSV;
export const getCommentsCSVSelectorSuccessful = (state: RootState) =>
  fromComments(state).fetchedCommentsCSV;
export const getCommentsCSVSelectorFailure = (state: RootState) =>
  fromComments(state).fetchedCommentsCSVFail;

export const performingBatchActionsPendingSelector = (state: RootState) =>
  fromComments(state).performingBatchActions;
export const batchActionsPerformedSelector = (state: RootState) =>
  fromComments(state).performedBatchActions;
export const batchActionsFailedSelector = (state: RootState) =>
  fromComments(state).performedBatchActionsFailed;

export const triggerNextBatchSelector = (state: RootState) => fromComments(state).triggerNextBatch;
