import { action } from "typesafe-actions";

import {
  GET_DOCUMENT,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_URL,
  GET_DOCUMENT_URL_SUCCESS,
  GET_DOCUMENT_URL_FAILURE,
} from "./actionTypes";

import { IDocumentPayload, IDocumentUrlPayload } from "./types";

export const getDocumentsAction = () => action(GET_DOCUMENT);
export const getDocumentsSuccess = ({ items }: IDocumentPayload) => {
  return action(GET_DOCUMENT_SUCCESS, items);
};
export const getDocumentsFailure = () => action(GET_DOCUMENT_FAILURE);

export const getDocumentsUrlAction = (payload: string) => action(GET_DOCUMENT_URL, payload);
export const getDocumentsUrlSuccess = (data: IDocumentUrlPayload) => {
  return action(GET_DOCUMENT_URL_SUCCESS, data);
};
export const getDocumentsUrlFailure = () => action(GET_DOCUMENT_URL_FAILURE);
