export const POST_SEARCH = "SEARCH/POST_SEARCH";
export const POST_SEARCH_SUCCESS = "SEARCH/POST_SEARCH_SUCCESS";
export const POST_SEARCH_FAILURE = "SEARCH/POST_SEARCH_FAILURE";

export const TRANSLATE_SEARCH_COMMENT = "SEARCH/TRANSLATE_SEARCH_COMMENT";

export const UPDATE_ALERTS_NOTIFICATION = "SEARCH/UPDATE_ALERTS_NOTIFICATION";
export const UPDATE_ALERTS_NOTIFICATION_SUCCESS = "SEARCH/UPDATE_ALERTS_NOTIFICATION_SUCCESS";

export const RESET_SEARCH_DATA = "SEARCH/RESET_SEARCH_DATA";
