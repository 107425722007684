import classNames from "classnames";
import { useSelector } from "react-redux";
import React, { FC, useState, useEffect, MouseEvent } from "react";

import { Tooltip, ChipAction } from "@bbdevcrew/bb_ui_kit_fe";

import {
  deleteCustomTagFromCommentSuccessfulSelector,
  deletingCustomTagFromReplyIdSelector,
} from "@store/customTags/selectors";

import s from "./CustomTags.module.less";

import { LoadingOutlined } from "@ant-design/icons";
import { ICustomTag } from "../../TagsDropdown/TagsDropdown.type";
import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ICustomTagsListProps {
  // eslint-disable-next-line
  customAction?: FC<any>;
  customTagsByUser?: ICustomTag[];
  customTagsAutomated?: ICustomTag[];
  onDeleteTag?: (tagId: string) => void;
}

const CustomTagsList: FC<ICustomTagsListProps> = ({
  customTagsAutomated,
  customTagsByUser,
  customAction: CustomActionComponent,
  onDeleteTag,
}) => {
  const [deletingCustomTagId, setDeletingCustomTagId] = useState<string | null>(null);

  const customTagFromCommentDeleted = useSelector(deleteCustomTagFromCommentSuccessfulSelector);
  const customTagFromReplyDeletedId = useSelector(deletingCustomTagFromReplyIdSelector);

  const newlyAddedCustomTags = customTagsByUser?.filter(tag => tag.newlyAdded);
  const customTags = customTagsByUser?.filter(tag => !tag.newlyAdded);

  useEffect(() => {
    if (customTagFromCommentDeleted || customTagFromReplyDeletedId) {
      setDeletingCustomTagId(null);
    }
  }, [customTagFromCommentDeleted, customTagFromReplyDeletedId]);

  const deleteTag = (event: MouseEvent<HTMLButtonElement>, tagId?: string) => {
    event.preventDefault();

    if (tagId) {
      setDeletingCustomTagId(tagId);

      onDeleteTag?.(tagId);
    }
  };

  const renderActionIcon = (tag: ICustomTag) => {
    return (
      <>
        {!!CustomActionComponent ? (
          <CustomActionComponent tag={tag} />
        ) : !!onDeleteTag ? (
          <>
            {tag.id === deletingCustomTagId || !tag.id ? (
              <LoadingOutlined spin className={s.bbLoading} />
            ) : (
              <CloseIcon />
            )}
          </>
        ) : null}
      </>
    );
  };

  const renderTags = (tags: ICustomTag[]) => {
    return tags.map(tag => {
      const { id, label, tooltip } = tag;

      return (
        <Tooltip title={tooltip} key={id}>
          <span
            className={classNames(s.bbTagWrapper, {
              [s.bbTagWrapperWithTooltip]: tooltip,
            })}
          >
            <ChipAction
              key={id || label}
              _size="xs"
              _type="grey"
              text={label}
              actionIcon={renderActionIcon(tag)}
              onActionClick={
                !CustomActionComponent && !!onDeleteTag ? e => deleteTag(e, id) : undefined
              }
            />
          </span>
        </Tooltip>
      );
    });
  };

  return !!customTagsByUser?.length || !!customTagsAutomated?.length ? (
    <span className={s.bbCustomTagsList}>
      {!!customTags?.length && renderTags(customTags)}
      {!!customTagsAutomated?.length && renderTags(customTagsAutomated)}
      {!!newlyAddedCustomTags?.length && renderTags(newlyAddedCustomTags)}
    </span>
  ) : null;
};

export default CustomTagsList;
