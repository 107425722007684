import {
  getReplyCommentsSelector,
  fetchingReplyCommentsSelector,
  replyCommentsNavigationIdSelector,
  replyCommentsCursorSelector,
} from "@store/replies/selectors";
import {
  commentsSelector,
  fetchingCommentsSelector,
  commentsPageCursorSelector,
  commentsNavigationIdSelector,
} from "@store/comments/selectors";

export const REPLY_MODAL_COMMENTS_FETCH_LIMIT = 200;

export const COMMENTS_SELECTORS_MAP = {
  all_comments: {
    data: commentsSelector,
    loading: fetchingCommentsSelector,
    cursor: commentsPageCursorSelector,
    navigationId: commentsNavigationIdSelector,
  },
  comment_details: {
    data: getReplyCommentsSelector,
    loading: fetchingReplyCommentsSelector,
    cursor: replyCommentsCursorSelector,
    navigationId: replyCommentsNavigationIdSelector,
  },
};
