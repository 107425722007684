import React, { FC } from "react";
import classNames from "classnames";

import { Icon, Tooltip, UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import { PlatformUser } from "@store/comments/types";

import s from "./ReplyPhrase.module.less";

import { BbLogoIcon } from "@assets/index";

interface IReplyPhraseProps {
  phrase: string;
  tooltip?: string;
  platform_user?: PlatformUser;
}

const ReplyPhrase: FC<IReplyPhraseProps> = ({ phrase, tooltip, platform_user }) => {
  return (
    <p data-testid="message" className={s.bbReplyPhrase}>
      <Tooltip title={tooltip} placement="top">
        <span
          className={classNames(s.bbReplyPhraseIconWrapper, {
            [s.bbReplyPhraseIconWrapperWithTooltip]: !!tooltip,
          })}
        >
          {platform_user ? (
            <UserAvatar
              className={s.bbUserAvatar}
              img={platform_user?.avatar}
              lastName={platform_user?.last_name}
              firstName={platform_user?.first_name}
            />
          ) : (
            <Icon _size="lg" icon={BbLogoIcon} className={s.bbReplyPhraseIcon} />
          )}
          {phrase}
        </span>
      </Tooltip>
    </p>
  );
};

export default ReplyPhrase;
