import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col, Empty, Grid, Menu } from "antd";
import ReactResizeDetector from "react-resize-detector";
import React, { FC, useCallback, useState } from "react";

import {
  Card,
  Icon,
  Button,
  Loading,
  Dropdown,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import SectionDivider from "../OverviewSectionDivider";
import CommentsChart from "../../_common/commentsChart/CommentsChart";
import { PerformanceCard } from "../../_common/performanceCard";

import { getDashboardCSVAction } from "@store/dashboard/actions";

import s from "./AgentPerformance.module.less";

import {
  normalizePerformanceData,
  normalizeUserStatsTimeData,
  normalizeUserStatsCommentsData,
  getUserStatsCommentsLegend,
  getUserStatsTimeLegend,
} from "./helpers";
import { IAgentPerformanceWidgetsProps } from "./AgentPerformance.type";
import { AgentPerformanceReportType } from "@store/dashboard/types";

import { DownloadIcon } from "@assets/index";

const AgentPerformanceWidgets: FC<IAgentPerformanceWidgetsProps> = ({
  data,
  config,
  loading,
  filters,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const downloadCSV = useCallback(
    (report_type: AgentPerformanceReportType) => {
      dispatch(getDashboardCSVAction(filters, report_type));
      setIsDropdownOpen(false);
    },
    [dispatch, filters, setIsDropdownOpen],
  );

  const screens = Grid.useBreakpoint();

  return (
    <>
      <Col xs={24}>
        <SectionDivider
          icon={config.sectionDivider.icon}
          title={config.sectionDivider.title}
          description={config.sectionDivider.description}
          onActionButtonClick={config.sectionDivider.onActionClick}
          actionButtonText={
            config.sectionDivider.action ? (
              <>
                <span>{config.sectionDivider.action}</span>
                <ChevronRightIcon />
              </>
            ) : undefined
          }
        />
      </Col>
      <Col xs={24}>
        <Row gutter={[28, 28]} className={s.AgentPerformanceWidgetWrapper}>
          <>
            {config.performanceWidgets.map((widget, index) => (
              <Col md={24} lg={24 / config.performanceWidgets.length} key={index}>
                <PerformanceCard
                  size="sm"
                  type={widget.type}
                  icon={widget.icon}
                  title={widget.title}
                  isLoading={loading}
                  tooltipIconType="info"
                  data={normalizePerformanceData(data?.stats.items[index])}
                  titleTooltipProps={widget.titleTooltipProps}
                />
              </Col>
            ))}
            <Col xs={24}>
              <Card
                headerTitle={config.userStats.title}
                headerSubTitle={config.userStats.subTitle}
                headerAction={
                  <Dropdown
                    open={isDropdownOpen}
                    triggerMode="click"
                    setOpen={setIsDropdownOpen}
                    placement="bottomRight"
                    trigger={
                      <Button
                        className={classNames(s.bbAgentCSVExportButton, {
                          [s.bbAgentCSVExportButtonOpen]: isDropdownOpen,
                        })}
                      >
                        <DownloadIcon />
                        <Icon
                          _size="xs"
                          className="ml-2"
                          icon={isDropdownOpen ? ChevronUpIcon : ChevronDownIcon}
                        />
                      </Button>
                    }
                  >
                    <Menu className={s.bbAgentCSVExportMenu}>
                      <Menu.Item onClick={() => downloadCSV("summary")} icon={<DownloadIcon />}>
                        {t("components:agentPerformance:export:summary")}
                      </Menu.Item>
                      <Menu.Item onClick={() => downloadCSV("detailed")} icon={<DownloadIcon />}>
                        {t("components:agentPerformance:export:detailed")}
                      </Menu.Item>
                    </Menu>
                  </Dropdown>
                }
              >
                <div className={s.bbCommentsChart}>
                  {loading ? (
                    <div className={s.bbCommentsChartLoading}>
                      <Loading isLoading />
                    </div>
                  ) : data?.user_stats.items.length ? (
                    <Row gutter={[28, 28]}>
                      <Col lg={12} md={24}>
                        <ReactResizeDetector
                          handleWidth
                          refreshMode="throttle"
                          refreshOptions={{ leading: true, trailing: true }}
                        >
                          {(props: { width: number }) => (
                            <CommentsChart
                              innerLegend
                              width={props.width}
                              shortName={!screens.xl}
                              data={normalizeUserStatsCommentsData(
                                config.userStats.colors[0],
                                data?.user_stats.items,
                                screens.xl,
                              )}
                              legendItems={getUserStatsCommentsLegend(config.userStats.colors[0])}
                            />
                          )}
                        </ReactResizeDetector>
                      </Col>
                      <Col lg={12} md={24}>
                        <ReactResizeDetector
                          handleWidth
                          refreshMode="throttle"
                          refreshOptions={{ leading: true, trailing: true }}
                        >
                          {(props: { width: number }) => (
                            <CommentsChart
                              withAxes={false}
                              width={props.width}
                              sentimentType="negative"
                              data={normalizeUserStatsTimeData(
                                config.userStats.colors[1],
                                data?.user_stats.items,
                              )}
                              legendItems={getUserStatsTimeLegend(config.userStats.colors[1])}
                            />
                          )}
                        </ReactResizeDetector>
                      </Col>
                    </Row>
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      className={s.bbCommentsChartEmpty}
                      description={t("components:comments:tableEmpty")}
                      imageStyle={{
                        height: 40,
                      }}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </>
        </Row>
      </Col>
    </>
  );
};

export default AgentPerformanceWidgets;
