import React from "react";
import { useTranslation } from "react-i18next";

import { Modal, Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./LinkedInDeleteConfirmationModal.module.less";

interface ILinkedInDeleteConfirmationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onReject: () => void;
  onConfirm: () => void;
}

export const LinkedInDeleteConfirmationModal: React.FC<ILinkedInDeleteConfirmationModalProps> = ({
  isOpen,
  onCancel,
  onReject,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      responsive
      centered
      theme="light"
      title={t("containers:batchMode:linkedInDeleteConfirmationModal:title")}
      hideFooter
      destroyOnClose={true}
      visible={isOpen}
      onCancel={onCancel}
    >
      <p className={s.bbBatchModeLinkedInDeleteConfirmationModalText}>
        {t("containers:batchMode:linkedInDeleteConfirmationModal:text:start")}
        &nbsp;
        <span className={s.bbBatchModeLinkedInDeleteConfirmationModalTextAttention}>
          {t("containers:batchMode:linkedInDeleteConfirmationModal:text:attention")}
        </span>
        . {t("containers:batchMode:linkedInDeleteConfirmationModal:text:end")}
      </p>
      <div className={s.bbBatchModeLinkedInDeleteConfirmationModalActions}>
        <Button
          _size="md"
          _type="link"
          onClick={onReject}
          className={s.bbBatchModeLinkedInDeleteConfirmationModalActionsReject}
        >
          {t("containers:batchMode:linkedInDeleteConfirmationModal:actions:reject")}
        </Button>
        <Button _size="md" _type="danger" onClick={onConfirm}>
          {t("containers:batchMode:linkedInDeleteConfirmationModal:actions:confirm")}
        </Button>
      </div>
    </Modal>
  );
};
