import { ISavedReplyListProps } from "./SavedReplyList";

export const SavedReplyMessageMaxLimit = 2150;
export const SavedReplyNameMaxLimit = 128;
export const DEBOUNCE_WAIT_TIME = 500;

export enum SavedReplyTabKeys {
  CustomReplies = "0",
  Presets = "1",
}

export type IReplyList = Pick<ISavedReplyListProps, "onViewChange" | "onReplyClick">;
