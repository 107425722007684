import { i18n } from "i18next";
import { getI18next } from "../../../../../languages/i18next";

const i18nextinstance = getI18next() as i18n;

export const firstStep = {
  step: 0,
  text: i18nextinstance.t("pages:dashboard:modals:savesSearch:saveButton"),
};

export const secondStep = {
  step: 1,
  text: i18nextinstance.t("pages:dashboard:modals:savesSearch:overwriteButton"),
};
