import { createReducer, ActionType } from "typesafe-actions";
import { IAggregationsState } from "./types";
import { getAggregationsSuccessAction } from "./actions";
import {
  GET_AGGREGATIONS,
  GET_AGGREGATIONS_SUCCESS,
  GET_AGGREGATIONS_FAILURE,
} from "./actionTypes";

const initialState = {
  data: [],
  fetchingAggregations: false,
  fetchedAggregations: false,
  fetchedAggregationsFail: false,
};

export const aggregationsReducer = createReducer<IAggregationsState>(initialState, {
  [GET_AGGREGATIONS]: (state: IAggregationsState) => ({
    ...state,
    fetchingAggregations: true,
    fetchedAggregations: false,
    fetchedAggregationsFail: false,
  }),
  [GET_AGGREGATIONS_SUCCESS]: (
    state: IAggregationsState,
    action: ActionType<typeof getAggregationsSuccessAction>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingAggregations: false,
    fetchedAggregations: true,
    fetchedAggregationsFail: false,
  }),
  [GET_AGGREGATIONS_FAILURE]: (state: IAggregationsState) => ({
    ...state,
    fetchingAggregations: false,
    fetchedAggregations: false,
    fetchedAggregationsFail: true,
  }),
});
