import { action } from "typesafe-actions";
import { IComment } from "../comments/types";

import {
  GET_ALERTS,
  GET_ALERTS_SUCCESS,
  GET_ALERTS_FAILURE,
  RESET_ALERTS_DATA,
  GET_ALERT_COMMENTS,
  CLEAR_ALERT_COMMENTS,
  GET_ALERT_COMMENTS_SUCCESS,
  GET_ALERT_COMMENTS_FAILURE,
} from "./actionTypes";
import { IAlert, IAlertRequestBody } from "./types";

export const getAlertsAction = (data: IAlertRequestBody) => action(GET_ALERTS, data);
export const getAlertsActionSuccess = (data: IAlert[], cursor?: string) =>
  action(GET_ALERTS_SUCCESS, { data, cursor });
export const getAlertsActionFailure = () => action(GET_ALERTS_FAILURE);
export const resetAlertsAction = () => action(RESET_ALERTS_DATA);

export const getAlertCommentsAction = (id: string) => action(GET_ALERT_COMMENTS, id);
export const getAlertCommentsActionSuccess = (data: IComment[]) =>
  action(GET_ALERT_COMMENTS_SUCCESS, data);
export const getAlertCommentsActionFailure = () => action(GET_ALERT_COMMENTS_FAILURE);
export const clearAlertCommentsAction = () => action(CLEAR_ALERT_COMMENTS);
