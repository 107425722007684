import React from "react";
import { useTranslation } from "react-i18next";

import s from "./Keywords.module.less";

function ExplanationTooltip() {
  const { t } = useTranslation();

  return (
    <div className={s.bbExplanationTooltip}>
      {t("components:filters:keywords:explanationTooltip:line1")}:
      <ul className={s.bbExplanationTooltipList}>
        <li>{t("components:filters:keywords:explanationTooltip:and")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:or")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:not")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:operator")}</li>
      </ul>
      {t("components:filters:keywords:explanationTooltip:line2")}:
      <ul className={s.bbExplanationTooltipList}>
        <li>{t("components:filters:keywords:explanationTooltip:modifiers")}</li>
      </ul>
      {t("components:filters:keywords:explanationTooltip:line3")}:
      <ul className={s.bbExplanationTooltipList}>
        <li>{t("components:filters:keywords:explanationTooltip:example1")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:example2")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:example3")}</li>
      </ul>
    </div>
  );
}

export default ExplanationTooltip;
