import { TFunction } from "i18next";
import { isTiktokApp } from "./isTiktokApp";
import { IExtraItem } from "@components/_common/AppHeader/AppHeader.type";

export interface IMenuUserInfo {
  username: string;
  iconUrl: string;
}

export const getExtraMenuItems = (
  t: TFunction,
  onSignoutClick: () => void,
  userInfo: IMenuUserInfo,
): IExtraItem[] => {
  const menuItems: IExtraItem[] = [
    {
      id: "profile",
      basename: "settings",
      url: `${process.env.BASE_URL}/settings`,
      label: userInfo.username,
      iconUrl: userInfo.iconUrl,
      subLabel: t("header:extra:personalSettings"),
    },
    {
      id: "change-client",
      includeDividerBefore: true,
    },
    {
      id: "company",
      basename: "settings",
      includeDividerBefore: true,
      url: `${process.env.BASE_URL}/settings`,
      label: t("header:extra:company"),
    },
    {
      id: "asset-management",
      basename: "settings",
      url: `${process.env.BASE_URL}/settings`,
      label: t("header:extra:assetManagement"),
    },
    {
      id: "user-management",
      basename: "settings",
      includeDividerAfter: true,
      url: `${process.env.BASE_URL}/settings`,
      label: t("header:extra:userManagement"),
    },
    {
      id: "upgrade-plan",
    },
  ];

  if (!isTiktokApp()) {
    menuItems.push(
      ...[
        {
          id: "knowledge-base",
          label: t("header:extra:knowledgeBase"),
          external: "https://info.brandbastion.com/knowledge/",
        },
        {
          id: "startTour",
          label: t("header:extra:startTour"),
        },
        {
          id: "signout",
          basename: "",
          action: onSignoutClick,
          includeDividerBefore: true,
          label: t("header:extra:signOut"),
        },
      ],
    );
  }

  return menuItems;
};

export const getRestrictedMenuItems = (
  t: TFunction,
  onSignoutClick: () => void,
  userInfo: IMenuUserInfo,
) => {
  const menuItems: IExtraItem[] = [
    {
      id: "profile",
      basename: "settings",
      url: `${process.env.BASE_URL}/settings`,
      label: userInfo.username,
      iconUrl: userInfo.iconUrl,
      subLabel: t("header:extra:personalSettings"),
    },
    {
      id: "change-client",
      includeDividerBefore: true,
    },
    {
      id: "company",
      basename: "settings",
      includeDividerBefore: true,
      url: `${process.env.BASE_URL}/settings`,
      label: t("header:extra:company"),
    },
  ];

  if (!isTiktokApp()) {
    menuItems.push(
      ...[
        {
          id: "knowledge-base",
          label: t("header:extra:knowledgeBase"),
          external: "https://info.brandbastion.com/knowledge/",
        },
        {
          id: "signout",
          basename: "",
          action: onSignoutClick,
          includeDividerBefore: true,
          label: t("header:extra:signOut"),
        },
      ],
    );
  }

  return menuItems;
};
