/* eslint-disable @typescript-eslint/no-empty-function */
import classNames from "classnames";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { createContext, useContext, useEffect, useState } from "react";

import TagSelectionDropdown from "./TagSelectionDropdown";
import { Icon, Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { LinkedInDeleteConfirmationModal } from "./LinkedInDeleteConfirmationModal";

import {
  batchActionsPerformedSelector,
  performingBatchActionsPendingSelector,
} from "@store/comments/selectors";
import { performBulkCommentAction } from "@store/comments/actions";

import {
  IComment,
  AllowedCommentActionsType,
  IBulkCommentActionPayload,
} from "@store/comments/types";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { getCurrentModuleName } from "@utils/paths";
import { isLinkedinPlatform } from "@utils/platform";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";
import { IBatchModeContext, BatchModeProps, IActionButtons } from "./BatchMode.types";

import s from "./BatchMode.module.less";

import {
  CheckIcon,
  CloseIcon,
  InformationIcon,
  ChevronRightIcon,
  EyeIcon,
  EyeOffFilledIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ArchiveIcon, ArchiveFilledIcon } from "@assets/index";

const BatchModeContext = createContext<IBatchModeContext>({
  selectedComments: [],
  setSelectedComments: () => {},
  selectedAction: undefined,
  setSelectedAction: () => {},
  isBatchModeActive: false,
  toggleBatchMode: () => {},
  selectedTags: [],
  setSelectedTags: () => {},
  dropdownTags: [],
  setDropdownTags: () => {},
  selectAllCommentsByDefault: () => {},
  addSelectedComment: () => {},
  toggleSelectAll: () => {},
});

export const useBatchMode = () => useContext(BatchModeContext);

const ActionButtons = ({ isCustomTagDropdownOpen, setCustomTagDropdownOpen }: IActionButtons) => {
  const { t } = useTranslation();

  const {
    selectedAction,
    selectedTags,
    selectedComments,
    dropdownTags,
    setSelectedAction,
    setSelectedTags,
    setDropdownTags,
  } = useBatchMode();

  const onActionSelect = (action: string) => {
    setSelectedAction(
      selectedAction === action ? undefined : (action as AllowedCommentActionsType),
    );
  };

  const selectedActionClass = "border-opacity-100 border-2";

  const onActionClick = (action: AllowedCommentActionsType) => {
    onActionSelect(action);
    setCustomTagDropdownOpen(false);
  };

  const tagSelectionDropdown = (
    <TagSelectionDropdown
      selectedComments={selectedComments}
      dropdownTags={dropdownTags}
      setDropdownTags={setDropdownTags}
      selectedTags={selectedTags}
      setSelectedTags={setSelectedTags}
      isCustomTagDropdownOpen={isCustomTagDropdownOpen}
      setCustomTagDropdownOpen={setCustomTagDropdownOpen}
      className={classNames(s.bbBaseBatchModeAction, {
        [selectedActionClass]: selectedAction === "tag" || !!selectedTags?.length,
      })}
    />
  );

  if (getCurrentModuleName() === "moderation") {
    return (
      <>
        {tagSelectionDropdown}
        <Button
          _size="sm"
          onClick={() => onActionClick("review")}
          className={classNames(s.bbBaseBatchModeAction, {
            [selectedActionClass]: selectedAction === "review",
          })}
        >
          <CheckIcon />
          <span>{t("containers:batchMode:actionBar:markAsReviewed")}</span>
        </Button>
        <Button
          _size="sm"
          onClick={() => onActionClick("hide")}
          className={classNames(s.bbBaseBatchModeAction, {
            [selectedActionClass]: selectedAction === "hide",
          })}
        >
          {selectedAction === "hide" ? <EyeOffFilledIcon /> : <EyeIcon />}
          <span>{t("containers:batchMode:actionBar:hide")}</span>
        </Button>
      </>
    );
  } else if (getCurrentModuleName() === "care")
    return (
      <>
        {tagSelectionDropdown}
        <Button
          _size="sm"
          onClick={() => onActionClick("ignore")}
          className={classNames(s.bbBaseBatchModeAction, {
            [selectedActionClass]: selectedAction === "ignore",
          })}
        >
          {selectedAction === "ignore" ? <ArchiveFilledIcon /> : <ArchiveIcon />}
          {t("containers:batchMode:actionBar:archive")}
        </Button>
        <Button
          _size="sm"
          onClick={() => onActionClick("hide")}
          className={classNames(s.bbBaseBatchModeAction, {
            [selectedActionClass]: selectedAction === "hide",
          })}
        >
          {selectedAction === "hide" ? <EyeOffFilledIcon /> : <EyeIcon />}
          <span>{t("containers:batchMode:actionBar:hide")}</span>
        </Button>
      </>
    );
  else return null;
};

export function BatchModeContextProvider({ children }: BatchModeProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedTags, setSelectedTags] = useState<ICustomTag[]>([]);
  const [dropdownTags, setDropdownTags] = useState<ICustomTag[]>([]);
  const [isBatchModeActive, setIsBatchModeActive] = useState<boolean>(false);
  const [isCustomTagDropdownOpen, setCustomTagDropdownOpen] = useState(false);
  const [selectedComments, setSelectedComments] = useState<IComment[]>([]);
  const [selectedAction, setSelectedAction] = useState<AllowedCommentActionsType>();
  const [isLinkedInDeleteConfrimationModalOpen, setLinkedInDeleteConfrimationModalOpen] =
    useState(false);

  const performedBatchActions = useSelector(batchActionsPerformedSelector);
  const performingBatchActions = useSelector(performingBatchActionsPendingSelector);

  const resetContext = () => {
    setSelectedAction(undefined);
    setSelectedComments([]);
    setSelectedTags([]);
  };

  useEffect(() => {
    if (performedBatchActions) {
      addToast({
        title: t("containers:batchMode:notifications:genericSuccess:title"),
        message: t("containers:batchMode:notifications:genericSuccess:message"),
        type: "success_accent",
      });
    }
  }, [performedBatchActions, t]);

  useEffect(() => {
    if (!isBatchModeActive || performedBatchActions) {
      resetContext();
    }
  }, [isBatchModeActive, performedBatchActions]);

  const toggleBatchMode = () => setIsBatchModeActive(state => !state);

  const toggleSelectAll = (comments: IComment[]) => {
    if (!selectedComments || !selectedComments?.length) {
      return setSelectedComments([...comments]);
    }
    if (!!selectedComments && selectedComments?.length < comments.length) {
      return setSelectedComments([...comments]);
    }
    if (selectedComments?.length === comments.length) {
      return setSelectedComments([]);
    }
  };

  const selectAllCommentsByDefault = (comments: IComment[]) => setSelectedComments(comments);

  const addSelectedComment = (comment: IComment) => {
    let filteredComments = null;

    const isCommentExist = (currentComment: IComment) => currentComment.id === comment.id;

    if (selectedComments?.some(isCommentExist)) {
      filteredComments = [
        ...selectedComments.filter(currentComment => !isCommentExist(currentComment)),
      ];
    } else {
      filteredComments = [...(selectedComments || []), comment];
    }

    setSelectedComments([...filteredComments]);
  };

  const toggleLinkedInDeleteConfirmation = () => {
    setLinkedInDeleteConfrimationModalOpen(prev => !prev);
  };

  const applyBulkActions = (comments: IComment[]) => {
    const payload: IBulkCommentActionPayload = {
      comment_ids: comments?.map(comment => comment?.id) || [],
    };

    if (selectedAction) payload.actions = [selectedAction];

    if (selectedTags?.length) payload.custom_tags_to_add = selectedTags.map(tag => tag.id || "");

    if (
      payload.comment_ids.length &&
      (payload.actions?.length || payload.custom_tags_to_add?.length)
    ) {
      resetContext();
      setCustomTagDropdownOpen(false);
      dispatch(performBulkCommentAction(payload));
    }
  };

  const handleRejectDeleteLinkedInComment = () => {
    const commentsWithoutLinkedIn = selectedComments.filter(
      comment => !isLinkedinPlatform(comment.platform_type.id),
    );

    toggleLinkedInDeleteConfirmation();
    applyBulkActions(commentsWithoutLinkedIn);
  };

  const handleConfirmDeleteLinkedInComment = () => {
    toggleLinkedInDeleteConfirmation();
    applyBulkActions(selectedComments);
  };

  const onApplyAndContinueClick = () => {
    const hasLinkedInComment = selectedComments.some(comment =>
      isLinkedinPlatform(comment.platform_type.id),
    );
    const showLinkedInDeleteConfirmationModal = hasLinkedInComment && selectedAction === "hide";

    if (showLinkedInDeleteConfirmationModal) {
      toggleLinkedInDeleteConfirmation();
      return;
    }

    applyBulkActions(selectedComments);
  };

  return (
    <BatchModeContext.Provider
      value={{
        selectedComments,
        setSelectedComments,
        selectedAction,
        setSelectedAction,
        isBatchModeActive,
        toggleBatchMode,
        dropdownTags,
        setDropdownTags,
        selectedTags,
        setSelectedTags,
        addSelectedComment,
        selectAllCommentsByDefault,
        toggleSelectAll,
      }}
    >
      <div id="batch-mode-provider">
        {children}
        {isBatchModeActive &&
          createPortal(
            <>
              <div className={s.bbBatchModeWrapper}>
                <div className={s.bbBatchMode}>
                  <div className={s.bbBatchModeCommentSelectionMessage}>
                    <span>
                      {t("containers:batchMode:actionBar:commentsSelected", {
                        count: selectedComments?.length || 0,
                      })}
                    </span>
                    <Tooltip
                      placement="top"
                      mouseEnterDelay={0.2}
                      title={t("containers:batchMode:actionBar:maxCommentSelectionMessage")}
                    >
                      <span className={s.bbBatchModeCommentSelectionIcon}>
                        <InformationIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <div className={s.bbBatchModeActionBtnsWrapper}>
                    <div className={s.bbBatchModeActionBtns}>
                      <ActionButtons
                        isCustomTagDropdownOpen={isCustomTagDropdownOpen}
                        setCustomTagDropdownOpen={setCustomTagDropdownOpen}
                      />
                    </div>
                    <div className={s.bbDivider}></div>
                    <div className={s.bbBatchModeApplyBtnWrapper}>
                      <Button
                        _size="sm"
                        loading={performingBatchActions}
                        onClick={onApplyAndContinueClick}
                        className={s.bbBatchModeApplyBtn}
                        disabled={
                          !selectedComments?.length ||
                          (!selectedAction?.length && !selectedTags?.length)
                        }
                      >
                        {t("containers:batchMode:actionBar:applyAndContinue")}
                        <ChevronRightIcon />
                      </Button>
                      <Icon
                        _size="sm"
                        icon={CloseIcon}
                        onClick={toggleBatchMode}
                        className={s.bbBatchModeCloseBtn}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <LinkedInDeleteConfirmationModal
                isOpen={isLinkedInDeleteConfrimationModalOpen}
                onConfirm={handleConfirmDeleteLinkedInComment}
                onCancel={toggleLinkedInDeleteConfirmation}
                onReject={handleRejectDeleteLinkedInComment}
              />
            </>,
            document.body,
          )}
      </div>
    </BatchModeContext.Provider>
  );
}
