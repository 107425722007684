import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useMemo } from "react";

import {
  IContextMenuOption,
  isLastChipOperator,
  hackOneOptionHeight,
  getContextMenuOptions,
} from "./ContextMenuConfig";
import { Select } from "@bbdevcrew/bb_ui_kit_fe";
import { IContextMenu } from "../../inputChip/InputChipQueryConfig";

import s from "./ContextMenu.module.less";

function ContextMenu({ chips, chipValue, onChipAdd, _size = "md" }: IContextMenu) {
  const { t } = useTranslation();
  const options = useMemo(() => {
    const filteredOptions = getContextMenuOptions(chips).filter(chip =>
      (chip as IContextMenuOption).label
        .toLowerCase()
        .includes(chipValue.replace("/", "").toLowerCase()),
    );

    return filteredOptions.length === 1 ? [...filteredOptions, ""] : filteredOptions;
  }, [chips, chipValue]);

  if (isLastChipOperator(chips)) {
    return (
      <span className={s.bbContextMenuChip}>
        {t("components:filters:keywords:context_menu:forbidden_operator")}
      </span>
    );
  }

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChipAdd(event.target.value);
  };

  /**
   * Don't show select at all if there are no options.
   * "" is dummy option for select size/height hack
   */
  if (options.length === 1 && options[0] === "") {
    return null;
  }

  return (
    <Select
      _size={_size}
      options={options}
      onChange={onChange}
      size={options.length} // This forces options to be "open," while keeping `select` hidden
      style={
        options.length === 2 && options[options.length - 1] === "" ? hackOneOptionHeight(_size) : {}
      }
      className={s.bbContextMenuSelect}
    />
  );
}

export default ContextMenu;
