import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";

import s from "./Aggregations.module.less";

import { Col, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AggregationStat from "./aggregationStat/AggregationStat";
import { Card, Loading, moveArrayItemToNewIndex } from "@bbdevcrew/bb_ui_kit_fe";

import {
  kpiDataSelector,
  getKpiDataPendingSelector,
  getKpiDataSuccessfulSelector,
} from "@store/kpiData/selectors";
import { getKpiDataAction } from "@store/kpiData/actions";

import { IAggregationsProps } from "./Aggregations.type";
import { IKpiData, IKpiItem } from "@store/kpiData/types";
import { aggregationInfoKey } from "@utils/aggregationName";
import { IFilters } from "@store/filters/types";

const Aggregations: React.FC<IAggregationsProps> = ({ filters, colors, height, widthInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hasAggregations, setHasAggregations] = useState<boolean>(false);
  const [aggregationsInfoKey, setAggregationsInfoKey] = useState<string>("");
  const [aggregations, setAggregations] = useState<IKpiData>({} as IKpiData);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAggregations = useCallback(
    (data: { filters: IFilters }) => dispatch(getKpiDataAction(data as any)), // eslint-disable-line
    [dispatch],
  );

  const aggregationsFetched = useSelector(getKpiDataSuccessfulSelector);
  const aggregationsPending = useSelector(getKpiDataPendingSelector);

  const aggregs = useSelector(kpiDataSelector);

  useEffect(() => {
    if (filters) {
      const rangeOption = filters.data_range_option || "";

      const dataSearchCopy = { ...filters };
      setIsLoading(true);
      setAggregations({} as IKpiData);
      fetchAggregations({ filters: dataSearchCopy });

      if (rangeOption && rangeOption !== "custom") {
        setHasAggregations(true);
        setAggregationsInfoKey(aggregationInfoKey(rangeOption));
      } else setHasAggregations(false);
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (aggregationsFetched) {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [aggregationsFetched]);

  useEffect(() => {
    if (aggregationsPending) {
      setIsLoading(true);
    }
    // eslint-disable-next-line
  }, [aggregationsPending]);

  useEffect(() => {
    if (aggregs) {
      setAggregations(aggregs as IKpiData);
    }
    // eslint-disable-next-line
  }, [aggregs]);

  return (
    <Card
      style={{ margin: 10, height: height || 620 }}
      headerTitle={t("pages:dashboard:cardStats:title")}
      headerTooltip={t("pages:dashboard:cardStats:titleTooltip")}
      headerAction={
        <span className={s.bbAggregationsRightTitle}>
          {hasAggregations && (
            <>
              {t("pages:dashboard:cardStats:rightTitle_" + aggregationsInfoKey)}{" "}
              {t("pages:dashboard:cardStats:rightTitleEnd")}
            </>
          )}
        </span>
      }
    >
      {isLoading ? (
        <div className={s.bbAggregationsLoading}>
          <Loading
            isLoading
            size="large"
            tip={t("pages:loading:spinText")}
            indicator={<LoadingOutlined color="primary" style={{ fontSize: 24 }} />}
          />
        </div>
      ) : aggregations && aggregations.items && aggregations.items.length ? (
        moveArrayItemToNewIndex(aggregations.items, 4, 3).map((item: IKpiItem, key: number) => {
          return (
            <Col key={key} xs={24}>
              <div
                className={s.bbAggregationsItem}
                data-stonly-target={`safety-moderation__overview--${item.id}`}
              >
                <AggregationStat item={item} widthInfo={widthInfo} color={colors[item.id]} />
              </div>
            </Col>
          );
        })
      ) : (
        <Empty
          className={s.bbAggregationsCardEmpty}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{
            height: 40,
          }}
        />
      )}
    </Card>
  );
};

export default Aggregations;
