import { i18n } from "i18next";
import { useEffect } from "react";
import { ActionType } from "typesafe-actions";
import { useDispatch, useSelector } from "react-redux";

import {
  IComment,
  ICommentReply,
  ICommentsState,
  ReplyPlatformType,
  ICommentActionPayload,
} from "@store/comments/types";
import { IRepliesState, IReplyData } from "@store/replies/types";

import {
  triggerNextCommentBatch,
  performCommentActionSuccessAction,
} from "@store/comments/actions";
import { getI18next } from "../languages/i18next";
import { PlatformType } from "@store/platform/types";
import { getReplyCommentsSelector } from "@store/replies/selectors";
import { getAllCommentsSelector } from "@store/comments/selectors";
import { PostPlacementType } from "@components/_common/Social/Post/Post.type";

const i18nextInstance = getI18next() as i18n;

export const getDefaultSortOptions = () => [
  {
    id: "desc",
    value: "desc",
    label: i18nextInstance.t("components:comments:sort:newest"),
  },
  {
    id: "asc",
    value: "asc",
    label: i18nextInstance.t("components:comments:sort:oldest"),
  },
];

export const getAiInboxSortOptions = () => [
  {
    id: "desc|ai_inbox_score",
    label: i18nextInstance.t("components:inbox:sort:higherIntent"),
  },
  {
    id: "asc|ai_inbox_score",
    label: i18nextInstance.t("components:inbox:sort:lowerIntent"),
  },
  {
    id: "desc|created_time",
    label: i18nextInstance.t("components:inbox:sort:newest"),
  },
  {
    id: "asc|created_time",
    label: i18nextInstance.t("components:inbox:sort:oldest"),
  },
];

export const getDefaultReplyPlatformTypeOptions = (): {
  value: ReplyPlatformType;
  label: string;
  id: ReplyPlatformType;
}[] => [
  {
    id: "all",
    value: "all",
    label: i18nextInstance.t("components:comments:filterByReplyPlatformType:all"),
  },
  {
    id: "brandbastion",
    value: "brandbastion",
    label: i18nextInstance.t("components:comments:filterByReplyPlatformType:brandbastion"),
  },
  {
    id: "platform",
    value: "platform",
    label: i18nextInstance.t("components:comments:filterByReplyPlatformType:platform"),
  },
];

/**
 * TODO: Not really ideal. We should look to adjust our contracts, so that comment actions
 * match inboxStats and comment.reply props.
 */
export enum ActionToReplyProp {
  like = "is_liked",
  hide = "is_hidden",
  flag = "is_flagged",
  ignore = "is_ignored",
  review = "is_reviewed",
  delete = "is_deleted",
  star = "is_starred",
  dismiss_errors = "is_error_dismissed",
}

export const canReplyToComment = (comment: IComment, isHidden: boolean) => {
  if (isHidden) {
    if (
      comment.platform_type.id === "facebook" ||
      comment.platform_type.id === "twitter" ||
      (comment.platform_type.id === "tiktok" && !comment.has_ads)
    ) {
      return true;
    }
    return false;
  }

  return true;
};

// Sets comment `reply` props
export function getNewReplyState(
  currentState: ICommentReply,
  commentActionPayload: ICommentActionPayload,
) {
  let state: ICommentReply = {
    ...currentState,
    [ActionToReplyProp[commentActionPayload.action]]: commentActionPayload.value,
  };

  if (commentActionPayload.action === "ignore") {
    state = {
      ...state,
      is_starred: false,
    };
  }

  return state;
}

export function handleSuccessfulCommentActionState<S extends ICommentsState | IRepliesState>(
  state: S,
  action: ActionType<typeof performCommentActionSuccessAction>,
): S {
  const commentIdx = state.comments.findIndex(({ id }) => id === action.payload.commentAction.id);

  if (state.comments[commentIdx]) {
    if (action.payload.commentAction.action.includes("hide")) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            actions: {
              ...state.comments[commentIdx].actions,
              can_reply: canReplyToComment(
                state.comments[commentIdx],
                action.payload.commentAction.value,
              ),
            },
            moderation: {
              ...state.comments[commentIdx].moderation,
              is_reviewed: action.payload.commentAction.value
                ? action.payload.commentAction.value
                : state.comments[commentIdx].moderation.is_reviewed,
            },
            is_hidden: action.payload.commentAction.value,
          },
        }),
      };
    }

    if (action.payload.commentAction.action.includes("delete")) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            actionsProcessing: state.actionsProcessing.filter(
              processingAction => processingAction !== action.payload.commentAction.action,
            ),
            moderation: {
              ...state.comments[commentIdx].moderation,
              is_reviewed: action.payload.commentAction.value,
            },
            is_deleted: action.payload.commentAction.value,
          },
        }),
      };
    }

    if (action.payload.commentAction.action.includes("like")) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            is_liked: action.payload.commentAction.value,
          },
        }),
      };
    }

    if (action.payload.commentAction.action.match(/review|flag/)) {
      return {
        ...state,
        comments: Object.assign([], {
          ...state.comments,
          [commentIdx]: {
            ...state.comments[commentIdx],
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            moderation: {
              ...state.comments[commentIdx].moderation,
              [ActionToReplyProp[action.payload.commentAction.action]]:
                action.payload.commentAction.value,
            },
          },
        }),
      };
    }

    if (action.payload.commentAction.action.includes("dismiss_errors")) {
      const commentsStateCopy = Object.assign([], state.comments);
      commentsStateCopy.splice(commentIdx, 1);

      return {
        ...state,
        comments: commentsStateCopy,
      };
    }

    // Flag/ignore actions are handled in `reply` prop of comment
    return {
      ...state,
      comments: Object.assign([], {
        ...state.comments,
        [commentIdx]: {
          ...state.comments[commentIdx],
          actionsProcessing: state.actionsProcessing.filter(
            actionProcessing => actionProcessing !== action.payload.commentAction.action,
          ),
          reply: getNewReplyState(state.comments[commentIdx].reply, action.payload.commentAction),
        },
      }),
    };
  }

  return state;
}

export function handleFailedCommentActionState<S extends ICommentsState | IRepliesState>(state: S) {
  return {
    ...state,
    comments: Object.assign([], {
      ...state.comments.map(comment => {
        comment.actionsProcessing = [];
        return comment;
      }),
    }),
  };
}

export const gethideUnhideTooltip = (
  isHidden: boolean,
  platformType: PlatformType,
  hideUnhideTooltip?: string,
) => {
  if (!!hideUnhideTooltip) {
    return hideUnhideTooltip;
  }

  if (isHidden) {
    return i18nextInstance.t(`components:comments:tooltips:unhide:${platformType}`);
  }

  return i18nextInstance.t("components:comments:tooltips:hide");
};

export const isPrivateConversation = (post_placement: PostPlacementType) =>
  post_placement === "STORY" || post_placement === "DM";

export const isStoryAttachment = (description: string) =>
  description === "story_reply" || description === "story_mention";

const findCommentById = (comments: IComment[], replyingCommentId: string) =>
  comments?.find(({ id }) => id === replyingCommentId);

export const useReplyingComment = (replyData: IReplyData) => {
  const dispatch = useDispatch();

  const comments = useSelector(getAllCommentsSelector);
  const replyComments = useSelector(getReplyCommentsSelector);

  const commentId = replyData.replying_comment_id;

  const replyingComment =
    findCommentById(comments, commentId) || findCommentById(replyComments, commentId);

  useEffect(() => {
    if (!replyingComment) {
      dispatch(triggerNextCommentBatch(commentId));
    }
  }, [replyingComment, commentId, dispatch]);

  return replyingComment;
};
