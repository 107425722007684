import { createReducer, ActionType } from "typesafe-actions";
import { ILanguage, ILanguagesState } from "./types";
import { postLanguagesSuccessAction } from "./actions";
import { POST_LANGUAGES, POST_LANGUAGES_SUCCESS, POST_LANGUAGES_FAILURE } from "./actionTypes";

const initialState = {
  languagesList: [] as ILanguage[],
  fetchingLanguages: false,
  fetchedLanguages: false,
  fetchedLanguagesFail: false,
};

export const languagesReducer = createReducer<ILanguagesState>(initialState, {
  [POST_LANGUAGES]: (state: ILanguagesState) => ({
    ...state,
    fetchingLanguages: true,
    fetchedLanguages: false,
    fetchedLanguagesFail: false,
  }),
  [POST_LANGUAGES_SUCCESS]: (
    state: ILanguagesState,
    action: ActionType<typeof postLanguagesSuccessAction>,
  ) => {
    return {
      ...state,
      languagesList: action.payload,
      fetchingLanguages: true,
      fetchedLanguages: true,
      fetchedLanguagesFail: false,
    };
  },
  [POST_LANGUAGES_FAILURE]: (state: ILanguagesState) => ({
    ...state,
    fetchingLanguages: true,
    fetchedLanguages: true,
    fetchedLanguagesFail: true,
  }),
});
