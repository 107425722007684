import { action } from "typesafe-actions";
import {
  POST_STATS,
  POST_STATS_SUCCESS,
  POST_STATS_FAILURE,
  RESET_STATS_DATA,
} from "./actionTypes";
import { IDataStatsProps } from "./types";
import { IFilters } from "../filters/types";

export const postStatsAction = (data: IFilters, query = "") =>
  action(POST_STATS, {
    data: data,
    query: query,
  });
export const postStatsActionSuccess = (data: IDataStatsProps) => action(POST_STATS_SUCCESS, data);
export const postStatsActionFailure = () => action(POST_STATS_FAILURE);

export const resetStatsData = () => action(RESET_STATS_DATA);
