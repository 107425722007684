import classNames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo, useEffect, useState } from "react";

import EmptyListState from "./EmptyListState";
import { Icon, Dropdown, Tooltip, ListItem } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import { ISavedFiltersListProps } from "./types";
import { IFilterItem } from "../../AppFilters.type";

import s from "./SavedFilters.module.less";

import { DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { FolderIcon, FolderFilledIcon } from "@assets/index";

export const SavedFiltersList: React.FC<ISavedFiltersListProps> = ({
  isSavedFilterOpen,
  predefinedFilters,
  savedFilterValue,
  onDropdownToggle,
  onDropdownClick,
  onSavedChange,
  handleDeleteSavedFilter,
}) => {
  const { t } = useTranslation();
  const [selectedFilterName, setSelectedFilterName] = useState<string>("");

  const me = useSelector(meSelector);

  const isSavedFilterSelected = useCallback(() => {
    return !!savedFilterValue;
  }, [savedFilterValue]);

  const isFilterSelected = useCallback(
    (filter: IFilterItem) => {
      if (savedFilterValue == filter.id) {
        setSelectedFilterName(filter.name);
      }

      return savedFilterValue == filter.id;
    },
    [savedFilterValue],
  );

  const onSavedFilterClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string | number | null,
  ) => {
    if (event.target instanceof window.HTMLButtonElement) {
      return;
    }
    onSavedChange(id);
    onDropdownClick(false);
  };

  useEffect(() => {
    if (!savedFilterValue) {
      setSelectedFilterName("");
    }
  }, [savedFilterValue]);

  const SavedFiltersOptions = useMemo(() => {
    const showFilters = predefinedFilters.some(
      filter => filter.items.length && filter.id !== "stickyFilters",
    );

    if (!showFilters) {
      return <EmptyListState />;
    }

    return predefinedFilters.map(({ id, label, items }) => {
      if ((id === "moderationGroups" && !me?.client?.is_managed) || id === "stickyFilters") {
        return null;
      }
      return (
        <div key={id}>
          {!!items.length && (
            <ListItem key={id} value={id.toString()} className={s.bbSavedFilterListItem}>
              {label}
            </ListItem>
          )}

          {items.map(item => {
            return (
              <ListItem
                key={item.id.toString()}
                value={item.id.toString()}
                selected={isFilterSelected(item)}
                onClick={e => onSavedFilterClick(e, item.id)}
                rightLabel={
                  id === "savedFilters" &&
                  savedFilterValue !== item.id && (
                    <button
                      className={s.bbSavedFilterDeleteBtn}
                      onClick={e => {
                        e.stopPropagation();
                        handleDeleteSavedFilter(item);
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  )
                }
              >
                {item.name}
              </ListItem>
            );
          })}
        </div>
      );
    });
    // eslint-disable-next-line
  }, [predefinedFilters, savedFilterValue]);

  return (
    <Dropdown
      open={isSavedFilterOpen}
      setOpen={onDropdownClick}
      placement="bottomRight"
      menuClassName={s.bbSavedFiltersMenu}
      trigger={
        <div className={s.bbbSavedFilterButtonWrapper}>
          <Tooltip
            placement="topRight"
            title={t("components:filters:savedFilters.label", {
              filterName: selectedFilterName.length ? `: ${selectedFilterName}` : "",
            })}
          >
            <Icon
              _size="lg"
              onClick={onDropdownToggle}
              icon={isSavedFilterSelected() ? FolderFilledIcon : FolderIcon}
              className={classNames(
                s.bbSavedFilterButton,
                s[`bbSavedFilterButton-${isSavedFilterSelected() ? "selected" : "default"}`],
              )}
            />
          </Tooltip>
        </div>
      }
    >
      {SavedFiltersOptions}
    </Dropdown>
  );
};
