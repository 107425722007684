import { action } from "typesafe-actions";
import {
  GET_MODERATION_GUIDELINES,
  GET_MODERATION_GUIDELINES_SUCCESS,
  GET_MODERATION_GUIDELINES_FAILURE,
} from "./actionTypes";
import { IGuidelinesResponse } from "./types";

export const getGuidelinesAction = () => action(GET_MODERATION_GUIDELINES);
export const getGuidelinesActionSuccess = (moderationdGuidelines: IGuidelinesResponse) =>
  action(GET_MODERATION_GUIDELINES_SUCCESS, moderationdGuidelines);
export const getGuidelinesActionFailure = () => action(GET_MODERATION_GUIDELINES_FAILURE);
