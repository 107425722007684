import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "antd";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../../_common/pieChartWidget/PieChartWidget";
import CommentsByTagChart from "./commentsByTagChart/CommentsByTagChart";
import TaggedCommentsWidget from "./taggedCommentsWidget/TaggedCommentsWidget";
import CommentsByPlatformWidget from "./commentsByPlatformWidget";

import { meSelector } from "@store/me/selectors";

import s from "./ExternalAppOverview.module.less";

import {
  ReplySelectOptions,
  normalizeCareTagsStatsData,
  normalizeRepliedStatsData,
} from "./helpers";
import {
  ICareOverview,
  IOverviewPerformanceItem,
  IOverviewPerformanceTag,
} from "@store/dashboard/types";
import { IPieChartUnit } from "../../search/Search.type";

import { ReplyIcon, TagIcon } from "@assets/index";

interface ICareSectionProps {
  data?: ICareOverview;
  loading: boolean;
  replySelectValue: string;
  showPrimeWidgets?: boolean;
}

const CareSection: FC<ICareSectionProps> = ({
  loading,
  data,
  replySelectValue,
  showPrimeWidgets = true,
}) => {
  const { t } = useTranslation();

  const [repliedStatsData, setRepliedStatsData] = useState<IOverviewPerformanceItem[]>([]);
  const [tagsStatsData, setTagsStatsData] = useState<IOverviewPerformanceTag[]>([]);
  const [tagsRepliedStatsData, setTagsRepliedStatsData] = useState<IPieChartUnit[]>([]);

  const me = useSelector(meSelector);

  useEffect(() => {
    if (data) {
      let repliedData: IOverviewPerformanceItem[] = [];
      let tagsRepliedStats: IOverviewPerformanceItem[] = [];

      if (replySelectValue === ReplySelectOptions.REPLIED_BY_BB) {
        repliedData = data.care_bb_replied_stats.items;
        tagsRepliedStats = data.tags_bb_replied_stats.items;
      } else if (replySelectValue === ReplySelectOptions.REPLIED_IN_PLATFORM) {
        repliedData = data.care_platform_replied_stats.items;
        tagsRepliedStats = data.tags_platform_replied_stats.items;
      } else if (replySelectValue === ReplySelectOptions.REPLIED_BY_ALL) {
        repliedData = data.care_replied_stats.items;
        tagsRepliedStats = data.tags_replied_stats.items;
      }

      setRepliedStatsData(repliedData);
      setTagsRepliedStatsData(normalizeRepliedStatsData(tagsRepliedStats));
      setTagsStatsData(normalizeCareTagsStatsData(data.engagement_tags_stats.items));
    }
  }, [data, replySelectValue]);

  const getDefaultValue = () => {
    if (tagsRepliedStatsData?.length) {
      const tagsRepliedStatsValues = tagsRepliedStatsData?.map(item => item.value);
      const tagsRepliedStatsTotal = tagsRepliedStatsValues.length
        ? tagsRepliedStatsValues.reduce((k, v) => k + v)
        : 0;

      return {
        name: "Total",
        value: tagsRepliedStatsTotal,
        color: "#000",
        rawName: "total",
        percentage_of_total: 100,
      };
    } else return undefined;
  };

  return (
    <>
      <Col xs={24} xxl={8}>
        <Row gutter={[28, 28]}>
          {showPrimeWidgets && (
            <Col xs={24}>
              <CommentsByPlatformWidget
                data={repliedStatsData}
                loading={loading}
                headerIcon={<ReplyIcon />}
                headerIconType="neutral"
                title={t("components:overviewCare:repliesIssued:title")}
                subTitle={t("components:overviewCare:repliesIssued:subTitle")}
                showCalculatedPercentage={false}
              />
            </Col>
          )}
          <Col xs={24}>
            <TaggedCommentsWidget
              data={data?.engagement_total_stats.items || []}
              loading={loading}
              headerIcon={<TagIcon />}
              headerIconType="neutral"
              title={t("components:overviewCare:engageable:title")}
              subTitle={t("components:overviewCare:engageable:subTitle", {
                name: me?.client?.name,
              })}
              showCalculatedPercentage={false}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} xxl={16}>
        <Row gutter={[28, 28]}>
          {showPrimeWidgets && (
            <Col xs={24}>
              <Card
                headerTitle={t("components:overviewCare:responded:title")}
                headerSubTitle={t("components:overviewCare:responded:subTitle")}
              >
                <div className={s.bbRepliedStatsPieChart}>
                  <PieChartWidget
                    sizes={[260, 260]}
                    radiuses={[95, 120]}
                    legendPosition="top"
                    loading={loading}
                    chartData={tagsRepliedStatsData}
                    defaultValue={getDefaultValue()}
                  />
                </div>
              </Card>
            </Col>
          )}
          <CommentsByTagChart
            data={tagsStatsData}
            loading={loading}
            title={t("components:overviewCare:respondedByTag:title")}
            subTitle={t("components:overviewCare:respondedByTag:subTitle", {
              name: me?.client?.name,
            })}
            showCalculatedPercentage={false}
          />
        </Row>
      </Col>
    </>
  );
};

export default CareSection;
