import React from "react";
import classNames from "classnames";

import { IActionDropdownItem } from "./ActionsDropdown.type";

import s from "./ActionsDropdown.module.less";

export const getMenuItemClassName = (selected?: boolean, disabled?: boolean) => {
  let ddMenuClassName = "";

  if (selected) {
    if (disabled) ddMenuClassName = s.bbDropdownMenuItemDisabled;
    else ddMenuClassName = s.bbDropdownMenuItemActive;
  } else {
    ddMenuClassName = s.bbDropdownMenuItem;
  }

  return ddMenuClassName;
};

export const renderIcon = ({
  icon,
  selected,
  disabled,
  iconClassName = "",
}: IActionDropdownItem) => {
  let className = "";

  if (selected) {
    if (disabled) className = s.bbSvgClassDisabled;
    else className = s.bbSvgClassSelected;
  } else {
    className = s.bbSvgClassDefault;
  }

  return <span className={classNames(className, iconClassName)}>{icon}</span>;
};
