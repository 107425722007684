import React, { FC } from "react";
import classNames from "classnames/bind";

import { Icon, UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplyPhrase.module.less";

import { IReplyPhrase } from "@store/comments/types";

import { BbLogoIcon } from "@assets/index";

interface IReplyPhraseProps {
  replyPhrase?: IReplyPhrase;
  className?: string;
}

export const ReplyPhrase: FC<IReplyPhraseProps> = ({ replyPhrase, className }) => (
  <>
    {replyPhrase && (
      <p className={classNames(s.bbReplyPhrase, className)}>
        {replyPhrase.platform_user ? (
          <UserAvatar
            className={s.bbReplyPhraseAvatar}
            img={replyPhrase.platform_user.avatar}
            lastName={replyPhrase.platform_user.last_name}
            firstName={replyPhrase.platform_user.first_name}
          />
        ) : (
          <Icon _size="lg" icon={BbLogoIcon} className={s.bbReplyPhraseIcon} />
        )}
        {replyPhrase.phrase}
      </p>
    )}
  </>
);
