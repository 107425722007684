import { useTranslation } from "react-i18next";
import React, { useEffect, useState, MouseEvent, useRef, FC, useCallback } from "react";
import { useSelector } from "react-redux";

import { useForm } from "antd/lib/form/Form";
import { Form, Typography, Carousel } from "antd";
import { Modal, AutoCompleteInput } from "@bbdevcrew/bb_ui_kit_fe";

import {
  postSavedFilterPendingSelector,
  postSavedFilterSuccessfulSelector,
  postSavedFilterFailedSelector,
} from "@store/savedFilters/selectors";

import { CarouselRef } from "antd/lib/carousel";
import { firstStep, secondStep } from "./constants";
import { ISaveFilterModalProps, ISaveForm, IMappedFilterItem } from "./types";

import s from "./SavedFilters.module.less";

const SaveFilterModal: FC<ISaveFilterModalProps> = ({
  onSaveFilter,
  savedFilters,
  onSaveFilterCancel,
}) => {
  const { t } = useTranslation();
  const carousel = useRef<CarouselRef>(null);
  const [form] = useForm<ISaveForm>();

  const [value, setValue] = useState<IMappedFilterItem>();
  const [autocompleteOptions, setAutocompleteOptions] = useState<IMappedFilterItem[]>([]);
  const [buttonNext, setButtonNext] = useState(firstStep);

  const isPostSavedPending = useSelector(postSavedFilterPendingSelector);
  const isPostSuccessful = useSelector(postSavedFilterSuccessfulSelector);
  const isPostFailed = useSelector(postSavedFilterFailedSelector);

  useEffect(() => {
    setAutocompleteOptions(
      savedFilters.map(savedFilter => ({
        ...savedFilter,
        value: savedFilter.name,
      })),
    );
  }, [savedFilters]);

  const isFirstStep = useCallback(() => buttonNext.step === 0, [buttonNext]);
  const isSecondStep = useCallback(() => buttonNext.step === 1, [buttonNext]);

  const onFinish = (values: ISaveForm) => {
    onSaveFilter(values, isSecondStep());
  };

  const goToFirstStep = useCallback(() => {
    setButtonNext(firstStep);
    carousel.current?.goTo(0);
  }, []);

  const goToSecondStep = useCallback(() => {
    setButtonNext(secondStep);
    carousel.current?.goTo(1);
  }, []);

  useEffect(() => {
    if (isPostSuccessful) {
      form.resetFields();
      goToFirstStep();
      onSaveFilterCancel();
    }

    if (isPostFailed) {
      goToSecondStep();
    }
    // eslint-disable-next-line
  }, [isPostSuccessful, isPostFailed, isPostSavedPending]);

  const cancel = () => {
    form.resetFields();
    onSaveFilterCancel();
    setTimeout(() => {
      if (carousel.current) {
        goToFirstStep();
      }
    }, 500);
  };

  const handleOk = (event: MouseEvent) => {
    if (isFirstStep() || isSecondStep()) {
      form.submit();
    }
    event.preventDefault();
  };

  const handleCancel = (event: MouseEvent) => {
    if (isFirstStep()) {
      cancel();
    } else if (isSecondStep()) {
      goToFirstStep();
    }
    event.preventDefault();
  };

  const handleChange = (name: string) => {
    const filter = autocompleteOptions.find(item => item.value === name);
    setValue(filter);
  };

  return (
    <Modal
      visible
      theme="light"
      destroyOnClose
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLabel={buttonNext.text}
      confirmLoading={isPostSavedPending}
      title={t("pages:dashboard:modals:savesSearch:title")}
      cancelLabel={
        isFirstStep()
          ? t("pages:dashboard:modals:savesSearch:cancelButton")
          : t("pages:dashboard:modals:savesSearch:backButton")
      }
    >
      <Carousel infinite={false} dots={false} ref={carousel}>
        <div>
          <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
            <Form.Item
              name="name"
              className={s.bbSavedFilterFormItem}
              label={t("pages:dashboard:modals:savesSearch:form:name:label")}
              rules={[
                {
                  required: true,
                  message: t("pages:dashboard:modals:savesSearch:form:name:message"),
                },
              ]}
            >
              <AutoCompleteInput
                size="large"
                id="name_saved_filter"
                onChange={handleChange}
                value={value?.id.toString()}
                options={autocompleteOptions}
                filterOption={(inputValue, option) =>
                  option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                dropdownMatchSelectWidth={false}
              />
            </Form.Item>
            <small style={{ marginBottom: 0 }}>
              {t("components:searchPanel:modals:savesSearch:infoMessage")}
            </small>
          </Form>
        </div>
        <div>
          <Typography.Paragraph>
            {t("components:searchPanel:modals:savesSearch:overwriteMessage")}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Paragraph>
            {t("components:searchPanel:modals:savesSearch:SavedMessage")}
          </Typography.Paragraph>
        </div>
      </Carousel>
    </Modal>
  );
};

export default SaveFilterModal;
