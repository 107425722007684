import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import CommentAction from "../commentAction/CommentAction";

import { canReplyToComment } from "@utils/comments";
import { IComment } from "@store/comments/types";

import { ReplyIcon, ReplyFilledIcon } from "@assets/index";

interface IActionReplyProps {
  className?: string;
  comment: IComment;
  onClickReply?: () => void;
}

const ActionReply: FC<IActionReplyProps> = ({ comment, onClickReply, className = "" }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const getTooltip = () => {
    if (comment.is_deleted) {
      return { title: t("components:reply:deletedReply") };
    }

    if (comment.reply.is_sent) {
      return { title: t("components:reply:showReply") };
    }

    if (!canReplyToComment(comment, comment.is_hidden)) {
      return {
        title: comment.actions.reply_tooltip
          ? comment.actions.reply_tooltip
          : t("components:comments:tooltips:replyActionTooltip", {
              assetName: comment.platform.label,
            }),
      };
    }

    if (
      comment.platform_type.id === "instagram" &&
      comment.actions.can_reply === false &&
      comment.post_placement === "AD"
    ) {
      return {
        title: comment.actions.reply_tooltip || "",
      };
    }

    return { title: t("components:comments:actions:reply") };
  };

  return (
    <CommentAction
      className={className}
      onClick={onClickReply}
      active={comment.actions.can_reply && comment.reply.is_sent}
      type={!comment.actions.can_reply ? "disabled" : "primary"}
      tooltipProps={getTooltip()}
    >
      <>
        {comment.reply.is_sent && comment.actions.can_reply ? <ReplyFilledIcon /> : <ReplyIcon />}

        {screens.sm ? (
          comment.reply.is_sent ? (
            <span>{t("components:comments:actions:replied")}</span>
          ) : (
            <span>{t("components:comments:actions:reply")}</span>
          )
        ) : null}
      </>
    </CommentAction>
  );
};

export default ActionReply;
