import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import {
  getAlertsActionSuccess,
  getAlertsActionFailure,
  getAlertCommentsActionSuccess,
  getAlertCommentsActionFailure,
} from "./actions";
import { getAlertTypesActionSuccess } from "../alertTypes/actions";

import { RootState } from "../";
import { alerts } from "@utils/paths";
import { IComment } from "../comments/types";
import { AlertsActions, IAlertsResponse } from "./types";
import { GET_ALERTS, GET_ALERT_COMMENTS } from "./actionTypes";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const getAlertsEpic = (
  action$: Observable<AlertsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_ALERTS)),
    switchMap(a => {
      return ajax
        .post<IAlertsResponse>(
          alerts,
          {
            ...a.payload,
            date_filter: {
              ...a.payload.date_filter,
              start_time: a.payload.date_filter.start_time?.format("YYYY-MM-DDTHH:mm:ssZ"),
              end_time: a.payload.date_filter.end_time?.format("YYYY-MM-DDTHH:mm:ssZ"),
            },
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(({ items, alert_types, cursor }) => [
            getAlertsActionSuccess(items, cursor),
            getAlertTypesActionSuccess(alert_types),
          ]),
          catchError(e => handleError(e, getAlertsActionFailure)),
        );
    }),
  );

export const getAlertCommentsEpic = (
  action$: Observable<AlertsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_ALERT_COMMENTS)),
    switchMap(a => {
      return ajax
        .get<{ items: IComment[] }>(
          `${alerts}/${a.payload}/comments`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(({ items }) => getAlertCommentsActionSuccess(items)),
          catchError(e => handleError(e, getAlertCommentsActionFailure)),
        );
    }),
  );
