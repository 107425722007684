import { StateObservable } from "redux-observable";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, switchMap, filter, map, tap, throttleTime } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { assignToMe as assignToMePath } from "@utils/paths";

import { assignToMeFailureAction, assignToMeSuccessAction } from "./actions";

import { ASSIGN_TO_ME } from "./actionTypes";
import { AssignActions } from "./types";
import { RootState } from "../index";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const assignToMeEpic = (
  action$: Observable<AssignActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(ASSIGN_TO_ME)),
    throttleTime(1000),
    switchMap(({ payload: { filters, list_type, onSuccess } }) =>
      ajax
        .post(
          assignToMePath,
          { filters, list_type },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          tap(() => onSuccess?.()),
          map(assignToMeSuccessAction),
          catchError(e => handleError(e, assignToMeFailureAction)),
        ),
    ),
  );
