import { useTranslation } from "react-i18next";
import { FormInstance } from "antd/lib/form";
import React, { useEffect } from "react";

import { Icon, CloseIcon, useSearchInput } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Keywords.module.less";

import { IFilters } from "@store/filters/types";

export interface IKeywordSearchProps {
  name: string;
  form: FormInstance<IFilters>;
  chipCount?: number;
  setSearchValue: (value: string) => void;
}

export const KeywordSearch = ({ name, form, chipCount, setSearchValue }: IKeywordSearchProps) => {
  const { t } = useTranslation();

  const { SearchInput, searchTerm } = useSearchInput(t("generic:search"));

  const onClearAll = () => {
    form.setFieldsValue({ [name]: [] });
  };

  useEffect(() => {
    setSearchValue(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <div className={s.bbKeywordsSearch}>
      {SearchInput}
      <button
        type="button"
        onClick={onClearAll}
        disabled={chipCount === 0}
        className={s.bbKeywordsSearchBtn}
      >
        <span>
          <Icon _size="sm" icon={CloseIcon} className={s.bbKeywordsSearchIcon} />
        </span>
        {t("generic:clearAll")}

        {chipCount ? <span className={s.bbKeywordsSearchCount}>({chipCount})</span> : null}
      </button>
    </div>
  );
};
