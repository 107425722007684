import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { FC, useState, useEffect } from "react";

import { Empty } from "antd";
import ReactResizeDetector from "react-resize-detector";
import { BarChart, Loading, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./KeyPhrasesTrends.module.less";

import {
  IKeywordPhrasesProps,
  IKeywordPhrasesChartItem,
  ITopInfluencersItem,
} from "@store/overview/types";
import { stripePattern, getSentimentBars } from "../../helpers";
import { useTiktokEnabled } from "@utils/useTiktokEnabled";
import { IKeyPhrasesTrendsProps, IAxisTick } from "./KeyPhrasesTrends.type";
import { IChartLabelClickEvent } from "../../topInfluencers/topInfluencersChart/TopInfluencersChart.type"; //eslint-disable-line max-len

const KeyPhrasesTrends: FC<IKeyPhrasesTrendsProps> = ({ data, loading, filters }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isTiktokEnabled = useTiktokEnabled();

  const [bars, setBars] = useState<ITopInfluencersItem[] | null>(null);
  const [keywordSentiment, setKeywordSentiment] = useState("all");
  const [maxLength, setMaxLength] = useState(0);

  useEffect(() => {
    if (data) setBars(data);
  }, [data]);

  const calculateWidth = (word: string) => {
    if (maxLength < word.length) {
      setMaxLength(word.length);
    }
  };

  const generateChartData = (keyPhrasesData: ITopInfluencersItem[]) => {
    let chartData: IKeywordPhrasesChartItem[] = [];

    const selectedSentiment = keyPhrasesData.find(item => item.sentiment.id === keywordSentiment);

    if (selectedSentiment?.sentiment.items.length) {
      if (keywordSentiment === "all") {
        chartData = selectedSentiment.sentiment.items.map(item => {
          calculateWidth(item.label);

          return {
            ...{
              label: item.label,
              [t(`components:postTrends:charts:positive`)]: item?.sentiment?.positive || 0,
              [t(`components:postTrends:charts:negative`)]: item?.sentiment?.negative || 0,
              [t(`components:postTrends:charts:neutral`)]: item?.sentiment?.neutral || 0,
              [t(`components:postTrends:charts:user_tags`)]: item?.sentiment?.user_tags || 0,
            },
            ...(isTiktokEnabled
              ? {
                  [t(`components:postTrends:charts:not_applicable`)]: 10 || 0,
                }
              : null),
          };
        });
      } else {
        chartData = selectedSentiment.sentiment.items.map(item => {
          return {
            label: item.label,
            [t(`components:postTrends:charts:${keywordSentiment}`)]: item.count,
          };
        });
      }
    }

    return chartData;
  };

  const generateLabel = (props: IAxisTick) => {
    return (
      <text
        className={s.bbRootKeyPhrasesTrendsLabel}
        x={20}
        y={props.y + 2}
        cursor="pointer"
        textDecoration="underline"
      >
        {props.payload.value}
      </text>
    );
  };

  const onclickYAxis = (axis: IChartLabelClickEvent) => {
    navigate("/search", {
      state: {
        filters: {
          ...filters,
          keyword_query: [axis.value],
        },
      },
    });
  };

  const generateTab = () => {
    return (
      <ReactResizeDetector
        handleWidth
        refreshMode={"throttle"}
        refreshOptions={{ leading: true, trailing: true }}
      >
        {(props: { width: number }) => {
          return (
            <BarChart
              yAxis
              xAxis
              tooltip
              height={335}
              barSize={13}
              layout="vertical"
              stackOffset="sign"
              width={props.width}
              data={generateChartData(bars || [])}
              pattern={stripePattern}
              tooltipProps={{ cursor: { fill: "transparent" } }}
              xAxisProps={{ type: "number", hide: true }}
              yAxisProps={{
                dataKey: "label",
                type: "category",
                interval: 0,
                axisLine: false,
                tickLine: false,
                width: maxLength * 10,
                tick: generateLabel,
                onClick: onclickYAxis,
              }}
              bars={getSentimentBars()}
              margin={{ top: 0, right: 30, left: 30, bottom: 0 }}
            />
          );
        }}
      </ReactResizeDetector>
    );
  };

  const onChangeTab = (tabKey: string) => {
    setKeywordSentiment(tabKey as keyof IKeywordPhrasesProps);
  };

  return (
    <div className={s.bbRootKeyPhrasesTrends}>
      <div className={s.bbRootKeyPhrasesTrendsBars}>
        {bars !== null && !loading ? (
          bars.length ? (
            <TabsScrollable
              centered
              noUnderline
              activeKey={keywordSentiment}
              onChange={onChangeTab}
            >
              <TabPane tab={t(`components:analysisTrends:tabs:all`)} tabKey="all">
                {generateTab()}
              </TabPane>
              <TabPane tab={t(`components:analysisTrends:tabs:positive`)} tabKey="positive">
                {generateTab()}
              </TabPane>
              <TabPane tab={t(`components:analysisTrends:tabs:negative`)} tabKey="negative">
                {generateTab()}
              </TabPane>
            </TabsScrollable>
          ) : (
            <Empty
              className={s.bbKeyPhrasesEmpty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("components:empty:description")}
              imageStyle={{
                height: 40,
              }}
            />
          )
        ) : (
          <div className={s.bbRootKeyPhrasesTrendsLoading}>
            <Loading isLoading />
          </div>
        )}
      </div>
    </div>
  );
};

export default KeyPhrasesTrends;
