import { action } from "typesafe-actions";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  POST_NOTIFICATION,
  POST_NOTIFICATION_SUCCESS,
  POST_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  PUT_NOTIFICATION,
  PUT_NOTIFICATION_SUCCESS,
  PUT_NOTIFICATION_FAILURE,
  RESET_NOTIFICATION,
  SUBSCRIBE_NOTIFICATION,
  SUBSCRIBE_NOTIFICATION_SUCCESS,
  SUBSCRIBE_NOTIFICATION_FAILURE,
  UNSUBSCRIBE_NOTIFICATION,
  UNSUBSCRIBE_NOTIFICATION_SUCCESS,
  UNSUBSCRIBE_NOTIFICATION_FAILURE,
} from "./actionTypes";
import { IPostNotificationBody, INotification, INotificationSubscriber } from "./types";

export const getNotificationsAction = () => action(GET_NOTIFICATIONS);
export const getNotificationsActionSuccess = (response: INotification[]) =>
  action(GET_NOTIFICATIONS_SUCCESS, response);
export const getNotificationsActionFailure = () => action(GET_NOTIFICATIONS_FAILURE);

export const postNotificationAction = (body: IPostNotificationBody) =>
  action(POST_NOTIFICATION, body);
export const postNotificationActionSuccess = (response: INotification, overwrite: boolean) =>
  action(POST_NOTIFICATION_SUCCESS, response, { overwrite });
export const postNotificationActionFailure = (data: { status: number }) =>
  action(POST_NOTIFICATION_FAILURE, data);

export const deleteNotificationAction = (id: string) => action(DELETE_NOTIFICATION, id);
export const deleteNotificationActionSuccess = (id: string) =>
  action(DELETE_NOTIFICATION_SUCCESS, id);
export const deleteNotificationActionFailure = () => action(DELETE_NOTIFICATION_FAILURE);

export const putNotificationAction = (id: string, data: IPostNotificationBody) =>
  action(PUT_NOTIFICATION, {
    id: id,
    data: data,
  });
export const putNotificationActionSuccess = (response: INotification) =>
  action(PUT_NOTIFICATION_SUCCESS, response);
export const putNotificationActionFailure = () => action(PUT_NOTIFICATION_FAILURE);

export const subscribeNotificationAction = (id: string, user: INotificationSubscriber) =>
  action(SUBSCRIBE_NOTIFICATION, {
    id: id,
    user: user,
  });
export const subscribeNotificationActionSuccess = (id: string, user: INotificationSubscriber) =>
  action(SUBSCRIBE_NOTIFICATION_SUCCESS, {
    id: id,
    user: user,
  });
export const subscribeNotificationActionFailure = () => action(SUBSCRIBE_NOTIFICATION_FAILURE);

export const unsubscribeNotificationAction = (id: string, userId: string) =>
  action(UNSUBSCRIBE_NOTIFICATION, {
    id: id,
    userId: userId,
  });
export const unsubscribeNotificationActionSuccess = (id: string, userId: string) =>
  action(UNSUBSCRIBE_NOTIFICATION_SUCCESS, {
    id: id,
    userId: userId,
  });
export const unsubscribeNotificationActionFailure = () => action(UNSUBSCRIBE_NOTIFICATION_FAILURE);

export const resetNotificationAction = () => action(RESET_NOTIFICATION);
