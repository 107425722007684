import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DownloadPDFButton.module.less";

import { DownloadIcon } from "@assets/index";

interface IDownloadPDFButtonProps {
  loading: boolean;
  disabled: boolean;
  tooltipText: string;
  onClick: () => void;
}

const DownloadPDFButton: FC<IDownloadPDFButtonProps> = ({
  loading,
  disabled,
  onClick,
  tooltipText,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbDownloadPagePDFWrapper}>
      <Tooltip title={tooltipText}>
        <span>
          <Button
            _size="sm"
            _type="custom"
            loading={loading}
            onClick={onClick}
            disabled={disabled}
            className={s.bbDownloadPagePDFBtn}
          >
            <span className={s.bbDownloadPagePDFBtnIcon}>
              <DownloadIcon />
            </span>
            <span>{t("generic:download")}</span>
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default DownloadPDFButton;
