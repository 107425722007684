import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import {
  PDF_MM_WIDTH,
  PDF_MM_HEIGHT,
  CANVAS_SETTINGS,
  fillBackground,
  drawSlideTitle,
  addSnapshotToCanvas,
} from "./helpers";

import Slide_Intro from "@assets/PDF_Export/Slide_intro.png";
import Slide_Outro from "@assets/PDF_Export/Slide_outro.png";

const generateSentiments = (pdf: jsPDF) => {
  const sentiments = document.getElementById("sentiment-cards");

  if (sentiments) {
    return html2canvas(sentiments, {
      ...CANVAS_SETTINGS,
      windowWidth: 2000,
    }).then(canvas => {
      pdf.addPage("a4", "landscape");
      fillBackground(canvas, pdf);
      drawSlideTitle(pdf, "Sentiment analysis snapshot", "Overview");
      addSnapshotToCanvas(pdf, canvas, 45);
    });
  }
};

const generateCommentEngagements = (pdf: jsPDF) => {
  const commentEngagements = document.getElementById("comment-engagement-cards");

  if (commentEngagements) {
    return html2canvas(commentEngagements, CANVAS_SETTINGS).then(canvas => {
      addSnapshotToCanvas(pdf, canvas, 75);
    });
  }
};

const generateContentSection = (pdf: jsPDF) => {
  const topics = document.getElementById("overview-content-section");

  if (topics) {
    return html2canvas(topics, {
      ...CANVAS_SETTINGS,
      windowWidth: 1700,
    }).then(canvas => {
      pdf.addPage("a4", "landscape");
      fillBackground(canvas, pdf);
      drawSlideTitle(pdf, "How is our content resonating?", "Content", 22);
      addSnapshotToCanvas(pdf, canvas, 40);
    });
  }
};

const generateTopicsSection = (pdf: jsPDF) => {
  const topics = document.getElementById("overview-topics-section");

  if (topics) {
    return html2canvas(topics, {
      ...CANVAS_SETTINGS,
      windowWidth: 2300,
    }).then(canvas => {
      pdf.addPage("a4", "landscape");
      fillBackground(canvas, pdf);
      drawSlideTitle(pdf, "What are people talking about?", "Topics", 20);
      addSnapshotToCanvas(pdf, canvas, 45);
    });
  }
};

const generateAudienceSection = (pdf: jsPDF) => {
  const audience = document.getElementById("overview-audience-section");

  if (audience) {
    return html2canvas(audience, {
      ...CANVAS_SETTINGS,
      windowWidth: 2300,
    }).then(canvas => {
      pdf.addPage("a4", "landscape");
      fillBackground(canvas, pdf);
      drawSlideTitle(pdf, "Who is our audience?", "Audience");
      addSnapshotToCanvas(pdf, canvas, 45);
    });
  }
};

const generateLastSlide = (pdf: jsPDF) => {
  pdf.addPage("a4", "landscape");
  pdf.addImage(Slide_Outro, "PNG", 0, 0, PDF_MM_WIDTH, PDF_MM_HEIGHT);
};

const useOverviewPDFDownload = (docName: string, beforeDownload: () => void) => {
  const pdf = new jsPDF("landscape", "mm", "a4");

  const downloadOverviewPDF = () => {
    pdf.addImage(Slide_Intro, "PNG", 0, 15, PDF_MM_WIDTH, PDF_MM_HEIGHT - 40);

    generateSentiments(pdf)?.then(() => {
      generateCommentEngagements(pdf)?.then(() => {
        generateContentSection(pdf)?.then(() => {
          generateTopicsSection(pdf)?.then(() => {
            generateAudienceSection(pdf)?.then(() => {
              generateLastSlide(pdf);
              beforeDownload();
              pdf.save(`${docName}.pdf`);
            });
          });
        });
      });
    });
  };

  return {
    downloadOverviewPDF,
  };
};

export default useOverviewPDFDownload;
