import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { Toggle } from "@bbdevcrew/bb_ui_kit_fe";

import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { IQuickAutomationsItemProps } from "./types";
import { patchMeClientAction } from "@store/me/actions";

import s from "./QuickAutomations.module.less";

import { ArrowUpIcon } from "@assets/index";

export const QuickAutomations: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className={s.bbQuickAutomationsWrapper}>
      <div className={s.bbQuickAutomationsTitle}>{t("components:quickAutomations:title")}</div>
      <div className="w-full">{children}</div>
    </div>
  );
};

export const QuickAutomationsItem: React.FC<IQuickAutomationsItemProps> = ({
  children,
  name,
  checked = false,
  leftIcon,
  rightIcon,
  leftIconColorType = "info",
  rightIconColorType = "info",
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(checked);

  const onToggleChange = () => {
    dispatch(
      patchMeClientAction({
        auto_action_config: {
          [name]: !isChecked,
        },
      }),
    );
  };

  const onToggle = () => {
    setIsChecked(!isChecked);
    onToggleChange();
    addToast({
      type: "success_accent",
      title: t(
        `components:quickAutomations:${name}:notification:${!isChecked ? "enabled" : "disabled"}`,
      ),
    });
  };

  return (
    <>
      <div className={s.bbQuickAutomation}>
        <div className="flex">
          <span
            className={classNames(
              s.bbQuickAutomationItemIcon,
              s[`bbQuickAutomationItemIcon-${leftIconColorType}`],
              {
                [s.bbQuickAutomationItemIconChecked]: isChecked,
              },
            )}
          >
            {leftIcon}
          </span>
          <span className={s.bbQuickAutomationItemRightArrow}>
            <ArrowUpIcon />
          </span>
          <span
            className={classNames(
              s.bbQuickAutomationItemIcon,
              s[`bbQuickAutomationItemIcon-${rightIconColorType}`],
              {
                [s.bbQuickAutomationItemIconChecked]: isChecked,
              },
            )}
          >
            {rightIcon}
          </span>
        </div>
        <Toggle id={name} _size="md" checked={isChecked} onChange={onToggle} />
      </div>
      <div className={s.bbQuickAutomationItemDescription}>{children}</div>
    </>
  );
};
