import {
  AdIcon,
  BrokenLinkIcon,
  FacebookColorIcon,
  InstagramColorIcon,
  LinkedinColorIcon,
  OrganicIcon,
  RocketIcon,
  TrustPilotColorIcon,
  YoutubeColorIcon,
  GoogleBusinessIcon,
} from "@assets/index";
import { TiktokColorIcon, TwitterColorIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const PlatformTypeIcon = {
  facebook: FacebookColorIcon,
  instagram: InstagramColorIcon,
  twitter: TwitterColorIcon,
  youtube: YoutubeColorIcon,
  tiktok: TiktokColorIcon,
  linkedin: LinkedinColorIcon,
  trustpilot: TrustPilotColorIcon,
  google_business: GoogleBusinessIcon,
};

export const PostTypeIcon = {
  pure_organic: OrganicIcon,
  pure_ads: AdIcon,
  unlinked_ads: BrokenLinkIcon,
  boosted_posts: RocketIcon,
};
