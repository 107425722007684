export const GET_NOTIFICATIONS = "NOTIFICATIONS/GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "NOTIFICATIONS/GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "NOTIFICATIONS/GET_NOTIFICATIONS_FAILURE";

export const POST_NOTIFICATION = "NOTIFICATIONS/POST_NOTIFICATION";
export const POST_NOTIFICATION_SUCCESS = "NOTIFICATIONS/POST_NOTIFICATION_SUCCESS";
export const POST_NOTIFICATION_FAILURE = "NOTIFICATIONS/POST_NOTIFICATION_FAILURE";

export const DELETE_NOTIFICATION = "NOTIFICATIONS/DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "NOTIFICATIONS/DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "NOTIFICATIONS/DELETE_NOTIFICATION_FAILURE";

export const PUT_NOTIFICATION = "NOTIFICATIONS/PUT_NOTIFICATION";
export const PUT_NOTIFICATION_SUCCESS = "NOTIFICATIONS/PUT_NOTIFICATION_SUCCESS";
export const PUT_NOTIFICATION_FAILURE = "NOTIFICATIONS/PUT_NOTIFICATION_FAILURE";

export const SUBSCRIBE_NOTIFICATION = "NOTIFICATIONS/SUBSCRIBE_NOTIFICATION";
export const SUBSCRIBE_NOTIFICATION_SUCCESS = "NOTIFICATIONS/SUBSCRIBE_NOTIFICATION_SUCCESS";
export const SUBSCRIBE_NOTIFICATION_FAILURE = "NOTIFICATIONS/SUBSCRIBE_NOTIFICATION_FAILURE";

export const UNSUBSCRIBE_NOTIFICATION = "NOTIFICATIONS/UNSUBSCRIBE_NOTIFICATION";
export const UNSUBSCRIBE_NOTIFICATION_SUCCESS = "NOTIFICATIONS/UNSUBSCRIBE_NOTIFICATION_SUCCESS";
export const UNSUBSCRIBE_NOTIFICATION_FAILURE = "NOTIFICATIONS/UNSUBSCRIBE_NOTIFICATION_FAILURE";

export const RESET_NOTIFICATION = "NOTIFICATIONS/RESET_NOTIFICATION";
