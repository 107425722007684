import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, useState, useCallback } from "react";

import PresetList from "./PresetList";
import CustomReplyList from "./CustomReplyList";
import SavedReplyEmptyView from "./SavedReplyEmptyView";
import { TabPane, TabsScrollable, Button, InputWhiteBordered } from "@bbdevcrew/bb_ui_kit_fe";

import { getSavedRepliesSelector, searchedSavedRepliesSelector } from "@store/replies/selectors";
import { postSavedRepliesAction } from "@store/replies/actions";

import { debounce } from "@bbdevcrew/bb_ui_kit_fe";
import { ViewChangeFunc } from "../common/modal/types";
import { DEBOUNCE_WAIT_TIME, SavedReplyTabKeys } from "./helper";

import s from "./SavedReplies.module.less";

import { PlusIcon } from "@assets/index";

export interface ISavedReplyListProps {
  onViewChange: ViewChangeFunc;
  onReplyClick?: (message: string) => void;
}

const SavedReplyList: React.FC<ISavedReplyListProps> = ({ onViewChange, onReplyClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<string>(SavedReplyTabKeys.CustomReplies);
  const [searchValue, setSearchValue] = useState("");

  const savedReplies = useSelector(getSavedRepliesSelector);
  const searchedSavedReplies = useSelector(searchedSavedRepliesSelector);

  // eslint-disable-next-line
  const fetchSavedReplies = useCallback(
    debounce((params?: { query?: string; presetQuery?: string }) => {
      dispatch(postSavedRepliesAction(params?.query, params?.presetQuery));
    }, DEBOUNCE_WAIT_TIME),
    [dispatch],
  );

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchValue(value);

    switch (selectedTab) {
      case SavedReplyTabKeys.CustomReplies:
        fetchSavedReplies({ query: value });
        break;
      case SavedReplyTabKeys.Presets:
        fetchSavedReplies({ presetQuery: value });
        break;
    }
  };

  const onChangeTab = (tab: string) => {
    setSelectedTab(tab);
    setSearchValue("");
    fetchSavedReplies();
  };

  if (
    !searchedSavedReplies &&
    savedReplies?.items?.length === 0 &&
    savedReplies?.presets?.length === 0
  )
    return <SavedReplyEmptyView onViewChange={onViewChange} />;

  return (
    <>
      <div className={s.bbSavedReplyHeader}>
        <div className={s.bbSavedReplyHeaderTitle}>
          {t("components:reply:savedReplies:list:title")}
        </div>
        <div className={s.bbSavedReplyHeaderActions}>
          {selectedTab === SavedReplyTabKeys.CustomReplies && (
            <Button
              _type="primary"
              onClick={() => onViewChange("create")}
              className={s.bbSavedReplyHeaderActionsCreateBtn}
            >
              <PlusIcon />
            </Button>
          )}
          <InputWhiteBordered
            value={searchValue}
            placeholder={t("components:reply:savedReplies:list:searchPlaceHolder", {
              suffix:
                selectedTab === SavedReplyTabKeys.CustomReplies
                  ? t("components:reply:savedReplies:list:customReplies")
                  : t("components:reply:savedReplies:list:presets"),
            })}
            onChange={onChangeSearchValue}
          />
        </div>
      </div>
      <TabsScrollable
        activeKey={selectedTab}
        onChange={onChangeTab}
        defaultActiveKey={SavedReplyTabKeys.CustomReplies}
      >
        <TabPane
          tab={t("components:reply:savedReplies:list:customReplies")}
          tabKey={SavedReplyTabKeys.CustomReplies}
        >
          <CustomReplyList onViewChange={onViewChange} onReplyClick={onReplyClick} />
        </TabPane>

        {!!savedReplies?.presets?.length && (
          <TabPane
            tab={t("components:reply:savedReplies:list:presets")}
            tabKey={SavedReplyTabKeys.Presets}
          >
            <PresetList onViewChange={onViewChange} onReplyClick={onReplyClick} />
          </TabPane>
        )}
      </TabsScrollable>
    </>
  );
};

export default SavedReplyList;
