import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SavedReplies.module.less";

import { ISavedReplyListProps } from "./SavedReplyList";

import { PlusIcon, SavedRepliesIcon } from "@assets/index";

export default function SavedReplyEmptyView({ onViewChange }: ISavedReplyListProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className={s.bbSavedReplyHeader}>
        <div className={s.bbSavedReplyHeaderTitle}>
          {t("components:reply:savedReplies:list:title")}
        </div>
      </div>
      <div className={s.bbSavedReplyEmptyContainer}>
        <SavedRepliesIcon />
        <div className={s.bbSavedReplyEmptyDescription}>
          {t("components:reply:savedReplies:empty:contentDescription")}
        </div>

        <Button
          _type="primary"
          className={s.bbSavedReplyEmptyCreateButton}
          onClick={() => onViewChange("create")}
        >
          <PlusIcon />
          {t("components:reply:savedReplies:empty:action")}
        </Button>
      </div>
    </>
  );
}
