import React, { MouseEvent } from "react";

import {
  transformOperatorToChip,
  InputChipQueryOperatorsEnum,
} from "../AppFilter/inputChip/InputChipQueryConfig";
import { IChipListActionProps } from "./ChipList";
import { NESTING_COMMAND_KEYS } from "../AppFilter/inputChip/InputChipConfig";
import { ChipAction, ChipType as ChipConfigType } from "@bbdevcrew/bb_ui_kit_fe";

const ChipType: { [key: string]: keyof typeof ChipConfigType } = {
  [InputChipQueryOperatorsEnum.OR as string]: "default" as keyof typeof ChipConfigType,
  [InputChipQueryOperatorsEnum.AND as string]: "success" as keyof typeof ChipConfigType,
  [InputChipQueryOperatorsEnum.AND_NOT as string]: "error" as keyof typeof ChipConfigType,
  [InputChipQueryOperatorsEnum.OR_NOT as string]: "error" as keyof typeof ChipConfigType,
  [InputChipQueryOperatorsEnum.NOT as string]: "error" as keyof typeof ChipConfigType,
};

export const ChipListQuery = React.memo(function ({
  _size,
  value,
  renderAddChip,
  onActionClick,
}: IChipListActionProps) {
  return (
    <div className="flex max-h-32 w-full flex-row flex-wrap gap-2 overflow-y-scroll py-2">
      {value.map((chip, idx) => {
        return NESTING_COMMAND_KEYS.includes(chip) ? (
          <span key={idx}>{chip}</span>
        ) : (
          <ChipAction
            key={idx}
            _size={_size}
            text={transformOperatorToChip(chip)}
            _type={ChipType[chip as keyof typeof InputChipQueryOperatorsEnum]}
            onActionClick={(event: MouseEvent<HTMLButtonElement>) => {
              // Could be too implicit. Remove if event needs to be bubbled up.
              event.preventDefault();
              return onActionClick(chip, idx);
            }}
          />
        );
      })}
      {renderAddChip && renderAddChip()}
    </div>
  );
});

ChipListQuery.displayName = "ChipListQuery";
