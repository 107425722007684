import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";

import { RootState } from "../";
import { DocumentActions, IDocumentPayload, IDocumentUrlPayload } from "./types";
import { document, documentUrl } from "@utils/paths";
import { GET_DOCUMENT, GET_DOCUMENT_URL } from "./actionTypes";
import {
  getDocumentsFailure,
  getDocumentsSuccess,
  getDocumentsUrlSuccess,
  getDocumentsUrlFailure,
} from "./actions";
import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";

export const getDocument = (
  action$: Observable<DocumentActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_DOCUMENT)),
    switchMap(() =>
      ajax
        .get<IDocumentPayload>(
          `${document}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getDocumentsSuccess(data)),
          catchError(err => handleError(err, getDocumentsFailure)),
        ),
    ),
  );

export const getDocumentUrl = (
  action$: Observable<DocumentActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_DOCUMENT_URL)),
    switchMap(a =>
      ajax
        .get<IDocumentUrlPayload>(
          `${documentUrl(a.payload)}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getDocumentsUrlSuccess(data)),
          catchError(err => handleError(err, getDocumentsUrlFailure)),
        ),
    ),
  );
