import { StateObservable } from "redux-observable";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import * as actions from "./actions";
import { POST_STATS } from "./actionTypes";

import { Actions, IDataStatsProps } from "./types";

import { stats } from "@utils/paths";
import { RootState } from "../";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const postStatsEpic = (action$: Observable<Actions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(POST_STATS)),
    switchMap(a => {
      return ajax
        .post<IDataStatsProps>(
          stats,
          {
            filters: a.payload.data,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.postStatsActionSuccess(data)),
          catchError(e => handleError(e, actions.postStatsActionFailure)),
        );
    }),
  );
