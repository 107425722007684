import { action } from "typesafe-actions";
import { IFilters } from "@store/filters/types";
import { POST_LANGUAGES, POST_LANGUAGES_SUCCESS, POST_LANGUAGES_FAILURE } from "./actionTypes";
import { ILanguage } from "./types";

export const postLanguagesAction = (data: IFilters, term: string) =>
  action(POST_LANGUAGES, {
    filters: data,
    term: term,
  });
export const postLanguagesSuccessAction = (languages: ILanguage[]) =>
  action(POST_LANGUAGES_SUCCESS, languages);
export const postLanguagesFailureAction = () => action(POST_LANGUAGES_FAILURE);
