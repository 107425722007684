import { action } from "typesafe-actions";
import {
  GET_MODERATION_STREAMS,
  GET_MODERATION_STREAMS_SUCCESS,
  GET_MODERATION_STREAMS_FAILURE,
  GET_MODERATION_STREAMS_COMMENTS,
  GET_MODERATION_STREAMS_COMMENTS_SUCCESS,
  GET_MODERATION_STREAMS_COMMENTS_FAILURE,
} from "./actionTypes";
import { IModerationStream, IStreamCommentPayload } from "./types";
import { IComment } from "../comments/types";

export const getModerationStreamsAction = () => action(GET_MODERATION_STREAMS);
export const getModerationStreamsActionSuccess = (streams: IModerationStream[]) =>
  action(GET_MODERATION_STREAMS_SUCCESS, streams);
export const getModerationStreamsActionFailure = () => action(GET_MODERATION_STREAMS_FAILURE);

export const getModerationStreamsCommentsAction = (payload: IStreamCommentPayload) =>
  action(GET_MODERATION_STREAMS_COMMENTS, payload);
export const getModerationStreamsCommentsActionSuccess = (comments: IComment[]) =>
  action(GET_MODERATION_STREAMS_COMMENTS_SUCCESS, comments);
export const getModerationStreamsCommentsActionFailure = () =>
  action(GET_MODERATION_STREAMS_COMMENTS_FAILURE);
