import React from "react";
import {
  IAgentPerformanceResponseStat,
  IAgentPerformanceUserStat,
  IOverviewPerformanceChartItem,
} from "@store/dashboard/types";
import { i18nextInstance } from "../../../../App";
import { Tooltip, UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AgentPerformance.module.less";

import { cropText } from "@utils/cropText";

const MAX_USERNAME_LENGTH = 18;

export const goToPath = (path: string) => {
  window.location.href = `${window.location.origin}${path}`;
};

export const dateToNumber = (date: string) => {
  let days = 0;
  let splitHours;
  const splitDays = date.split("d");

  if (splitDays.length === 2) {
    days = parseFloat(splitDays[0]);
    splitHours = splitDays[1].split("h");
  } else {
    days = 0;
    splitHours = splitDays[0].split("h");
  }

  const hours = parseFloat(splitHours[0]) || 0;
  const splitMinutes = splitHours[1].split("m");
  const minutes = parseFloat(splitMinutes[0]) || 0;

  return days * 24 + hours + minutes / 60;
};

export const normalizePerformanceData = (stats?: IAgentPerformanceResponseStat) => {
  return {
    change_percentage: stats?.change_percentage || 0,
    percentage_of_total: 0,
    count: stats?.pretty_count || stats?.count || 0,
  };
};

export const normalizeUserStatsCommentsData = (
  color: string,
  userStats?: IAgentPerformanceUserStat[],
  showName?: boolean,
) => {
  if (userStats) {
    return userStats?.map(userStat => {
      const isUsernameLong = userStat.name.length > MAX_USERNAME_LENGTH;

      return {
        id: userStat.id,
        name: (
          <>
            <UserAvatar
              img={userStat.avatar}
              lastName={userStat.name.split(" ")[0]}
              firstName={userStat.name.split(" ")[1]}
              className={s.bbUserStatsAvatar}
            />
            {showName && (
              <Tooltip title={showName && isUsernameLong ? userStat.name : ""}>
                {cropText(userStat.name, MAX_USERNAME_LENGTH)}
              </Tooltip>
            )}
          </>
        ),
        count: userStat.count,
        change_percentage: userStat.count_change_percentage,
        percentage_of_total: userStat.count_percentage_of_total,
        category: userStat.id,
        category_name: userStat.name,
        color: color,
      } as IOverviewPerformanceChartItem;
    });
  }
  return [];
};

export const normalizeUserStatsTimeData = (
  color: string,
  userStats?: IAgentPerformanceUserStat[],
) => {
  if (userStats) {
    return userStats?.map(userStat => {
      return {
        id: userStat.id,
        name: userStat.name,
        count: dateToNumber(userStat.duration),
        count_pretty: userStat.duration,
        change_percentage: userStat.duration_change_percentage,
        category: userStat.id,
        category_name: userStat.name,
        color: color,
      };
    });
  }
  return [];
};

export const getUserStatsCommentsLegend = (color: string) => [
  {
    id: "repliesIssued",
    label: i18nextInstance.t(
      "components:agentPerformance:responseIssuedAndTime:legend:repliesIssued",
    ),
    color: color,
  },
];

export const getUserStatsTimeLegend = (color: string) => [
  {
    id: "responseTime",
    label: i18nextInstance.t(
      "components:agentPerformance:responseIssuedAndTime:legend:responseTime",
    ),
    color: color,
  },
];
