import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { AggregationActions, IBreakdownUnit } from "./types";
import { RootState } from "../";
import { GET_AGGREGATIONS } from "./actionTypes";
import { getAggregationsFailureAction, getAggregationsSuccessAction } from "./actions";

import { aggregations } from "@utils/paths";
import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";

export const getAggregations = (
  action$: Observable<AggregationActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_AGGREGATIONS)),
    switchMap(a => {
      return ajax
        .post<{ items: IBreakdownUnit[] }>(
          aggregations,
          {
            ...a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getAggregationsSuccessAction(data.items)),
          catchError(e => handleError(e, getAggregationsFailureAction)),
        );
    }),
  );
