import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Post from "./Post";
import { Grid, Avatar } from "antd";
import { Modal } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import { IPostModalProps } from "./Post.type";
import { isPostMention } from "@utils/post";
import { PlatformType } from "@store/platform/types";

const MAX_TAG_LIST_LENGTH = 20;

const PostModal: React.FC<IPostModalProps> = ({
  isOpen,
  post,
  onClose,
  isLoading,
  bars,
  stats,
  showPostTags = false,
}) => {
  const screens = Grid.useBreakpoint();
  const me = useSelector(meSelector);
  const { t } = useTranslation();

  const bodyStyle = useMemo(() => {
    if (!screens.md) {
      return {
        height: "100%",
      };
    }
  }, [screens.md]);

  const getTitle = () => {
    const twitterNotOwnedPost = post && !post?.owned && post?.platform_type.id.includes("twitter");
    const isIGMentionPost = post && isPostMention(post);

    if (twitterNotOwnedPost || isIGMentionPost) {
      return post.author_name;
    }

    return me?.client?.name;
  };

  const getTitlePrefix = () => {
    if (post && isPostMention(post)) return;

    return <Avatar shape="circle" size={32} src={me?.client?.logo} />;
  };

  return (
    <Modal
      centered
      responsive
      hideFooter
      theme="light"
      bodyStyle={bodyStyle}
      width={582}
      destroyOnClose={true}
      visible={isOpen}
      title={getTitle()}
      onCancel={onClose}
      titlePrefix={getTitlePrefix()}
    >
      <Post
        post={post}
        isLoading={isLoading}
        showPostTags={showPostTags}
        maxTagListLength={MAX_TAG_LIST_LENGTH}
        platform={post?.platform_type?.id as PlatformType}
        texts={{
          postId: t("components:comments:post:postIdLabel"),
          assetId: t("components:comments:post:assetIdLabel"),
          postType: t("components:comments:post:postTypeLabel"),
          linkToPost: t("components:comments:post:linkToPost"),
          spinText: t("pages:loading:spinText"),
        }}
        tooltips={{
          postId: t("components:comments:post:tooltips:post_id"),
          assetId: t("components:comments:post:tooltips:asset_id"),
          postType: t("components:comments:post:tooltips:post_type"),
        }}
        bars={bars}
        stats={stats}
      />
    </Modal>
  );
};

export default PostModal;
