import { createReducer, ActionType } from "typesafe-actions";
import { ISavedFilterState } from "./types";

import {
  getPredefinedFiltersSuccessAction,
  postSavedFilterSuccessAction,
  postSavedFilterFailureAction,
  deleteSavedFilterSuccessAction,
} from "./actions";

import {
  GET_PREDEFINED_FILTERS,
  GET_PREDEFINED_FILTERS_SUCCESS,
  GET_PREDEFINED_FILTERS_FAILURE,
  POST_SAVED_FILTER,
  POST_SAVED_FILTER_SUCCESS,
  POST_SAVED_FILTER_FAILURE,
  DELETE_SAVED_FILTER,
  DELETE_SAVED_FILTER_SUCCESS,
  DELETE_SAVED_FILTER_FAILURE,
  RESET_SAVED_FILTERS_DATA,
} from "./actionTypes";

const initialState = {
  predefinedFilters: [],
  isOverWrite: false,
  hasAlerts: false,
  fetchingGetPredefinedFilters: false,
  fetchedGetPredefinedFilters: false,
  fetchedGetPredefinedFiltersFail: false,
  fetchingPostSavedFilter: false,
  fetchedPostSavedFilter: false,
  fetchedPostSavedFilterFail: false,
  fetchingDeleteSavedFilter: false,
  fetchedDeleteSavedFilter: false,
  fetchedDeleteSavedFilterFail: false,
};

export const savedFiltersReducer = createReducer<ISavedFilterState>(initialState, {
  [GET_PREDEFINED_FILTERS]: (state: ISavedFilterState) => ({
    ...state,
    fetchingGetPredefinedFilters: true,
    fetchedGetPredefinedFilters: false,
    fetchedGetPredefinedFiltersFail: false,
  }),
  [GET_PREDEFINED_FILTERS_SUCCESS]: (
    state: ISavedFilterState,
    action: ActionType<typeof getPredefinedFiltersSuccessAction>,
  ) => {
    return {
      ...state,
      predefinedFilters: action.payload,
      fetchingGetPredefinedFilters: false,
      fetchedGetPredefinedFilters: true,
      fetchedGetPredefinedFiltersFail: false,
    };
  },
  [GET_PREDEFINED_FILTERS_FAILURE]: (state: ISavedFilterState) => ({
    ...state,
    fetchingGetPredefinedFilters: false,
    fetchedGetPredefinedFilters: false,
    fetchedGetPredefinedFiltersFail: true,
  }),
  // POST
  [POST_SAVED_FILTER]: (state: ISavedFilterState) => ({
    ...state,
    fetchingPostSavedFilter: true,
    fetchedPostSavedFilter: false,
    fetchedPostSavedFilterFail: false,
    isOverWrite: false,
    hasAlerts: false,
  }),
  [POST_SAVED_FILTER_SUCCESS]: (
    state: ISavedFilterState,
    action: ActionType<typeof postSavedFilterSuccessAction>,
  ) => {
    const newPredefinedFilters = [...state.predefinedFilters];
    const savedFiltersIndex = state.predefinedFilters.findIndex(({ id }) => id === "savedFilters");
    const newSavedFilters = { ...newPredefinedFilters[savedFiltersIndex] };

    // Update saved filter itself
    if (action.meta.overwrite) {
      const smIndex = newSavedFilters.items.findIndex(sm => sm.id === action.payload.id);
      if (smIndex !== -1) {
        newSavedFilters.items[smIndex] = action.payload;
      }
    } else {
      newSavedFilters.items.push(action.payload);
    }

    // Update predefined saved filters
    if (savedFiltersIndex !== -1) {
      newPredefinedFilters[savedFiltersIndex] = newSavedFilters;
    }

    return {
      ...state,
      predefinedFilters: newPredefinedFilters,
      fetchingPostSavedFilter: false,
      fetchedPostSavedFilter: true,
      fetchedPostSavedFilterFail: false,
      isOverWrite: false,
      hasAlerts: false,
    };
  },
  [POST_SAVED_FILTER_FAILURE]: (
    state: ISavedFilterState,
    action: ActionType<typeof postSavedFilterFailureAction>,
  ) => {
    return {
      ...state,
      isOverWrite: action.payload.status === 409,
      hasAlerts: action.payload.response.has_alerts,
      fetchingPostSavedFilter: false,
      fetchedPostSavedFilter: false,
      fetchedPostSavedFilterFail: true,
    };
  },
  [DELETE_SAVED_FILTER]: (state: ISavedFilterState) => ({
    ...state,
    fetchingDeleteSavedFilter: true,
    fetchedDeleteSavedFilter: false,
    fetchedDeleteSavedFilterFail: false,
  }),
  [DELETE_SAVED_FILTER_SUCCESS]: (
    state: ISavedFilterState,
    action: ActionType<typeof deleteSavedFilterSuccessAction>,
  ) => {
    const newPredefinedFilters = [...state.predefinedFilters];

    const savedFiltersIndex = state.predefinedFilters.findIndex(({ id }) => id === "savedFilters");
    const newSavedFilters = { ...newPredefinedFilters[savedFiltersIndex] };

    newSavedFilters.items = newSavedFilters.items.filter(sm => {
      return sm.id.toString() !== action.payload.toString();
    });

    // Update predefined saved filters
    if (savedFiltersIndex !== -1) {
      newPredefinedFilters[savedFiltersIndex] = newSavedFilters;
    }

    return {
      ...state,
      predefinedFilters: newPredefinedFilters,
      fetchingDeleteSavedFilter: false,
      fetchedDeleteSavedFilter: true,
      fetchedDeleteSavedFilterFail: false,
    };
  },
  [DELETE_SAVED_FILTER_FAILURE]: (state: ISavedFilterState) => ({
    ...state,
    fetchingDeleteSavedFilter: false,
    fetchedDeleteSavedFilter: false,
    fetchedDeleteSavedFilterFail: true,
  }),
  [RESET_SAVED_FILTERS_DATA]: (state: ISavedFilterState) => ({
    ...state,
    fetchingGetSavedFilters: false,
    fetchedGetSavedFilters: false,
    fetchedGetSavedFiltersFail: false,
    fetchingPostSavedFilter: false,
    fetchedPostSavedFilter: false,
    fetchedPostSavedFilterFail: false,
    fetchingDeleteSavedFilter: false,
    fetchedDeleteSavedFilter: false,
    fetchedDeleteSavedFilterFail: false,
    fetchingGetCompares: false,
    fetchedGetCompares: false,
    fetchedGetComparesFail: false,
    fetchingToggleCompares: false,
    fetchedToggleCompares: false,
    fetchedToggleComparesFail: false,
  }),
});
