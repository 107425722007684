import { IInboxStats } from "@store/inboxStats/types";
import { ReplyPlatformType } from "@store/comments/types";

export type AssignToMeClickHandlerType = (assign_from_tab: string, closeModal: () => void) => void;

export type OnTabChangeHandlerType = (tab: keyof IInboxStats) => void;

export interface IInboxCommentsHeaderProps {
  sortValue?: string;
  statTabParent?: string;
  statTabValue: keyof IInboxStats;
  replyPlatformTypeValue: ReplyPlatformType;
  onClickAssignToMe: AssignToMeClickHandlerType;
  onSortChange: (value: string) => void;
  onReplyPlatformChange: (value: string) => void;
}

export enum RepliedMapEnum {
  all = "replied",
  brandbastion = "replied_in_bb",
  platform = "replied_in_platform",
}
