export const parseCookies = (cookie: string) => {
  return cookie.split(";").reduce((res: { [key: string]: string | undefined }, c: string) => {
    const [key, val] = c.trim().split("=").map(decodeURIComponent);
    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});
};

export const deleteAllCookies = () => {
  const allCookies = document.cookie.split(";");

  // The "expire" attribute of every cookie is
  // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  for (let i = 0; i < allCookies.length; i++)
    document.cookie =
      allCookies[i] +
      `=;domain=${
        process.env.AWS_AMPLIFY_COOKIE_STORAGE_DOMAIN
      };expires=${new Date(0).toUTCString()};`;
};

export const deleteCognitoCookies = () => {
  const allCookies = document.cookie.split(";");
  // TODO: remove the hardcoded domain (end of december)
  for (let i = 0; i < allCookies.length; i++) {
    if (allCookies[i].includes("Cognito")) {
      document.cookie =
        allCookies[i] + `=;domain=.brandbastion.com;expires=${new Date(0).toUTCString()};path=/`;
    }
  }
};

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();

  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    `;domain=${process.env.AWS_AMPLIFY_COOKIE_STORAGE_DOMAIN};path=/`;
};

export const getCookie = (cname: string) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
