import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import ErrorPosting from "./ErrorPosting";
import PublishingPostImagePreview from "./PreviewImage";
import PublishingPostVideoPreview from "./PreviewVideo";
import PublishingPostPDFPreview from "./PreviewPDF";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./PublishingPost.module.less";

import { IPublishingPost } from "@store/publishings/types";
import { getPlatformIconRoundFilled } from "@utils/platform";
import { getPostMediaTypeIcon } from "../../../_common/PostPreviewTooltip/helpers";

import { EditIcon } from "@assets/index";
import TimeIcon from "@assets/Time.svg";
import CheckmarkIcon from "@assets/Checkmark.svg";

interface IPublishingPostBodyProps {
  post: IPublishingPost;
}

export default function PublishingPostBody({ post }: IPublishingPostBodyProps) {
  const { t } = useTranslation();

  const postDraftBadge = (
    <div className={s.bbPostDraftBadge}>
      <EditIcon />
      {t("generic:draft")}
    </div>
  );

  const postPublishingTime = (
    <div className={s.bbPostTime}>
      {post.publishing_status === "published" ? (
        <CheckmarkIcon />
      ) : post.publishing_status === "pending" ? (
        <TimeIcon />
      ) : null}
      {moment.unix(post.created_time).format("hh:mm A")}
    </div>
  );

  return (
    <>
      {!!post.picture ? (
        <PublishingPostImagePreview post={post} />
      ) : post.attachments?.length && post.attachments[0].type === "video" ? (
        <PublishingPostVideoPreview post={post} />
      ) : post.attachments?.length && post.attachments[0].type === "pdf" ? (
        <PublishingPostPDFPreview post={post} />
      ) : (
        <div className={s.bbTextPostCaptionWrapper}>
          <div className={s.bbPostCaption}>{post.message}</div>
          <ErrorPosting post={post} />
          {!!post.attachments?.length && (
            <div className={s.bbPostMediaType}>{getPostMediaTypeIcon(post)}</div>
          )}
        </div>
      )}
      <Tooltip title={post.page_name} overlayInnerStyle={{ fontSize: 12 }}>
        <div className={s.bbPlatformIcon}>{getPlatformIconRoundFilled(post.platform_type.id)}</div>
      </Tooltip>
      {post.publishing_status === "draft" ? postDraftBadge : postPublishingTime}
    </>
  );
}
