import React from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DeleteConfirmation.module.less";

interface IDeleteConfirmationProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  className?: string;
}
const DeleteConfirmation: React.FC<IDeleteConfirmationProps> = ({
  message,
  onConfirm,
  onCancel,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(s.bbDeleteConfirmation, className)}>
      {message}
      <div className="ml-3">
        <Button _size="sm" _type="secondary" onClick={onCancel}>
          {t("generic:cancel")}
        </Button>
        <Button _size="sm" _type="danger" className="ml-2" onClick={onConfirm}>
          {t("generic:delete")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
