import React, { FC } from "react";

import { Button, ChipIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Overview.module.less";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

interface IOverviewSectionDividerProps {
  title: string;
  description: string;
  icon: JSX.Element;
  additionalAction?: JSX.Element;
  actionButtonText?: JSX.Element;
  onActionButtonClick?: () => void;
}

const SectionDivider: FC<IOverviewSectionDividerProps> = ({
  icon,
  title,
  description,
  additionalAction,
  actionButtonText,
  onActionButtonClick,
}) => {
  const screens = useBreakpoint();

  return (
    <>
      <div className={s.bbSectionDivider}>
        <div className={s.bbSectionDividerMain}>
          <span className={s.bbSectionDividerChipWrapper}>
            <ChipIcon
              _size="sm"
              _type="default"
              icon={icon}
              iconPosition="left"
              className={s.bbSectionDividerChip}
              iconClassName={s.bbSectionDividerChipIcon}
              text={title}
            />
          </span>
          {screens.xxl && <span className={s.bbSectionDividerDescription}>{description}</span>}
        </div>
        <div className={s.bbActionButtonsWrapper}>
          {additionalAction}
          {onActionButtonClick && actionButtonText && (
            <Button
              _type="secondary"
              onClick={onActionButtonClick}
              className={s.bbSectionDividerMainAction}
            >
              {actionButtonText}
            </Button>
          )}
        </div>
      </div>
      {!screens.xxl && <div className={s.bbSectionDividerDescription}>{description}</div>}
    </>
  );
};

export default SectionDivider;
