import {
  InputChipQueryLabelsEnum,
  InputChipQueryOperatorsEnum,
} from "../../inputChip/InputChipQueryConfig";
import { SelectOptionSize } from "@bbdevcrew/bb_ui_kit_fe";

export interface IContextMenuOption {
  id: InputChipQueryOperatorsEnum;
  label: InputChipQueryLabelsEnum;
}

/**
 * HARD HACK.
 * Since we use `size` prop on select to force options rendering,
 * we have to force select height in case there is 1 option.
 *
 * Ref: if select has only 1 option, it's set by default, which is not what we want
 *
 * TODO: Investigate if we can easily read tailwind values from config, rather than
 * guesstimate.
 */
export function hackOneOptionHeight(_size: keyof typeof SelectOptionSize) {
  if (_size === "sm") {
    return { height: "49px" };
  }
  if (_size === "md") {
    return { height: "57px" };
  }
  if (_size === "lg") {
    return { height: "69px" };
  }

  return {};
}

export function isLastChipOperator(allChips: string[]) {
  const lastChip = allChips[allChips.length - 1] || "";

  if (InputChipQueryLabelsEnum[lastChip as keyof typeof InputChipQueryLabelsEnum]) {
    return true;
  }

  return false;
}

/**
 * Rules for options generation:
 * 1. If there are no values, `NOT` is the only option.
 * 2. If there are values AND last value is `keyword`, ALL options are available.
 * 3. If there are values AND last value is `operator`, NO options are available.
 */
export function getContextMenuOptions(allChips: string[]): IContextMenuOption[] {
  if (allChips.length === 0) {
    return [{ id: InputChipQueryOperatorsEnum.NOT, label: InputChipQueryLabelsEnum.__NOT__ }];
  }

  if (isLastChipOperator(allChips)) {
    return [];
  }

  return [
    { id: InputChipQueryOperatorsEnum.AND, label: InputChipQueryLabelsEnum.__AND__ },
    { id: InputChipQueryOperatorsEnum.OR, label: InputChipQueryLabelsEnum.__OR__ },
    { id: InputChipQueryOperatorsEnum.AND_NOT, label: InputChipQueryLabelsEnum.__AND__NOT__ },
    { id: InputChipQueryOperatorsEnum.OR_NOT, label: InputChipQueryLabelsEnum.__OR__NOT__ },
  ];
}
