import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import React, { FC, useState, useEffect, useCallback } from "react";

import { Grid } from "antd";
import ChartLabel from "./ChartLabel";
import { Card, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";
import BreakdownChartLegend from "./breakdownChartLegend/BreakdownChartLegend";
import { BreakdownChart } from "../../../_common/BreakdownChart/BreakdownChart";
import BreakdownChartTooltip from "./breakdownChartTooltip/BreakdownChartTooltip";

import { postDashboardAction } from "@store/dashboard/actions";
import { safetyBreakdownSelector } from "@store/dashboard/selectors";

import { IFilters } from "@store/filters/types";
import { IDashboardPayload } from "@store/dashboard/types";
import { IBreakdownProps, IChartLabelClickEvent } from "./Breakdown.type";

import s from "./Breakdown.module.less";

export const LABEL_WIDTH = 180;
export const CHART_HEIGHT = 436;
export const LABEL_SMALL = 150;
export const LABEL_LARGE = 200;

const cx = cn.bind(s);

const Breakdown: FC<IBreakdownProps> = ({ appFilters, filterToSpecificItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const chartLabelWidth = screens.md ? LABEL_LARGE : LABEL_SMALL;

  const [activeTabKey, setActiveTabKey] = useState("platform_type");
  const [filters, setFilters] = useState<IFilters>();

  const postDashboards = useCallback(
    (body: IDashboardPayload) => dispatch(postDashboardAction(body)),
    [dispatch],
  );

  const safetyBreakdown = useSelector(safetyBreakdownSelector);

  useEffect(() => {
    if (appFilters !== filters) {
      setFilters(appFilters);
      postDashboards({
        filters: appFilters,
        components: ["safety_breakdown"],
      });
    }
    // eslint-disable-next-line
  }, [appFilters]);

  const onYAxisLabelClick = (clickedItem: IChartLabelClickEvent) => {
    if (safetyBreakdown?.length) {
      const safetyBreakdownUnit = safetyBreakdown.find(unit => unit.id === activeTabKey);

      if (safetyBreakdownUnit && filterToSpecificItem) {
        filterToSpecificItem({
          [safetyBreakdownUnit.search_param as string]: [clickedItem.value],
        });
      }
    }
  };

  const getSelectedItemLabel = () => {
    return safetyBreakdown?.find(item => item.id === activeTabKey)?.label || "";
  };

  return (
    <div className={s.bbBreakdown}>
      <Card
        id="card-breakdown"
        className={s.bbBreakdownCard}
        data-stonly-target="safety-moderation__breakdown-chart"
        headerTitle={t("components:breakdown:title", {
          selected: getSelectedItemLabel(),
        })}
        headerTooltip={t("components:breakdown:info", {
          selected: getSelectedItemLabel(),
        })}
      >
        <TabsScrollable
          activeKey={activeTabKey}
          onChange={key => setActiveTabKey(key)}
          defaultActiveKey={(safetyBreakdown && safetyBreakdown[0]?.id) || "platform_type"}
          className={s.bbBreakdownTabs}
        >
          {safetyBreakdown?.map(breakdownUnit => (
            <TabPane tab={breakdownUnit.label} tabKey={breakdownUnit.id} key={breakdownUnit.id}>
              <ReactResizeDetector
                handleWidth
                refreshRate={2000}
                refreshMode={"throttle"}
                refreshOptions={{ leading: true, trailing: true }}
              >
                {(resizeProps: { width: number }) => {
                  return (
                    <div
                      className={cx(s.bbBreakdownContainer, {
                        [s.bbBreakdownContainerFull]: !screens.lg,
                      })}
                    >
                      <BreakdownChart
                        data={breakdownUnit}
                        height={CHART_HEIGHT}
                        labelWidth={LABEL_WIDTH}
                        customTooltip={<BreakdownChartTooltip />}
                        width={resizeProps.width - chartLabelWidth || 0}
                        generateLabel={props => (
                          <ChartLabel
                            type={activeTabKey}
                            labelTickProps={props}
                            breakdownUnit={breakdownUnit}
                            tooltipLabel={getSelectedItemLabel()}
                            chartLabelWidth={chartLabelWidth}
                          />
                        )}
                        onYAxisLabelClick={onYAxisLabelClick}
                      />
                      <BreakdownChartLegend />
                    </div>
                  );
                }}
              </ReactResizeDetector>
            </TabPane>
          ))}
        </TabsScrollable>
      </Card>
    </div>
  );
};

export default Breakdown;
