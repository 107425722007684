import { IPerformanceUnit, PerformanceCardType } from "../../../_common/performanceCard/types";

export const normalizeChartData = (data: IPerformanceUnit, type: PerformanceCardType) => {
  // Using "all" instead of "total" is reserved and not visible in the chart component

  return (
    data?.histogram?.items?.map(item => {
      return {
        [type === "total" ? "all" : type]: item.value,
        name: item.name,
      };
    }) || []
  );
};

export const defaultSizeProps = {
  md: 12,
  lg: 12,
  xl: 6,
};

export const fullSizeProps = {
  md: 24,
  lg: 24,
  xl: 24,
};

export const areas = [
  {
    name: "all",
    label: "All",
    color: "#006DD9",
  },
  {
    name: "positive",
    label: "Positive",
    color: "#00A35A",
  },
  {
    name: "negative",
    label: "Negative",
    color: "#D23031",
  },
  {
    name: "neutral",
    label: "Neutral",
    color: "#676F85",
  },
];

export const responsiveProps = {
  height: 52,
};

export const XAxisProps = {
  hide: true,
};

export const YAxisProps = {
  hide: true,
};
