import { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { DropdownMenuPlacementType } from "@bbdevcrew/bb_ui_kit_fe";
import { IDynamicFilter, IInjectedCustomProps } from "../../Filters.type";

export const MIN_DATE_RANGE = "2020-01-01";
export const YT_AVAILABLE_DAYS = 30;

// eslint-disable-next-line
export interface IFilterRangeProps extends IDynamicFilter<{}>, IInjectedCustomProps {
  onCalendarChange?: (value: RangeValue<Moment>) => void;
  onChangeDataRangeOption?: (value: string) => void;
  dropdownPlacement?: DropdownMenuPlacementType;
  dropdownMatchSelectWidth?: boolean;
  maxTagCount?: number;
  maxTagPlaceholder?: string;
  isYTSelected?: boolean;
}
