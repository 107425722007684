import React from "react";
import classNames from "classnames";

import { Chip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SelectedExcludedIndicator.module.less";

interface ISelectedExcludedIndicatorProps {
  selected?: string;
  excluded?: string;
  className?: string;
}

function SelectedExcludedIndicator({
  selected,
  excluded,
  className,
}: ISelectedExcludedIndicatorProps) {
  return (
    <div className={classNames(s.bbIndicator, className)} data-testid="selected-excluded-indicator">
      {!!selected && (
        <Chip _size="xxs" _type="primary" text={selected} className={s.bbIndicatorSelected} />
      )}
      {!!excluded && (
        <Chip _size="xxs" _type="primary" className={s.bbIndicatorExcluded} text={excluded} />
      )}
    </div>
  );
}

export default SelectedExcludedIndicator;
