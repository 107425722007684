import { action } from "typesafe-actions";

import { ASSIGN_TO_ME, ASSIGN_TO_ME_SUCCESS, ASSIGN_TO_ME_FAILURE } from "./actionTypes";
import { AssignListType } from "./types";
import { IFilters } from "../filters/types";

export const assignToMeAction = (
  list_type: AssignListType,
  filters?: IFilters,
  onSuccess?: () => void,
) => action(ASSIGN_TO_ME, { list_type, filters, onSuccess });

export const assignToMeSuccessAction = () => action(ASSIGN_TO_ME_SUCCESS);

export const assignToMeFailureAction = (error: string) => action(ASSIGN_TO_ME_FAILURE, error);
