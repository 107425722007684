import { createReducer, ActionType } from "typesafe-actions";
import { IFiltersState } from "./types";

import { toggleOpenFiltersPanelAction } from "./actions";
import { TOGGLE_OPEN_FILTERS_PANEL } from "./actionTypes";

export const OPEN_FILTERS_PANEL_WIDTH = 270;
export const COLLAPSED_FILTERS_PANEL_WIDTH = 20;

const initialState = {
  filtersOpen: true,
  filtersPanelWidth: OPEN_FILTERS_PANEL_WIDTH,
};

export const filtersReducer = createReducer<IFiltersState>(initialState, {
  [TOGGLE_OPEN_FILTERS_PANEL]: (
    state: IFiltersState,
    action: ActionType<typeof toggleOpenFiltersPanelAction>,
  ) => {
    const filtersOpen = action.payload;

    return {
      ...state,
      filtersOpen: filtersOpen,
      filtersPanelWidth: filtersOpen ? OPEN_FILTERS_PANEL_WIDTH : COLLAPSED_FILTERS_PANEL_WIDTH,
    };
  },
});
