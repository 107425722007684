import React, { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { Form } from "antd";
import CustomTags from "../../CommentCard/customTags/CustomTagsDropdown";
import BrandBastionTags from "../../CommentCard/brandBastionTags/BBTags";
import CustomTagsList from "../../CommentCard/customTags/CustomTagsList";
import CommentTagsList from "../../CommentCard/commentTags/CommentTagsList";
import { Input, Button, TextareaNew } from "@bbdevcrew/bb_ui_kit_fe";

import {
  setSavedReplyBBTags,
  setSavedReplyCustomTags,
  putSavedReplyAction,
  postReplyBBTagAction,
  deleteReplyBBTagAction,
} from "@store/replies/actions";
import { deleteCustomTagFromReplyAction } from "@store/customTags/actions";
import {
  getCreateSavedReplyCustomTagsSelector,
  getSavedRepliesSelector,
  getSavedReplyBBTagsSelector,
} from "@store/replies/selectors";
import { canManageBBTagsSelector } from "@store/me/selectors";

import { ViewChangeFunc } from "../common/modal/types";
import { SavedReplyMessageMaxLimit, SavedReplyNameMaxLimit } from "./helper";

import s from "./SavedReplies.module.less";

import { ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { ICommentTag } from "@store/comments/types";
import { SavedReplyPayloadType } from "@store/replies/types";

interface ISavedReplyEditProps {
  editItemId: string;
  onViewChange: ViewChangeFunc;
}

const SavedReplyEdit: React.FC<ISavedReplyEditProps> = ({ editItemId, onViewChange }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useDispatch();

  const customTags = useSelector(getCreateSavedReplyCustomTagsSelector);
  const bbTags = useSelector(getSavedReplyBBTagsSelector);
  const savedReplies = useSelector(getSavedRepliesSelector);
  const canManageBBTag = useSelector(canManageBBTagsSelector);

  const editItem = savedReplies?.items.filter(({ id }) => id === editItemId)[0];

  useEffect(() => {
    if (editItem) {
      dispatch(setSavedReplyCustomTags(editItem.custom_tags));
      dispatch(setSavedReplyBBTags(editItem.tags));
    }
    // eslint-disable-next-line
  }, [dispatch, editItemId]);

  const onFinish = (values: SavedReplyPayloadType) => {
    const { name, message } = values;
    if (editItem?.id) {
      dispatch(
        putSavedReplyAction({
          name: name.trim(),
          message: message.trim(),
          id: editItem?.id,
          tags: bbTags.map(tag => tag.id),
          custom_tags: customTags.map(tag => tag.id).filter(id => id !== undefined) as string[],
        }),
      );
      onViewChange("list");
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onViewChange("list");
  };

  const handleDeleteCustomTag = (tagId?: string) => {
    tagId && dispatch(deleteCustomTagFromReplyAction(tagId));
  };

  const handleDeleteBBTag = (tagId: string) => {
    dispatch(deleteReplyBBTagAction(tagId));
  };

  const handleAddBBTag = ({ id, label, category }: ICommentTag) => {
    dispatch(postReplyBBTagAction({ id, label, category }));
  };

  return (
    <>
      <div className={s.bbSavedReplyHeader}>
        <Button _type="link" className={s.bbSavedReplyHeaderBack} onClick={handleCancel}>
          <ChevronLeftIcon />
        </Button>
        <div className={s.bbSavedReplyHeaderTitle}>
          {t("components:reply:savedReplies:edit:title")}
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        className={s.bbSavedReplyCreateForm}
        onFinish={onFinish}
        initialValues={{
          name: editItem?.name || "",
          message: editItem?.message || "",
        }}
      >
        <Form.Item
          name="name"
          label={t("components:reply:savedReplies:edit:nameInputlabel")}
          rules={[
            {
              required: true,
              message: t("components:reply:savedReplies:edit:nameInputError"),
            },
            {
              max: SavedReplyNameMaxLimit,
              message: t("components:reply:savedReplies:edit:messageCharLimit", {
                amount: SavedReplyNameMaxLimit,
              }),
            },
          ]}
        >
          <Input className={s.bbSavedReplyCreateFormInput} _size="lg" />
        </Form.Item>
        <Form.Item
          name="message"
          label={t("components:reply:savedReplies:edit:messageInputlabel")}
          rules={[
            {
              required: true,
              message: t("components:reply:savedReplies:edit:messageInputError"),
            },
            {
              max: SavedReplyMessageMaxLimit,
              message: t("components:reply:savedReplies:edit:messageCharLimit", {
                amount: SavedReplyMessageMaxLimit,
              }),
            },
          ]}
        >
          <TextareaNew className={s.bbSavedReplyCreateFormInputMessage} _size="lg" />
        </Form.Item>
        <div className={s.bbSavedRepliesTags} data-stonly-target="app-global__saved-replies--tags">
          <CommentTagsList tags={bbTags} isReply className={s.bbCommentTagsList} />
          <CustomTagsList customTagsByUser={customTags} onDeleteTag={handleDeleteCustomTag} />
          <CustomTags
            customTagsByUser={customTags}
            customTagsAutomated={[]}
            hasTags={!!customTags.length}
          />
          {canManageBBTag && (
            <BrandBastionTags
              tags={bbTags}
              onAddTag={handleAddBBTag}
              onDeleteTag={handleDeleteBBTag}
            />
          )}
        </div>
        <Form.Item>
          <div className={s.bbSavedReplyCreateFormActions}>
            <Button _type="link" onClick={handleCancel}>
              {t("generic:discard")}
            </Button>
            <Button _type="primary" type="submit">
              {t("generic:save")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default SavedReplyEdit;
