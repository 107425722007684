import moment from "moment";
import i18next from "i18next";
import { IPost } from "@components/_common/Social/Post/Post.type";

export const getPostImageUrl = (post: IPost) =>
  post.attachments && post.attachments[0]
    ? post.attachments[0].media?.image?.src
    : post?.picture
      ? post?.picture
      : undefined;

export const getPostSubtext = (post: IPost, dateFormat?: string) => {
  const isPostPaid = post.has_ads;

  if (post.platform_type.id === "twitter") {
    if (isPostPaid) {
      return i18next.t("generic:promoted");
    }

    return `@${post.author_name} - ${moment(post.created_time_pretty).format(
      "h:mm A MMM DD, YYYY",
    )}`;
  }

  // Other platforms
  if (isPostPaid) {
    return i18next.t("generic:sponsored");
  }

  return moment(post.created_time_pretty).format(dateFormat || "h:mm A MMM DD, YYYY");
};

export const isPostMention = (post: IPost) => {
  return post.type === "MENTION" || post.type === "CAPTION_MENTION";
};
