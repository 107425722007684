import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useState, useEffect, useCallback } from "react";

import { Row, Col, Grid } from "antd";
import SectionDivider from "./OverviewSectionDivider";
import ReactResizeDetector from "react-resize-detector";
import UserLanguages from "./userLanguages/UserLanguages";
import AppFilters from "../../_common/AppFilter/AppFilters";
import TopInfluencers from "./topInfluencers/TopInfluencers";
import AnalysisTrends from "./analysisTrends/AnalysisTrends";
import MostUsedEmojis from "./mostUsedEmojis/MostUsedEmojis";
import CommentEngagementCards from "./commentEngagementCards";
import SentimentsCards from "./sentimentsCards/SentimentsCards";
import AgentPerformance from "./agentPerformance/AgentPerformance";
import EngagementsCards from "./engagementsCards/EngagementsCards";
import PlatformsPieChart from "./platformsPieChart/PlatformsPieChart";
import SentimentPieChart from "./sentimentPieChart/SentimentPieChart";
import MostCommentedPosts from "./mostCommentedPosts/MostCommentedPosts";
import DownloadPDFButton from "../_common/downloadPdf/DownloadPDFButton";
import ExternalAppOverview from "./externalAppsOverview/ExternalAppOverview";
import ReviewsWidget from "./reviewsWidget";
import MessageTypePieChart from "./messageTypePieChart/MessageTypePieChart";
import MessageTypeDetailTable from "./messageTypeDetailTable/MessageTypeDetailTable";

import {
  overviewPlatformsSelector,
  overviewMessageTypesSelector,
  overviewSentimentSelector,
  overviewConversationsSelector,
  overviewTopKeywordsSelector,
  overviewTopKeywordPhrasesSelector,
  overviewEmojisSelector,
  overviewLanguagesSelector,
  overviewTopInfluencersSelector,
  getDashboardSelectorPending,
  getDashboardSelectorSuccessful,
  overviewReviewWidgetSelector,
} from "@store/dashboard/selectors";
import { postDashboardAction } from "@store/dashboard/actions";
import { getTrendingPostsSelectorPending } from "@store/overview/selectors";
import { clientDataSelector, meSelector, meSuccessfulSelector } from "@store/me/selectors";

import s from "./Overview.module.less";

import { getPDFName } from "./helpers";
import { IFilters } from "@store/filters/types";
import { useAppFilters } from "@utils/appFilters";
import { useYTSelected } from "@utils/useYTselected";
import { getDefaultFilters } from "../../_common/AppFilter/config";
import useOverviewPDF from "../_common/downloadPdf/useOverviewPDFDownload";
import { ISavedFilter } from "../../_common/AppFilter/AppFilters.type";
import { IDashboardPayload, OverviewComponentType } from "@store/dashboard/types";

import { PostIcon, WordsIcon } from "@assets/index";
import { UserGroupIcon } from "@bbdevcrew/bb_ui_kit_fe";

const Overview: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const { filters, updateSearchDataAndUrl } = useAppFilters();
  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const [downloadPDFLoading, setDownloadPDFLoading] = useState(false);
  const [topSectionsLoading, setTopSectionsLoading] = useState(false);
  const [topSectionsLoaded, setTopSectionsLoaded] = useState(false);
  const [bottomSectionsLoading, setBottomSectionsLoading] = useState(false);

  const me = useSelector(meSelector);
  const meFetched = useSelector(meSuccessfulSelector);
  const clientData = useSelector(clientDataSelector);
  const dashboardLoading = useSelector(getDashboardSelectorPending);
  const dashboardLoaded = useSelector(getDashboardSelectorSuccessful);
  const overviewSentiments = useSelector(overviewSentimentSelector);
  const overviewEmojis = useSelector(overviewEmojisSelector);
  const overviewPlatforms = useSelector(overviewPlatformsSelector);
  const overviewMessageType = useSelector(overviewMessageTypesSelector);
  const overviewConversations = useSelector(overviewConversationsSelector);
  const overviewLanguages = useSelector(overviewLanguagesSelector);
  const overviewTopInfluencers = useSelector(overviewTopInfluencersSelector);
  const trendingPostsLoading = useSelector(getTrendingPostsSelectorPending);
  const intelligenceFilters = getDefaultFilters(t, me);
  const overviewReviewWidgetData = useSelector(overviewReviewWidgetSelector);

  function onBeforePDFDownload() {
    setDownloadPDFLoading(false);
  }

  const { downloadOverviewPDF } = useOverviewPDF(
    getPDFName(filters, me?.client?.name),
    onBeforePDFDownload,
  );

  function onDownloadPDFClick() {
    downloadOverviewPDF();
    setDownloadPDFLoading(true);
  }

  const overviewTopKeywords = useSelector(overviewTopKeywordsSelector);
  const overviewTopKeywordPhrases = useSelector(overviewTopKeywordPhrasesSelector);

  const getDashboardData = useCallback(
    (data: IDashboardPayload) => dispatch(postDashboardAction(data)),
    [dispatch],
  );

  const getTopSections = useCallback(() => {
    getDashboardData({
      filters,
      components: [
        "explorer_overview_sentiment",
        "explorer_overview_platform",
        "explorer_overview_conversations",
        "review_widgets",
      ],
    });
  }, [filters, getDashboardData]);

  const getBottomSections = () => {
    const components: OverviewComponentType[] = [
      "explorer_overview_top_emojis",
      "explorer_overview_top_keywords",
      "explorer_overview_top_keyword_phrases",
      "explorer_overview_top_languages",
      "explorer_overview_top_influencers",
      "review_widgets",
      "explorer_overview_message_types",
    ];

    if (me?.client?.is_care_managed) components.push("explorer_overview_care");

    if (me?.client?.is_moderation_managed) components.push("explorer_overview_safety");

    if (me?.is_agent_performance_widget_activated) components.push("explorer_agent_performance");

    getDashboardData({
      filters,
      components,
    });
  };

  useEffect(() => {
    if (me && meFetched && filters && !topSectionsLoading) {
      getTopSections();
      setTopSectionsLoading(true);
    }
    // eslint-disable-next-line
  }, [me, meFetched, filters, getTopSections]);

  useEffect(() => {
    if (dashboardLoaded && !dashboardLoading) {
      if (topSectionsLoading) {
        setTopSectionsLoading(false);
        setTopSectionsLoaded(true);
      }
      if (bottomSectionsLoading) {
        setBottomSectionsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [dashboardLoading, dashboardLoaded]);

  useEffect(() => {
    if (!topSectionsLoading && topSectionsLoaded && !bottomSectionsLoading) {
      getBottomSections();
      setBottomSectionsLoading(true);
    }
    // eslint-disable-next-line
  }, [topSectionsLoading, topSectionsLoaded, filters]);

  const onFilter = (data: IFilters, param: string | ISavedFilter | undefined) =>
    updateSearchDataAndUrl(data, param);

  const appFilters = (
    <>
      {filters && (
        <AppFilters
          onFilter={onFilter}
          clientData={clientData}
          initialFilterData={filters}
          customFilters={intelligenceFilters}
        />
      )}
    </>
  );

  return filters ? (
    <div className={s.bbRootTrendsContainer}>
      {screens.md && appFilters}
      <div>
        <DownloadPDFButton
          loading={downloadPDFLoading}
          onClick={onDownloadPDFClick}
          tooltipText={
            isYTFilterSelected ? t("pages:insights:ytNoDownload") : t("pages:insights:downloadPDF")
          }
          disabled={dashboardLoading || trendingPostsLoading || isYTFilterSelected}
        />
        <Col xs={24} id="sentiment-cards">
          <Row>
            <SentimentsCards
              filters={filters}
              loading={topSectionsLoading}
              data={overviewSentiments || []}
            />
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            {overviewConversations?.length === 4 ? (
              <EngagementsCards loading={topSectionsLoading} data={overviewConversations} />
            ) : overviewConversations?.length === 2 ? (
              <CommentEngagementCards loading={topSectionsLoading} data={overviewConversations} />
            ) : null}
          </Row>
        </Col>

        <SectionDivider
          icon={<PostIcon />}
          title={t("pages:overviewSections:content:title")}
          description={t("pages:overviewSections:content:description")}
        />
        <div>
          <ReactResizeDetector
            handleWidth
            refreshMode="throttle"
            refreshOptions={{ leading: true, trailing: true }}
          >
            {(props: { width: number }) => (
              <Col xs={24}>
                <Row>
                  <MostCommentedPosts filters={filters} containerWidth={props.width} />
                </Row>
              </Col>
            )}
          </ReactResizeDetector>
          <Row gutter={[24, 24]} className={s.bbPieChartsWrapper} id="overview-content-section">
            <SentimentPieChart
              data={overviewSentiments || []}
              filterData={filters}
              loading={topSectionsLoading}
            />
            <PlatformsPieChart
              loading={topSectionsLoading}
              data={overviewPlatforms || []}
              filterData={filters}
            />
          </Row>

          <Row gutter={[24, 24]} className={s.bbPieChartsWrapper}>
            <MessageTypePieChart
              loading={bottomSectionsLoading}
              data={overviewMessageType?.messageTypeDistribution}
              filterData={filters}
            />
            <MessageTypeDetailTable
              loading={bottomSectionsLoading}
              data={overviewMessageType?.messageTypeDetail || []}
            />
          </Row>
        </div>

        <Row gutter={[28, 28]}>
          <ExternalAppOverview />
          {me?.is_agent_performance_widget_activated && <AgentPerformance filters={filters} />}
        </Row>

        <Row gutter={[28, 28]}>
          <Col xs={24}>
            <SectionDivider
              icon={<WordsIcon />}
              title={t("pages:overviewSections:topics:title")}
              description={t("pages:overviewSections:topics:description")}
            />
          </Col>

          <Col xs={24} id="overview-topics-section" className={s.bbTopics}>
            <Row gutter={[28, 28]}>
              <Col xs={24} xxl={12}>
                <AnalysisTrends
                  filters={filters}
                  loading={bottomSectionsLoading}
                  wordCloudData={overviewTopKeywords || []}
                  keywordPhrasesData={overviewTopKeywordPhrases || []}
                />
              </Col>
              <Col xs={24} xxl={12}>
                <MostUsedEmojis
                  filters={filters}
                  data={overviewEmojis || []}
                  loading={bottomSectionsLoading}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[28, 28]}>
          <Col xs={24}>
            <SectionDivider
              icon={<UserGroupIcon />}
              title={t("pages:overviewSections:audience:title")}
              description={t("pages:overviewSections:audience:description", {
                brandname: me?.client?.name,
              })}
            />
          </Col>

          <Col xs={24}>
            <Row gutter={[28, 28]}>
              <Col xs={24} xxl={12}>
                <UserLanguages
                  filterData={filters}
                  loading={bottomSectionsLoading}
                  data={overviewLanguages || []}
                />
              </Col>
              <Col xs={24} xxl={12} id="overview-audience-section">
                <TopInfluencers
                  filterData={filters}
                  loading={bottomSectionsLoading}
                  data={overviewTopInfluencers || []}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {overviewReviewWidgetData && (
          <Row gutter={[28, 28]}>
            <ReviewsWidget data={overviewReviewWidgetData} loading={bottomSectionsLoading} />
          </Row>
        )}
      </div>
      {!screens.md && appFilters}
    </div>
  ) : null;
};

export default Overview;
