import React from "react";
import ReactDOM from "react-dom";

import "./index.less";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

/**
 * TODO: Revert back to React18 way of mounting the APP when Antd comes out with support
 * for React 18.
 * Issue: https://github.com/ant-design/ant-design/issues/26136
 */
// eslint-disable-next-line
ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
