import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "..";
import { GET_COMPARE } from "./actionTypes";
import { Actions } from "./types";
import { getCompareFailure, getCompareSuccess } from "./actions";

import { compare } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const getCompare = (action$: Observable<Actions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(GET_COMPARE)),
    switchMap(a => {
      return ajax
        .post(
          `${compare}/aggregations`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => getCompareSuccess()),
          catchError(e => handleError(e, getCompareFailure)),
        );
    }),
  );
