import React from "react";
import { useTranslation } from "react-i18next";
import { SavedFiltersIcon } from "@assets/index";

import s from "./EmptyListState.module.less";

export default function EmptyListState() {
  const { t } = useTranslation();

  return (
    <div className={s.bbEmptyListState}>
      <p className={s.bbEmptylistStateTitle}>
        {t("components:filters:savedFilters:list:emptyFilters:title")}
      </p>
      <img src={SavedFiltersIcon} alt="saved-filter-image" className={s.bbEmptyListStateImage} />
      <p className={s.bbEmptyListStateDescription}>
        {t("components:filters:savedFilters:list:emptyFilters:description")}
      </p>
    </div>
  );
}
