import classNames from "classnames";
import React, { useState, MouseEvent } from "react";

import { Icon, Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { IInfoProps } from "./Info.type";
import { PlatformTypeIcon } from "@utils/platform";

import s from "./Info.module.less";

import { TranslateLanguageIcon } from "@assets/index";
import { EyeOffFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

const tooltipDelay = process.env.TOOLTIP_DELAY ? parseFloat(process.env.TOOLTIP_DELAY) : 1.2;

const Info: React.FC<IInfoProps> = ({
  id,
  index,
  postId,
  hideAds,
  hideParent,
  hidePost,
  hideLinks,
  hasTranslate,
  tooltips,
  platformIconName,
  username,
  parentComment,
  date,
  time,
  texts,
  functions,
  isParent,
  isTranslated,
  showHiddenIcon,
  isHidden,
}) => {
  const [translate, setTranslate] = useState(isTranslated);

  const onClickParent = (e: MouseEvent) => {
    e.preventDefault();

    if (functions && functions.onClickParent) {
      functions.onClickParent(id);
    }
  };

  const onClickTranslate = (e: MouseEvent) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setTranslate(!translate);

    if (functions && functions.onClickTranslate) {
      functions.onClickTranslate(id, isParent ? true : false);
    }
  };

  return (
    <div className={s.bbCommentsInfo}>
      <div className={s.bbCommentsPlatform}>
        <div />
        <Tooltip mouseEnterDelay={tooltipDelay} title={tooltips?.platformTextTooltip || ""}>
          <span>
            <Icon
              _size="sm"
              icon={PlatformTypeIcon[platformIconName as keyof typeof PlatformTypeIcon]}
            />
          </span>
        </Tooltip>
      </div>
      {!!hasTranslate && (
        <div onClick={onClickTranslate} className={s.bbCommentsInfoTranslate}>
          <span
            className={classNames({
              [s.bbCommentsInfoIsTranslated]: translate,
            })}
          >
            <TranslateLanguageIcon />
          </span>
        </div>
      )}
      <Tooltip mouseEnterDelay={tooltipDelay} title={tooltips?.userNameTooltip || ""}>
        <div className={s.bbCommentsUsername}>{username}</div>
      </Tooltip>
      <Tooltip
        title={tooltips && tooltips.dateTooltip ? tooltips.dateTooltip : ""}
        mouseEnterDelay={tooltipDelay}
      >
        <div>
          <div className={s.bbCommentsDate}>{date}</div>
          <div className={s.bbCommentsTime}>{time}</div>
        </div>
      </Tooltip>
      {!hideLinks ? (
        <div>
          {!hideParent && !!parentComment && (
            <span>
              <Tooltip mouseEnterDelay={tooltipDelay} title={tooltips?.seeParentTextTooltip || ""}>
                <span>
                  <Button _size="sm" _type="link" onClick={e => onClickParent(e)}>
                    {texts?.seeParentText || ""}
                  </Button>
                </span>
              </Tooltip>
              <span> / </span>
            </span>
          )}
          {!hidePost ? (
            <Tooltip mouseEnterDelay={tooltipDelay} title={tooltips?.postTextTooltip || ""}>
              <span>
                <Button
                  _size="sm"
                  _type="link"
                  onClick={e =>
                    functions?.onClickOpenPost ? functions.onClickOpenPost(e, postId) : null
                  }
                >
                  {texts?.postText || ""}
                </Button>
              </span>
            </Tooltip>
          ) : null}
          {!hideAds ? (
            <span>
              <span> / </span>
              <Tooltip mouseEnterDelay={tooltipDelay} title={tooltips?.adsTextTooltip || ""}>
                <span>
                  <Button
                    _size="sm"
                    _type="link"
                    onClick={e =>
                      functions?.onClickOpenAds ? functions.onClickOpenAds(e, postId) : null
                    }
                  >
                    {texts?.adsText || ""}
                  </Button>
                </span>
              </Tooltip>
            </span>
          ) : null}
        </div>
      ) : null}
      {showHiddenIcon && isHidden ? (
        <div className="flex justify-end">
          <Tooltip mouseEnterDelay={tooltipDelay} title={tooltips?.hiddenMessageTooltip || ""}>
            {!isParent && !hideLinks ? (
              <Button
                _size="sm"
                _type="secondary"
                onClick={e =>
                  functions && functions.onClickHidden
                    ? functions.onClickHidden(e, index, id)
                    : null
                }
              >
                <Icon _size="md" icon={EyeOffFilledIcon} />
              </Button>
            ) : (
              <Icon _size="md" icon={EyeOffFilledIcon} />
            )}
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default Info;
