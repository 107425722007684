import React from "react";
import { useTranslation } from "react-i18next";

import { BarChart, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./MostCommentedPostFooter.module.less";

import { renderStatsTooltip } from "./helpers";
import { IStatsProps } from "@store/overview/types";
import { getSentimentBars, generateStatsData } from "../../../helpers";

import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

interface IMostCommentedPostCommentStatsProps {
  chartWidth: number;
  commentStats: IStatsProps;
}
const CARD_WIDTH_OFFSET = 80;

export const CommentStats = ({ chartWidth, commentStats }: IMostCommentedPostCommentStatsProps) => {
  const { t } = useTranslation();
  const width = chartWidth >= CARD_WIDTH_OFFSET ? chartWidth - CARD_WIDTH_OFFSET : 0;
  const stats = generateStatsData(commentStats, false);

  return (
    <Tooltip title={renderStatsTooltip(commentStats, t)} overlayInnerStyle={{ fontSize: 14 }}>
      <div style={{ width: width - 10 }} className={s.bbCommentStatsWrapper}>
        <BarChart
          layout="vertical"
          total={{
            name: t("components:postTrends:sentiments:total"),
            color: palette.colorBlue900,
          }}
          data={stats}
          width={width}
          height={30}
          barSize={27}
          stackOffset="sign"
          yAxis
          xAxis
          isShowPercent
          percentagePrecision={1}
          xAxisProps={{
            type: "number",
            hide: true,
          }}
          yAxisProps={{ dataKey: "name", type: "category", hide: true }}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
          bars={getSentimentBars()}
        />
      </div>
    </Tooltip>
  );
};
