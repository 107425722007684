import { NavigateFunction } from "react-router-dom";
import { IMenuItem, IAppSubfeature } from "./AppHeader.type";
import { removeItemUrlSearch } from "@utils/appFilters";
import { PreRedirectFnType } from "../AppLayout/AppLayout.type";

export const findItem = (menu: IMenuItem[], key: string) =>
  menu.find(m => m.subfeatures?.find(sub => sub.id === key));

export const selectItem = (menu: IMenuItem[], key: string) => {
  const parentFeature = findItem(menu, key)?.basename;

  if (!parentFeature) return [];

  return [parentFeature, key];
};

export const isItemActive = (item: IMenuItem) => location.href.includes(item.id);

const resolvePreRedirect = async (onPreRedirect: PreRedirectFnType, isSameBase: boolean) => {
  // Resolve "pre-redirect" functions, if any
  try {
    await onPreRedirect(!isSameBase);
  } catch (err) {
    console.error(err);
  }
};

const redirectToFeature = (feature: IMenuItem) => {
  const { url } = feature;

  removeItemUrlSearch("tab");
  const isAbsPath = url?.startsWith("https://");
  const redirectUrl = isAbsPath ? url : `${window.location.origin}${url}`;
  const queryParams =
    "preserveFilters" in feature && feature.preserveFilters
      ? `${localStorage.getItem("query") || ""}`
      : "";

  window.location.href = redirectUrl + (!!queryParams ? `/${queryParams}` : "");
};

export const onFeatureRedirect = async (
  onPreRedirect: PreRedirectFnType,
  item: IMenuItem,
  isSameBase: boolean,
) => {
  resolvePreRedirect(onPreRedirect, isSameBase);
  redirectToFeature(item);
};

export const onSubfeatureRedirect = async (
  navigate: NavigateFunction,
  onPreRedirect: PreRedirectFnType,
  item: IMenuItem | IAppSubfeature,
  isSameBase: boolean,
) => {
  resolvePreRedirect(onPreRedirect, isSameBase);

  if (isSameBase) {
    navigate({
      pathname: `/${item.id}`,
      search:
        "preserveFilters" in item && item.preserveFilters
          ? `${localStorage.getItem("query") || ""}`
          : "",
    });
  } else {
    redirectToFeature(item);
  }
};
