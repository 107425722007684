import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "antd";
import { EyeOffIcon } from "@bbdevcrew/bb_ui_kit_fe";
import CommentsByPlatformWidget from "./commentsByPlatformWidget";
import CommentsByTagChart from "./commentsByTagChart/CommentsByTagChart";
import TaggedCommentsWidget from "./taggedCommentsWidget/TaggedCommentsWidget";

import { meSelector } from "@store/me/selectors";

import s from "./ExternalAppOverview.module.less";

import { normalizeSafetyTagsStatsData } from "./helpers";
import { ISafetyOverview, IOverviewPerformanceTag } from "@store/dashboard/types";

import { TagIcon } from "@assets/index";

interface ISafetySectionProps {
  data?: ISafetyOverview;
  loading: boolean;
  withChartLegend?: boolean;
}

const SafetySection: FC<ISafetySectionProps> = ({ data, loading, withChartLegend = true }) => {
  const { t } = useTranslation();

  const [tagsStatsData, setTagsStatsData] = useState<IOverviewPerformanceTag[]>([]);

  const me = useSelector(meSelector);

  useEffect(() => {
    if (data) {
      setTagsStatsData(normalizeSafetyTagsStatsData(data.tags_hidden_stats.items));
    }
  }, [data]);

  return (
    <>
      <Col xs={24} xl={24} xxl={8}>
        <Row gutter={[28, 28]}>
          <Col xs={24} xl={12} xxl={24}>
            <CommentsByPlatformWidget
              data={data?.safety_hidden_stats.items || []}
              loading={loading}
              headerIcon={<EyeOffIcon />}
              headerIconType="danger"
              title={t("components:overviewSafety:commentsHidden:title")}
              subTitle={t("components:overviewSafety:commentsHidden:subTitle")}
            />
          </Col>
          {data?.harmful_stats && (
            <Col xs={24} xl={12} xxl={24}>
              <TaggedCommentsWidget
                data={data?.harmful_stats.items || []}
                loading={loading}
                headerIcon={<TagIcon />}
                headerIconType="danger"
                title={t("components:overviewSafety:potentiallyHarmful:title")}
                subTitle={t("components:overviewSafety:potentiallyHarmful:subTitle")}
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col xs={24} xl={24} xxl={16}>
        <div className={s.bbCommentsByTagSafetyChart}>
          <Row>
            <CommentsByTagChart
              withLegend={withChartLegend}
              data={tagsStatsData}
              loading={loading}
              title={t("components:overviewSafety:hiddenByTag:title")}
              subTitle={t("components:overviewSafety:hiddenByTag:subTitle", {
                name: me?.client?.name,
              })}
            />
          </Row>
        </div>
      </Col>
    </>
  );
};

export default SafetySection;
